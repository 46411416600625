<template>
  <div class="home">
    <list-page/>
  </div>
</template>

<script>
// @ is an alias to /src


import ListPage from "@/components/page/HomeView/content/list-page.vue";

export default {
  name: 'HomeView',
  components: {ListPage},
  mounted() {
    document.title = "Ацетилхолин и боль нижней части спины";

    let meta = document.querySelector("meta[name='description']");
    if (meta) {
      meta.setAttribute('content', 'О болевом пороге, ко-анальгетиках и адъювантной терапии');
    } else {
      meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "О болевом пороге, ко-анальгетиках и адъювантной терапии";
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
    window.scrollTo(0, 0);

  },


}
</script>

<style lang="scss" scoped>
</style>
