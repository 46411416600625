import {createStore} from 'vuex'

export default createStore({
    state: {
        ifMobile: false,
        totalQuizScore: 0,
        showResultScore: false,
        confirmPopup: true,
        cookiesPopup: true,
    },
    getters: {
        getIsMobile: state => {
            return state.ifMobile
        },
        getTotalQuizScore: state => {
            return state.totalQuizScore
        },
        getShowResultScore: state => {
            return state.showResultScore
        },
        getConfirmPopup: state => {
            return state.confirmPopup
        },
        getCookiesPopup: state => {
            return state.cookiesPopup
        }
    },
    mutations: {
        setIsMobile(state, mobile) {
            state.ifMobile = mobile
        },
        setTotalQuizScore(state, score) {
            state.totalQuizScore = score
        },
        setShowResultScore(state, show) {
            state.showResultScore = show
        },
        setConfirmPopup(state, confirmPopup) {
            state.confirmPopup = confirmPopup
        },
        setCookiesPopup(state, cookiesPopup) {
            state.cookiesPopup = cookiesPopup
        }
    },
    actions: {
        switchMobile({commit}, mobile) {
            commit('setIsMobile', mobile)
        },
        switchTotalQuizScore({commit}, score) {
            commit('setTotalQuizScore', score)
        },
        switchShowResultScore({commit}, show) {
            commit('setShowResultScore', show)
        },
        switchConfirmPopup({commit}, confirmPopup) {
            sessionStorage.setItem('confirmPopup', confirmPopup)
            commit('setConfirmPopup', confirmPopup)
        },
        switchCookiesPopup({commit}, cookiesPopup) {
            commit('setCookiesPopup', cookiesPopup)
        },
    },
})
