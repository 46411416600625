<script>
import top from "@/components/ui/top.vue"
import bottom from "@/components/ui/bottom.vue"
import floatList from "@/components/ui/float-list.vue"
import more from '@/components/ui/more.vue'
import popupConfirm from "@/components/ui/popup-confirm.vue"
import {mapActions, mapGetters} from "vuex";
import SelfMed from "@/components/ui/self-med.vue";
import cookies from "@/components/ui/cookies.vue";


export default {
  name: "app",

  components: {
    SelfMed,
    top,
    bottom,
    more,
    floatList,
    cookies,
    popupConfirm
  },
  watch: {
    '$route'(to, from) {
      this.routeName = to.name
    }
  },

  data() {
    return {
      mobile: false,
      routeName: null,
      popup: true,
    }
  },
  computed: {
    ...mapGetters(['getIsMobile', "getConfirmPopup", "getCookiesPopup"])
  },
  mounted() {
    if (sessionStorage.getItem('confirmPopup')) {
      this.switchConfirmPopup(false)
    }
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);


    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      this.switchMobile(true)
    }
    if (navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)) {
      this.switchMobile(true)
    }

    if (isAndroid || iOS === true) {
      this.switchMobile(true)
    }

    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
    if (localStorage.getItem('cookies') !== null) {
      this.switchCookiesPopup(false)
    }

    if (!this.getIsMobile) {

      window.addEventListener("resize", () => {

        let vh = window.innerHeight * 0.01;

        document.documentElement.style.setProperty('--vh', `${vh}px`);

      });
    }
  },

  methods: {
    ...mapActions(['switchMobile', "switchConfirmPopup", "switchCookiesPopup"]),

  }
}
</script>

<template>
  <div class="wrapper" :class="[{ active: getIsMobile }]">
    <div class="star" :class="{active:routeName !== 'home'}">
      <top :page="routeName"/>
      <router-view/>
      <more :page="routeName" v-if="routeName !== 'home'"/>

      <SelfMed/>
      <bottom/>

      <cookies/>
      <floatList v-if="routeName !== 'home'"/>

      <popupConfirm v-if="getConfirmPopup && !getCookiesPopup"/>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/style/vars";
@import "@/assets/style/fonts.css";

.js-scroll {
  opacity: 0;
}

.scrolled.fade-in-bottom {
  animation: fade-in-bottom .6s ease-in-out both;

  &.delay1 {
    animation-delay: 100ms;
  }

  &.delay2 {
    animation-delay: 200ms;
  }

  &.delay3 {
    animation-delay: 300ms;
  }

  &.delay4 {
    animation-delay: 400ms;
  }

  &.delay5 {
    animation-delay: 500ms;
  }

}

@keyframes fade-in-bottom {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: rotateZ(0);
  }
  $pos: 1;
  @for $i from 1 through 9 {
    $pos: $pos * -1;
    $m: (50 / $i) * $pos;
    #{$i*10%} {
      transform: rotateZ($m * 1deg)
    }
  }
  100% {
    transform: rotateZ(0);
  }
}

.wrapper {
  overflow: hidden;
  background: var(--Linear, linear-gradient(111deg, #40143C 0%, #270650 96.23%));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  min-height: calc(100vh);
  min-height: calc((var(--vh, 1vh) * 100));
  position: relative;

  padding-top: 80px;

  @media screen and (max-width: 600px) {
    padding-top: 55px;
  }

  .star {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    min-height: calc(100vh - 80px);
    min-height: calc((var(--vh, 1vh) * 100) - 80px);
    position: relative;

    &.active {
      background: url("@/assets/img/ui/star-d.png") repeat center;
      background-size: 100%;
    }

    @media screen and (max-width: 600px) {
      &.active {
        background: url("@/assets/img/ui/star-t.png") repeat;
        background-size: 100%;
      }
    }

    @media screen and (max-width: 600px) {
      min-height: calc(100vh - 55px);
      min-height: calc((var(--vh, 1vh) * 100) - 55px);

      &.active {
        background: url("@/assets/img/ui/star-m.png") repeat;
        background-size: 100%;
      }
    }
  }
}

.wrapper.active {
  cursor: none !important;
  outline: none !important;
}
</style>
