<script setup>

</script>

<template>
  <div class="self-med">
    <svg class="desk" viewBox="0 0 1320 146" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path d="M1318.12 42.9693V37.5129H1320V42.9693H1318.12Z" fill="#C298DC"/>
        <path
          d="M1308.48 43.5463C1306.82 43.5463 1305.53 43.0042 1304.61 41.9199C1303.7 40.8006 1303.05 39.2791 1302.67 37.3554C1302.3 35.4317 1302.12 33.2631 1302.12 30.8497V22.7175H1299.38V42.9692H1297.72V0.472189H1299.38V20.3566H1302.12V12.3819C1302.12 9.93346 1302.31 7.78238 1302.7 5.9286C1303.08 4.07482 1303.73 2.62327 1304.64 1.57397C1305.58 0.524655 1306.86 0 1308.48 0C1310.09 0 1311.36 0.542143 1312.28 1.62643C1313.19 2.67574 1313.84 4.14477 1314.22 6.03353C1314.61 7.88731 1314.8 10.0209 1314.8 12.4343V30.9546C1314.8 33.368 1314.62 35.5191 1314.25 37.4079C1313.89 39.2966 1313.25 40.8006 1312.34 41.9199C1311.42 43.0042 1310.14 43.5463 1308.48 43.5463ZM1308.51 41.1854C1309.74 41.1854 1310.69 40.7832 1311.35 39.9787C1312.03 39.1742 1312.5 38.0549 1312.75 36.6209C1313.03 35.1519 1313.17 33.4555 1313.17 31.5317V11.9097C1313.17 9.98593 1313.03 8.30703 1312.75 6.87298C1312.48 5.43892 1312 4.33715 1311.32 3.56765C1310.66 2.76318 1309.71 2.36095 1308.48 2.36095C1307.24 2.36095 1306.28 2.76318 1305.6 3.56765C1304.92 4.33715 1304.44 5.43892 1304.17 6.87298C1303.89 8.30703 1303.75 9.98593 1303.75 11.9097V31.5317C1303.75 33.4555 1303.88 35.1519 1304.13 36.6209C1304.41 38.0549 1304.89 39.1742 1305.57 39.9787C1306.27 40.7832 1307.25 41.1854 1308.51 41.1854Z"
          fill="#C298DC"/>
        <path
          d="M1281.74 42.9697V0.472656H1283.3V36.6738L1292.15 0.472656H1293.58V42.9697H1292.02V6.76851L1283.21 42.9697H1281.74Z"
          fill="#C298DC"/>
        <path
          d="M1265.07 42.9697V0.472656H1266.83V19.6225H1275.89V0.472656H1277.62V42.9697H1275.89V21.8261H1266.83V42.9697H1265.07Z"
          fill="#C298DC"/>
        <path
          d="M1253.17 42.9697V0.472656H1262.11V2.78114H1254.93V19.8324H1260.84V22.036H1254.93V40.7137H1262.21V42.9697H1253.17Z"
          fill="#C298DC"/>
        <path
          d="M1236.51 42.9697V0.472656H1238.26V19.6225H1247.33V0.472656H1249.06V42.9697H1247.33V21.8261H1238.26V42.9697H1236.51Z"
          fill="#C298DC"/>
        <path
          d="M1224.62 42.9697V0.472656H1233.55V2.78114H1226.37V19.8324H1232.28V22.036H1226.37V40.7137H1233.65V42.9697H1224.62Z"
          fill="#C298DC"/>
        <path
          d="M1204.79 42.9697L1205.56 0.472656H1207.28L1212.74 38.8249L1218.3 0.472656H1220.09L1220.82 42.9697H1219.32L1218.68 7.34563L1213.41 42.9697H1212.14L1206.93 7.3981L1206.29 42.9697H1204.79Z"
          fill="#C298DC"/>
        <path
          d="M1189.13 42.9697V0.472656H1190.7V36.6738L1199.54 0.472656H1200.98V42.9697H1199.41V6.76851L1190.6 42.9697H1189.13Z"
          fill="#C298DC"/>
        <path
          d="M1174.31 42.9697V0.472656H1180.37C1181.93 0.472656 1183.14 0.962333 1184.01 1.94169C1184.89 2.88607 1185.49 4.18022 1185.83 5.82414C1186.19 7.46805 1186.38 9.33932 1186.38 11.4379C1186.38 13.3267 1186.19 15.128 1185.83 16.8419C1185.49 18.5558 1184.89 19.9548 1184.01 21.0391C1183.14 22.1234 1181.94 22.6655 1180.4 22.6655H1176.03V42.9697H1174.31ZM1176.03 20.462H1180.02C1181.06 20.462 1181.92 20.1647 1182.58 19.5701C1183.26 18.9755 1183.76 18.0311 1184.08 16.7369C1184.42 15.4078 1184.59 13.6415 1184.59 11.4379C1184.59 9.16444 1184.44 7.3981 1184.14 6.13893C1183.84 4.84478 1183.36 3.95287 1182.7 3.46319C1182.04 2.93853 1181.16 2.67621 1180.05 2.67621H1176.03V20.462Z"
          fill="#C298DC"/>
        <path d="M1157.55 42.9697V0.472656H1170.16V42.9697H1168.43V2.67621H1159.27V42.9697H1157.55Z" fill="#C298DC"/>
        <path
          d="M1137.89 47.849L1137.93 45.593C1138.97 45.6279 1139.79 45.523 1140.38 45.2782C1141 45.0683 1141.46 44.6486 1141.76 44.019C1142.06 43.4244 1142.27 42.5849 1142.4 41.5006C1142.54 40.3814 1142.67 38.9648 1142.78 37.2509L1137 0.472656H1138.72L1141.66 20.0947L1143.64 33.8932L1145.36 19.9898L1147.95 0.472656H1149.71L1144.57 37.5133C1144.35 39.1572 1144.15 40.5912 1143.96 41.8154C1143.77 43.0047 1143.55 43.984 1143.29 44.7535C1143.03 45.558 1142.69 46.1876 1142.27 46.6423C1141.84 47.1319 1141.28 47.4642 1140.58 47.6391C1139.87 47.814 1138.98 47.8839 1137.89 47.849Z"
          fill="#C298DC"/>
        <path
          d="M1118.49 42.9697L1119.25 0.472656H1120.98L1126.44 38.8249L1131.99 0.472656H1133.78L1134.51 42.9697H1133.01L1132.37 7.34563L1127.11 42.9697H1125.83L1120.63 7.3981L1119.99 42.9697H1118.49Z"
          fill="#C298DC"/>
        <path
          d="M1108.82 43.5463C1107.14 43.5463 1105.83 43.0042 1104.9 41.9199C1103.98 40.8006 1103.34 39.2966 1102.98 37.4079C1102.62 35.4841 1102.44 33.3156 1102.44 30.9022V12.3294C1102.44 9.91597 1102.63 7.78238 1103.01 5.9286C1103.4 4.07482 1104.04 2.62327 1104.96 1.57397C1105.9 0.524655 1107.18 0 1108.82 0C1110.46 0 1111.74 0.524655 1112.65 1.57397C1113.59 2.62327 1114.25 4.07482 1114.63 5.9286C1115.02 7.78238 1115.21 9.91597 1115.21 12.3294V30.9546C1115.21 33.368 1115.03 35.5191 1114.67 37.4079C1114.3 39.2966 1113.65 40.8006 1112.72 41.9199C1111.8 43.0042 1110.5 43.5463 1108.82 43.5463ZM1108.82 41.1854C1110.06 41.1854 1111 40.7832 1111.66 39.9787C1112.35 39.1742 1112.81 38.0549 1113.07 36.6209C1113.32 35.1519 1113.45 33.4555 1113.45 31.5317V11.9097C1113.45 9.98593 1113.32 8.30703 1113.07 6.87298C1112.81 5.43892 1112.36 4.33715 1111.7 3.56765C1111.04 2.76318 1110.08 2.36095 1108.82 2.36095C1107.59 2.36095 1106.63 2.76318 1105.95 3.56765C1105.29 4.33715 1104.83 5.43892 1104.58 6.87298C1104.32 8.30703 1104.19 9.98593 1104.19 11.9097V31.5317C1104.19 33.4555 1104.32 35.1519 1104.58 36.6209C1104.83 38.0549 1105.29 39.1742 1105.95 39.9787C1106.63 40.7832 1107.59 41.1854 1108.82 41.1854Z"
          fill="#C298DC"/>
        <path
          d="M1088.62 42.9697V0.472656H1090.38V20.4095H1092.52L1098.2 0.472656H1099.99L1094.05 21.0916L1100.95 42.9697H1099.19L1092.52 22.0884H1090.38V42.9697H1088.62Z"
          fill="#C298DC"/>
        <path
          d="M1079.42 43.5463C1077.72 43.5463 1076.41 42.9692 1075.49 41.815C1074.6 40.6607 1073.98 39.1217 1073.64 37.198C1073.3 35.2393 1073.13 33.0882 1073.13 30.7448V12.6966C1073.13 10.2483 1073.31 8.07968 1073.67 6.19092C1074.03 4.26719 1074.67 2.76318 1075.59 1.67889C1076.5 0.559631 1077.78 0 1079.42 0C1080.93 0 1082.11 0.489679 1082.96 1.46904C1083.81 2.44839 1084.42 3.84747 1084.78 5.66627C1085.15 7.4501 1085.33 9.54871 1085.33 11.9621V14.1132H1083.67V11.9621C1083.67 9.93346 1083.55 8.21959 1083.31 6.82051C1083.08 5.38646 1082.65 4.28468 1082.04 3.51519C1081.42 2.74569 1080.55 2.36095 1079.42 2.36095C1078.19 2.36095 1077.24 2.78067 1076.58 3.62012C1075.92 4.45957 1075.47 5.63129 1075.24 7.1353C1075 8.63931 1074.89 10.3882 1074.89 12.3819V31.1645C1074.89 33.2631 1075.01 35.0644 1075.27 36.5684C1075.52 38.0375 1075.98 39.1742 1076.64 39.9787C1077.3 40.7832 1078.23 41.1854 1079.42 41.1854C1080.57 41.1854 1081.45 40.7832 1082.07 39.9787C1082.69 39.1742 1083.1 38.0549 1083.31 36.6209C1083.55 35.1519 1083.67 33.4555 1083.67 31.5317V28.9609H1085.33V31.0596C1085.33 33.473 1085.16 35.6241 1084.82 37.5128C1084.47 39.4016 1083.88 40.8881 1083.03 41.9724C1082.18 43.0217 1080.97 43.5463 1079.42 43.5463Z"
          fill="#C298DC"/>
        <path
          d="M1056.97 42.9697V0.472656H1058.73V19.6225H1067.8V0.472656H1069.52V42.9697H1067.8V21.8261H1058.73V42.9697H1056.97Z"
          fill="#C298DC"/>
        <path
          d="M1041.03 42.9697V0.472656H1042.59V36.6738L1051.43 0.472656H1052.87V42.9697H1051.31V6.76851L1042.49 42.9697H1041.03Z"
          fill="#C298DC"/>
        <path
          d="M1037.05 50L1036.85 42.9697H1023.96V0.472656H1025.71V40.7661H1034.91V0.472656H1036.66V40.7661H1038.45V50H1037.05Z"
          fill="#C298DC"/>
        <path
          d="M1008.01 42.9697V0.472656H1009.57V36.6738L1018.42 0.472656H1019.85V42.9697H1018.29V6.76851L1009.48 42.9697H1008.01Z"
          fill="#C298DC"/>
        <path
          d="M988.547 50V40.7661H989.473C989.898 40.3114 990.282 39.8567 990.622 39.402C990.984 38.9123 991.303 38.1953 991.58 37.2509C991.857 36.3066 992.112 34.9774 992.346 33.2636C992.602 31.5147 992.836 29.1713 993.049 26.2332C993.261 23.2602 993.474 19.5001 993.687 14.9531L994.358 0.472656H1003.46V40.7661H1005.34V50H1003.94L1003.74 42.9697H990.143L989.952 50H988.547ZM991.42 40.7661H1001.7V2.67621H995.954L995.347 15.4778C995.134 20.0248 994.911 23.7673 994.677 26.7054C994.464 29.6085 994.241 31.917 994.006 33.6308C993.772 35.3447 993.517 36.6563 993.24 37.5657C992.985 38.4402 992.708 39.1047 992.41 39.5594C992.112 39.9791 991.782 40.3814 991.42 40.7661Z"
          fill="#C298DC"/>
        <path
          d="M978.728 42.9697V0.472656H987.667V2.78114H980.484V19.8324H986.39V22.036H980.484V40.7137H987.763V42.9697H978.728Z"
          fill="#C298DC"/>
        <path
          d="M958.907 42.9697L959.673 0.472656H961.398L966.857 38.8249L972.412 0.472656H974.2L974.935 42.9697H973.434L972.795 7.34563L967.528 42.9697H966.25L961.046 7.3981L960.408 42.9697H958.907Z"
          fill="#C298DC"/>
        <path
          d="M943.835 43.5463C942.153 43.5463 940.844 43.0042 939.908 41.9199C938.992 40.8006 938.354 39.2966 937.992 37.4079C937.63 35.4841 937.449 33.3156 937.449 30.9022V12.3294C937.449 9.91597 937.641 7.78238 938.024 5.9286C938.407 4.07482 939.056 2.62327 939.971 1.57397C940.908 0.524655 942.196 0 943.835 0C945.474 0 946.751 0.524655 947.666 1.57397C948.602 2.62327 949.262 4.07482 949.645 5.9286C950.029 7.78238 950.22 9.91597 950.22 12.3294V30.9546C950.22 33.368 950.039 35.5191 949.677 37.4079C949.315 39.2966 948.666 40.8006 947.73 41.9199C946.814 43.0042 945.516 43.5463 943.835 43.5463ZM943.835 41.1854C945.069 41.1854 946.016 40.7832 946.676 39.9787C947.357 39.1742 947.826 38.0549 948.081 36.6209C948.336 35.1519 948.464 33.4555 948.464 31.5317V11.9097C948.464 9.98593 948.336 8.30703 948.081 6.87298C947.826 5.43892 947.368 4.33715 946.708 3.56765C946.048 2.76318 945.09 2.36095 943.835 2.36095C942.6 2.36095 941.642 2.76318 940.961 3.56765C940.301 4.33715 939.844 5.43892 939.588 6.87298C939.333 8.30703 939.205 9.98593 939.205 11.9097V31.5317C939.205 33.4555 939.333 35.1519 939.588 36.6209C939.844 38.0549 940.301 39.1742 940.961 39.9787C941.642 40.7832 942.6 41.1854 943.835 41.1854Z"
          fill="#C298DC"/>
        <path d="M921.232 42.9697V0.472656H933.844V42.9697H932.12V2.67621H922.956V42.9697H921.232Z" fill="#C298DC"/>
        <path
          d="M905.895 43.5463C904.235 43.5463 902.947 43.0042 902.032 41.9199C901.117 40.8006 900.468 39.2791 900.084 37.3554C899.723 35.4317 899.542 33.2631 899.542 30.8497V22.7175H896.796V42.9692H895.136V0.472189H896.796V20.3566H899.542V12.3819C899.542 9.93346 899.733 7.78238 900.116 5.9286C900.5 4.07482 901.149 2.62327 902.064 1.57397C903 0.524655 904.278 0 905.895 0C907.513 0 908.779 0.542143 909.695 1.62643C910.61 2.67574 911.259 4.14477 911.642 6.03353C912.025 7.88731 912.217 10.0209 912.217 12.4343V30.9546C912.217 33.368 912.036 35.5191 911.674 37.4079C911.312 39.2966 910.674 40.8006 909.758 41.9199C908.843 43.0042 907.555 43.5463 905.895 43.5463ZM905.927 41.1854C907.162 41.1854 908.109 40.7832 908.769 39.9787C909.45 39.1742 909.918 38.0549 910.173 36.6209C910.45 35.1519 910.588 33.4555 910.588 31.5317V11.9097C910.588 9.98593 910.45 8.30703 910.173 6.87298C909.897 5.43892 909.418 4.33715 908.737 3.56765C908.077 2.76318 907.13 2.36095 905.895 2.36095C904.661 2.36095 903.703 2.76318 903.022 3.56765C902.341 4.33715 901.862 5.43892 901.585 6.87298C901.308 8.30703 901.17 9.98593 901.17 11.9097V31.5317C901.17 33.4555 901.298 35.1519 901.553 36.6209C901.83 38.0549 902.309 39.1742 902.99 39.9787C903.692 40.7832 904.671 41.1854 905.927 41.1854Z"
          fill="#C298DC"/>
        <path
          d="M879.154 42.9697V0.472656H880.719V36.6738L889.563 0.472656H890.999V42.9697H889.435V6.76851L880.623 42.9697H879.154Z"
          fill="#C298DC"/>
        <path
          d="M875.172 50L874.981 42.9697H862.082V0.472656H863.838V40.7661H873.033V0.472656H874.789V40.7661H876.577V50H875.172Z"
          fill="#C298DC"/>
        <path
          d="M847.758 42.9697V0.472656H849.514V20.4095H851.653L857.336 0.472656H859.124L853.185 21.0916L860.082 42.9697H858.326L851.653 22.0884H849.514V42.9697H847.758Z"
          fill="#C298DC"/>
        <path
          d="M833.544 47.849L833.576 45.593C834.619 45.6279 835.439 45.523 836.035 45.2782C836.652 45.0683 837.11 44.6486 837.408 44.019C837.705 43.4244 837.918 42.5849 838.046 41.5006C838.195 40.3814 838.323 38.9648 838.429 37.2509L832.65 0.472656H834.374L837.312 20.0947L839.291 33.8932L841.015 19.9898L843.601 0.472656H845.357L840.217 37.5133C840.004 39.1572 839.802 40.5912 839.61 41.8154C839.419 43.0047 839.195 43.984 838.94 44.7535C838.685 45.558 838.344 46.1876 837.918 46.6423C837.493 47.1319 836.929 47.4642 836.226 47.6391C835.524 47.814 834.63 47.8839 833.544 47.849Z"
          fill="#C298DC"/>
        <path
          d="M819.133 42.9697V0.472656H825.199C826.753 0.472656 827.966 0.962333 828.839 1.94169C829.711 2.88607 830.318 4.18022 830.658 5.82414C831.02 7.46805 831.201 9.33932 831.201 11.4379C831.201 13.3267 831.02 15.128 830.658 16.8419C830.318 18.5558 829.711 19.9548 828.839 21.0391C827.966 22.1234 826.763 22.6655 825.231 22.6655H820.857V42.9697H819.133ZM820.857 20.462H824.848C825.891 20.462 826.742 20.1647 827.402 19.5701C828.083 18.9755 828.583 18.0311 828.902 16.7369C829.243 15.4078 829.413 13.6415 829.413 11.4379C829.413 9.16444 829.264 7.3981 828.966 6.13893C828.668 4.84478 828.189 3.95287 827.53 3.46319C826.87 2.93853 825.986 2.67621 824.88 2.67621H820.857V20.462Z"
          fill="#C298DC"/>
        <path d="M809.968 42.9697V2.78114H804.796V0.472656H816.737V2.78114H811.692V42.9697H809.968Z" fill="#C298DC"/>
        <path
          d="M797.318 43.5463C795.615 43.5463 794.306 42.9692 793.391 41.815C792.497 40.6607 791.88 39.1217 791.539 37.198C791.199 35.2393 791.028 33.0882 791.028 30.7448V12.6966C791.028 10.2483 791.209 8.07968 791.571 6.19092C791.933 4.26719 792.572 2.76318 793.487 1.67889C794.402 0.559631 795.679 0 797.318 0C798.829 0 800.011 0.489679 800.862 1.46904C801.713 2.44839 802.32 3.84747 802.682 5.66627C803.044 7.4501 803.225 9.54871 803.225 11.9621V14.1132H801.564V11.9621C801.564 9.93346 801.447 8.21959 801.213 6.82051C800.979 5.38646 800.553 4.28468 799.936 3.51519C799.319 2.74569 798.446 2.36095 797.318 2.36095C796.083 2.36095 795.136 2.78067 794.476 3.62012C793.817 4.45957 793.37 5.63129 793.136 7.1353C792.901 8.63931 792.784 10.3882 792.784 12.3819V31.1645C792.784 33.2631 792.912 35.0644 793.167 36.5684C793.423 38.0375 793.88 39.1742 794.54 39.9787C795.2 40.7832 796.126 41.1854 797.318 41.1854C798.467 41.1854 799.351 40.7832 799.968 39.9787C800.585 39.1742 801 38.0549 801.213 36.6209C801.447 35.1519 801.564 33.4555 801.564 31.5317V28.9609H803.225V31.0596C803.225 33.473 803.054 35.6241 802.714 37.5128C802.373 39.4016 801.777 40.8881 800.926 41.9724C800.074 43.0217 798.872 43.5463 797.318 43.5463Z"
          fill="#C298DC"/>
        <path
          d="M774.873 42.9697V0.472656H776.629V19.6225H785.696V0.472656H787.42V42.9697H785.696V21.8261H776.629V42.9697H774.873Z"
          fill="#C298DC"/>
        <path
          d="M758.925 42.9697V0.472656H760.489V36.6738L769.333 0.472656H770.77V42.9697H769.205V6.76851L760.393 42.9697H758.925Z"
          fill="#C298DC"/>
        <path
          d="M739.468 42.9697V0.472656H741.192V18.4158H744.097C745.694 18.4158 747.003 18.888 748.024 19.8324C749.046 20.7418 749.802 22.1234 750.291 23.9772C750.781 25.831 751.025 28.1569 751.025 30.9551C751.025 32.7039 750.898 34.3129 750.642 35.7819C750.408 37.2509 750.014 38.5276 749.461 39.6119C748.929 40.6612 748.226 41.4832 747.354 42.0778C746.481 42.6724 745.417 42.9697 744.161 42.9697H739.468ZM741.192 40.6612H744.225C745.481 40.6612 746.47 40.2939 747.194 39.5594C747.939 38.7899 748.471 37.6882 748.791 36.2541C749.11 34.7851 749.269 33.0012 749.269 30.9026C749.269 27.7197 748.897 25.2364 748.152 23.4525C747.407 21.6337 746.087 20.7243 744.193 20.7243H741.192V40.6612Z"
          fill="#C298DC"/>
        <path d="M730.303 42.9697V2.78114H725.131V0.472656H737.072V2.78114H732.027V42.9697H730.303Z" fill="#C298DC"/>
        <path
          d="M712.371 42.9697L717.799 0.472656H719.81L725.27 42.9697H723.514L722.173 31.2174H715.468L714.095 42.9697H712.371ZM715.723 29.1188H721.949L718.82 3.93538L715.723 29.1188Z"
          fill="#C298DC"/>
        <path d="M704.578 42.9697V2.78114H699.406V0.472656H711.347V2.78114H706.302V42.9697H704.578Z" fill="#C298DC"/>
        <path
          d="M685.182 42.9697V0.472656H686.746V36.6738L695.59 0.472656H697.027V42.9697H695.462V6.76851L686.65 42.9697H685.182Z"
          fill="#C298DC"/>
        <path
          d="M679.312 42.9697V23.2427C677.972 23.8723 676.705 24.187 675.513 24.187C674.172 24.187 673.012 23.8897 672.033 23.2951C671.075 22.6655 670.33 21.6862 669.798 20.3571C669.266 18.993 669 17.2091 669 15.0056V0.472656H670.756V15.0056C670.756 16.9293 670.979 18.3984 671.426 19.4127C671.873 20.427 672.469 21.1266 673.214 21.5113C673.981 21.8961 674.832 22.0884 675.769 22.0884C676.45 22.0884 677.088 22.001 677.684 21.8261C678.301 21.6162 678.844 21.3714 679.312 21.0916V0.472656H681.068V42.9697H679.312Z"
          fill="#C298DC"/>
        <path
          d="M659.753 43.5463C658.071 43.5463 656.762 43.0042 655.826 41.9199C654.91 40.8006 654.272 39.2966 653.91 37.4079C653.548 35.4841 653.367 33.3156 653.367 30.9022V12.3294C653.367 9.91597 653.559 7.78238 653.942 5.9286C654.325 4.07482 654.974 2.62327 655.889 1.57397C656.826 0.524655 658.114 0 659.753 0C661.392 0 662.669 0.524655 663.584 1.57397C664.52 2.62327 665.18 4.07482 665.563 5.9286C665.946 7.78238 666.138 9.91597 666.138 12.3294V30.9546C666.138 33.368 665.957 35.5191 665.595 37.4079C665.233 39.2966 664.584 40.8006 663.648 41.9199C662.732 43.0042 661.434 43.5463 659.753 43.5463ZM659.753 41.1854C660.987 41.1854 661.934 40.7832 662.594 39.9787C663.275 39.1742 663.744 38.0549 663.999 36.6209C664.254 35.1519 664.382 33.4555 664.382 31.5317V11.9097C664.382 9.98593 664.254 8.30703 663.999 6.87298C663.744 5.43892 663.286 4.33715 662.626 3.56765C661.966 2.76318 661.008 2.36095 659.753 2.36095C658.518 2.36095 657.56 2.76318 656.879 3.56765C656.219 4.33715 655.762 5.43892 655.506 6.87298C655.251 8.30703 655.123 9.98593 655.123 11.9097V31.5317C655.123 33.4555 655.251 35.1519 655.506 36.6209C655.762 38.0549 656.219 39.1742 656.879 39.9787C657.56 40.7832 658.518 41.1854 659.753 41.1854Z"
          fill="#C298DC"/>
        <path
          d="M639.052 42.9697V0.472656H645.118C646.672 0.472656 647.885 0.962333 648.758 1.94169C649.63 2.88607 650.237 4.18022 650.577 5.82414C650.939 7.46805 651.12 9.33932 651.12 11.4379C651.12 13.3267 650.939 15.128 650.577 16.8419C650.237 18.5558 649.63 19.9548 648.758 21.0391C647.885 22.1234 646.682 22.6655 645.15 22.6655H640.776V42.9697H639.052ZM640.776 20.462H644.767C645.81 20.462 646.661 20.1647 647.321 19.5701C648.002 18.9755 648.502 18.0311 648.821 16.7369C649.162 15.4078 649.332 13.6415 649.332 11.4379C649.332 9.16444 649.183 7.3981 648.885 6.13893C648.587 4.84478 648.108 3.95287 647.449 3.46319C646.789 2.93853 645.905 2.67621 644.799 2.67621H640.776V20.462Z"
          fill="#C298DC"/>
        <path d="M622.294 42.9697V0.472656H634.905V42.9697H633.181V2.67621H624.018V42.9697H622.294Z" fill="#C298DC"/>
        <path
          d="M606.902 43.5463C605.221 43.5463 603.912 43.0042 602.975 41.9199C602.06 40.8006 601.421 39.2966 601.059 37.4079C600.698 35.4841 600.517 33.3156 600.517 30.9022V12.3294C600.517 9.91597 600.708 7.78238 601.091 5.9286C601.474 4.07482 602.124 2.62327 603.039 1.57397C603.975 0.524655 605.263 0 606.902 0C608.541 0 609.818 0.524655 610.733 1.57397C611.67 2.62327 612.33 4.07482 612.713 5.9286C613.096 7.78238 613.287 9.91597 613.287 12.3294V30.9546C613.287 33.368 613.107 35.5191 612.745 37.4079C612.383 39.2966 611.734 40.8006 610.797 41.9199C609.882 43.0042 608.583 43.5463 606.902 43.5463ZM606.902 41.1854C608.137 41.1854 609.084 40.7832 609.744 39.9787C610.425 39.1742 610.893 38.0549 611.148 36.6209C611.404 35.1519 611.531 33.4555 611.531 31.5317V11.9097C611.531 9.98593 611.404 8.30703 611.148 6.87298C610.893 5.43892 610.435 4.33715 609.775 3.56765C609.116 2.76318 608.158 2.36095 606.902 2.36095C605.667 2.36095 604.71 2.76318 604.029 3.56765C603.369 4.33715 602.911 5.43892 602.656 6.87298C602.4 8.30703 602.273 9.98593 602.273 11.9097V31.5317C602.273 33.4555 602.4 35.1519 602.656 36.6209C602.911 38.0549 603.369 39.1742 604.029 39.9787C604.71 40.7832 605.667 41.1854 606.902 41.1854Z"
          fill="#C298DC"/>
        <path
          d="M584.362 42.9697V0.472656H586.118V19.6225H595.186V0.472656H596.91V42.9697H595.186V21.8261H586.118V42.9697H584.362Z"
          fill="#C298DC"/>
        <path
          d="M570.315 42.9697V0.472656H572.039V18.4158H574.945C576.541 18.4158 577.85 18.888 578.872 19.8324C579.894 20.7418 580.649 22.1234 581.139 23.9772C581.628 25.831 581.873 28.1569 581.873 30.9551C581.873 32.7039 581.745 34.3129 581.49 35.7819C581.256 37.2509 580.862 38.5276 580.309 39.6119C579.777 40.6612 579.074 41.4832 578.201 42.0778C577.329 42.6724 576.264 42.9697 575.009 42.9697H570.315ZM572.039 40.6612H575.073C576.328 40.6612 577.318 40.2939 578.042 39.5594C578.787 38.7899 579.319 37.6882 579.638 36.2541C579.957 34.7851 580.117 33.0012 580.117 30.9026C580.117 27.7197 579.745 25.2364 579 23.4525C578.255 21.6337 576.935 20.7243 575.041 20.7243H572.039V40.6612Z"
          fill="#C298DC"/>
        <path
          d="M551.586 43.0746V40.8186C552.267 40.7486 552.831 40.6087 553.278 40.3989C553.725 40.189 554.087 39.6993 554.364 38.9298C554.64 38.1603 554.874 36.9187 555.066 35.2048C555.258 33.4909 555.438 31.1125 555.609 28.0695C555.8 25.0265 556.013 21.1091 556.247 16.3172L556.95 0.472656H566.209V42.9697H564.453V2.67621H558.578L557.939 16.3172C557.705 21.1091 557.492 25.1139 557.301 28.3318C557.109 31.5147 556.886 34.0855 556.63 36.0442C556.396 38.003 556.077 39.4895 555.673 40.5038C555.29 41.4832 554.768 42.1477 554.108 42.4975C553.448 42.8473 552.608 43.0396 551.586 43.0746Z"
          fill="#C298DC"/>
        <path
          d="M541.255 42.9697V0.472656H550.194V2.78114H543.011V19.8324H548.917V22.036H543.011V40.7137H550.29V42.9697H541.255Z"
          fill="#C298DC"/>
        <path d="M532.121 42.9697V2.78114H526.949V0.472656H538.89V2.78114H533.845V42.9697H532.121Z" fill="#C298DC"/>
        <path
          d="M514.189 42.9697L519.617 0.472656H521.628L527.088 42.9697H525.332L523.991 31.2174H517.286L515.914 42.9697H514.189ZM517.542 29.1188H523.768L520.639 3.93538L517.542 29.1188Z"
          fill="#C298DC"/>
        <path
          d="M495.71 42.9697L496.476 0.472656H498.2L503.66 38.8249L509.215 0.472656H511.003L511.737 42.9697H510.237L509.598 7.34563L504.33 42.9697H503.053L497.849 7.3981L497.21 42.9697H495.71Z"
          fill="#C298DC"/>
        <path
          d="M480.049 42.9697V0.472656H481.613V36.6738L490.457 0.472656H491.894V42.9697H490.329V6.76851L481.517 42.9697H480.049Z"
          fill="#C298DC"/>
        <path
          d="M463.383 42.9697V0.472656H465.139V19.6225H474.206V0.472656H475.93V42.9697H474.206V21.8261H465.139V42.9697H463.383Z"
          fill="#C298DC"/>
        <path
          d="M448.463 42.9697V0.472656H453.795C454.987 0.472656 455.966 0.752472 456.732 1.3121C457.52 1.83676 458.126 2.58876 458.552 3.56812C458.999 4.54747 459.307 5.68423 459.478 6.97837C459.669 8.23755 459.765 9.60165 459.765 11.0707C459.765 12.1899 459.68 13.3966 459.51 14.6908C459.361 15.95 459.073 17.0692 458.648 18.0486C458.222 19.0279 457.583 19.6925 456.732 20.0423C457.732 20.532 458.488 21.3714 458.999 22.5606C459.531 23.7498 459.882 25.079 460.052 26.548C460.244 27.982 460.34 29.3287 460.34 30.5878C460.34 32.1968 460.265 33.7358 460.116 35.2048C459.989 36.6738 459.723 38.0029 459.318 39.1922C458.914 40.3464 458.328 41.2733 457.562 41.9728C456.817 42.6374 455.817 42.9697 454.561 42.9697H448.463ZM450.187 40.7137H454.337C456.04 40.7137 457.179 39.8917 457.754 38.2478C458.35 36.6039 458.648 34.068 458.648 30.6403C458.648 28.9264 458.52 27.3699 458.265 25.9709C458.03 24.5718 457.615 23.4525 457.019 22.6131C456.445 21.7736 455.636 21.3539 454.593 21.3539H450.187V40.7137ZM450.187 19.0979H454.561C455.519 19.0979 456.253 18.7306 456.764 17.9961C457.275 17.2616 457.626 16.2997 457.818 15.1105C458.03 13.8863 458.137 12.5397 458.137 11.0707C458.137 9.49672 458.02 8.08015 457.786 6.82098C457.551 5.56181 457.094 4.58245 456.413 3.88291C455.753 3.1484 454.742 2.78114 453.38 2.78114H450.187V19.0979Z"
          fill="#C298DC"/>
        <path
          d="M423.201 42.9697L423.967 0.472656H425.691L431.151 38.8249L436.706 0.472656H438.494L439.229 42.9697H437.728L437.089 7.34563L431.822 42.9697H430.544L425.34 7.3981L424.702 42.9697H423.201Z"
          fill="#C298DC"/>
        <path
          d="M411.593 42.9697V0.472656H420.532V2.78114H413.349V19.8324H419.255V22.036H413.349V40.7137H420.628V42.9697H411.593Z"
          fill="#C298DC"/>
        <path
          d="M395.645 42.9697V0.472656H397.209V36.6738L406.053 0.472656H407.489V42.9697H405.925V6.76851L397.113 42.9697H395.645Z"
          fill="#C298DC"/>
        <path
          d="M378.979 42.9697V0.472656H380.735V19.6225H389.802V0.472656H391.526V42.9697H389.802V21.8261H380.735V42.9697H378.979Z"
          fill="#C298DC"/>
        <path
          d="M367.083 42.9697V0.472656H376.023V2.78114H368.839V19.8324H374.746V22.036H368.839V40.7137H376.118V42.9697H367.083Z"
          fill="#C298DC"/>
        <path
          d="M350.417 42.9697V0.472656H352.173V19.6225H361.24V0.472656H362.964V42.9697H361.24V21.8261H352.173V42.9697H350.417Z"
          fill="#C298DC"/>
        <path
          d="M338.521 42.9697V0.472656H347.461V2.78114H340.277V19.8324H346.184V22.036H340.277V40.7137H347.557V42.9697H338.521Z"
          fill="#C298DC"/>
        <path
          d="M318.7 42.9697L319.466 0.472656H321.191L326.65 38.8249L332.205 0.472656H333.993L334.728 42.9697H333.227L332.588 7.34563L327.321 42.9697H326.043L320.839 7.3981L320.201 42.9697H318.7Z"
          fill="#C298DC"/>
        <path
          d="M303.039 42.9697V0.472656H304.604V36.6738L313.447 0.472656H314.884V42.9697H313.32V6.76851L304.508 42.9697H303.039Z"
          fill="#C298DC"/>
        <path
          d="M288.213 42.9697V0.472656H294.279C295.833 0.472656 297.046 0.962333 297.919 1.94169C298.791 2.88607 299.398 4.18022 299.739 5.82414C300.1 7.46805 300.281 9.33932 300.281 11.4379C300.281 13.3267 300.1 15.128 299.739 16.8419C299.398 18.5558 298.791 19.9548 297.919 21.0391C297.046 22.1234 295.843 22.6655 294.311 22.6655H289.937V42.9697H288.213ZM289.937 20.462H293.928C294.971 20.462 295.822 20.1647 296.482 19.5701C297.163 18.9755 297.663 18.0311 297.983 16.7369C298.323 15.4078 298.493 13.6415 298.493 11.4379C298.493 9.16444 298.344 7.3981 298.046 6.13893C297.748 4.84478 297.27 3.95287 296.61 3.46319C295.95 2.93853 295.067 2.67621 293.96 2.67621H289.937V20.462Z"
          fill="#C298DC"/>
        <path d="M271.453 42.9697V0.472656H284.064V42.9697H282.34V2.67621H273.177V42.9697H271.453Z" fill="#C298DC"/>
        <path
          d="M250.063 42.9697V0.472656H251.628V36.6738L260.472 0.472656H261.908V42.9697H260.344V6.76851L251.532 42.9697H250.063Z"
          fill="#C298DC"/>
        <path
          d="M224.832 42.9697L225.598 0.472656H227.322L232.782 38.8249L238.337 0.472656H240.125L240.859 42.9697H239.359L238.72 7.34563L233.452 42.9697H232.175L226.971 7.3981L226.333 42.9697H224.832Z"
          fill="#C298DC"/>
        <path
          d="M213.225 42.9697V0.472656H222.164V2.78114H214.981V19.8324H220.887V22.036H214.981V40.7137H222.26V42.9697H213.225Z"
          fill="#C298DC"/>
        <path
          d="M197.275 42.9697V0.472656H198.84V36.6738L207.684 0.472656H209.12V42.9697H207.556V6.76851L198.744 42.9697H197.275Z"
          fill="#C298DC"/>
        <path
          d="M180.609 42.9697V0.472656H182.365V19.6225H191.433V0.472656H193.157V42.9697H191.433V21.8261H182.365V42.9697H180.609Z"
          fill="#C298DC"/>
        <path
          d="M168.715 42.9697V0.472656H177.654V2.78114H170.471V19.8324H176.377V22.036H170.471V40.7137H177.75V42.9697H168.715Z"
          fill="#C298DC"/>
        <path
          d="M162.843 42.9697V23.2427C161.502 23.8723 160.235 24.187 159.043 24.187C157.702 24.187 156.542 23.8897 155.563 23.2951C154.606 22.6655 153.861 21.6862 153.328 20.3571C152.796 18.993 152.53 17.2091 152.53 15.0056V0.472656H154.286V15.0056C154.286 16.9293 154.51 18.3984 154.957 19.4127C155.404 20.427 156 21.1266 156.745 21.5113C157.511 21.8961 158.362 22.0884 159.299 22.0884C159.98 22.0884 160.618 22.001 161.214 21.8261C161.832 21.6162 162.374 21.3714 162.843 21.0916V0.472656H164.599V42.9697H162.843Z"
          fill="#C298DC"/>
        <path
          d="M138.499 42.9697L143.927 0.472656H145.938L151.398 42.9697H149.642L148.301 31.2174H141.596L140.223 42.9697H138.499ZM141.851 29.1188H148.077L144.948 3.93538L141.851 29.1188Z"
          fill="#C298DC"/>
        <path
          d="M123.177 42.9697V0.472656H124.933V19.6225H134V0.472656H135.724V42.9697H134V21.8261H124.933V42.9697H123.177Z"
          fill="#C298DC"/>
        <path
          d="M114.365 43.5463C113.067 43.5463 112.013 43.1266 111.204 42.2872C110.417 41.4477 109.842 40.2935 109.48 38.8244C109.119 37.3204 108.938 35.5541 108.938 33.5254V32.7384H110.534C110.534 32.8084 110.534 32.9833 110.534 33.2631C110.534 33.5079 110.534 33.6653 110.534 33.7353C110.555 36.1837 110.864 38.0549 111.46 39.3491C112.056 40.6432 113.024 41.2903 114.365 41.2903C115.174 41.2903 115.898 41.028 116.536 40.5033C117.175 39.9437 117.675 38.9643 118.037 37.5653C118.42 36.1662 118.612 34.2075 118.612 31.6891C118.612 28.891 118.271 26.5825 117.59 24.7637C116.909 22.9449 115.749 22.0005 114.11 21.9306C114.089 21.9306 113.95 21.9306 113.695 21.9306C113.439 21.9306 113.184 21.9306 112.929 21.9306C112.673 21.9306 112.524 21.9306 112.482 21.9306V19.8319C112.524 19.8319 112.673 19.8319 112.929 19.8319C113.184 19.8319 113.439 19.8319 113.695 19.8319C113.95 19.8319 114.078 19.8319 114.078 19.8319C115.738 19.762 116.909 18.975 117.59 17.471C118.271 15.932 118.612 13.5886 118.612 10.4406C118.612 8.06219 118.303 6.10348 117.686 4.5645C117.09 3.02551 116.068 2.25602 114.621 2.25602C113.258 2.25602 112.205 2.81565 111.46 3.93491C110.736 5.05417 110.364 7.01289 110.342 9.81104C110.342 9.881 110.342 10.0209 110.342 10.2308C110.342 10.4057 110.342 10.5281 110.342 10.598H108.746V9.91597C108.746 7.92228 108.927 6.19093 109.289 4.72189C109.672 3.21788 110.289 2.06364 111.141 1.25917C111.992 0.419724 113.131 0 114.557 0C115.876 0 116.962 0.472189 117.813 1.41657C118.665 2.32597 119.293 3.56765 119.697 5.14162C120.123 6.71558 120.336 8.4994 120.336 10.4931C120.336 13.676 119.974 16.1244 119.25 17.8383C118.526 19.5172 117.643 20.5315 116.6 20.8813C117.154 21.0911 117.718 21.5808 118.292 22.3503C118.867 23.0848 119.346 24.2041 119.729 25.7081C120.133 27.2121 120.336 29.1883 120.336 31.6367C120.336 33.9452 120.133 36.0088 119.729 37.8276C119.346 39.6114 118.718 41.0105 117.845 42.0248C116.973 43.0392 115.813 43.5463 114.365 43.5463Z"
          fill="#C298DC"/>
        <path
          d="M93.4922 42.9697L98.9198 0.472656H100.931L106.391 42.9697H104.635L103.294 31.2174H96.5891L95.2163 42.9697H93.4922ZM96.8445 29.1188H103.07L99.9415 3.93538L96.8445 29.1188Z"
          fill="#C298DC"/>
        <path
          d="M78.167 42.9697V0.472656H79.923V19.6225H88.9903V0.472656H90.7143V42.9697H88.9903V21.8261H79.923V42.9697H78.167Z"
          fill="#C298DC"/>
        <path
          d="M53.2988 50V40.7661H54.2247C54.6504 40.3114 55.0335 39.8567 55.3741 39.402C55.7359 38.9123 56.0552 38.1953 56.3319 37.2509C56.6086 36.3066 56.864 34.9774 57.0982 33.2636C57.3536 31.5147 57.5877 29.1713 57.8005 26.2332C58.0134 23.2602 58.2262 19.5001 58.4391 14.9531L59.1096 0.472656H68.2088V40.7661H70.0925V50H68.6877L68.4961 42.9697H54.8952L54.7036 50H53.2988ZM56.1723 40.7661H66.4528V2.67621H60.7059L60.0993 15.4778C59.8865 20.0248 59.663 23.7673 59.4288 26.7054C59.216 29.6085 58.9925 31.917 58.7584 33.6308C58.5242 35.3447 58.2688 36.6563 57.9921 37.5657C57.7367 38.4402 57.46 39.1047 57.162 39.5594C56.864 39.9791 56.5341 40.3814 56.1723 40.7661Z"
          fill="#C298DC"/>
        <path
          d="M43.4795 42.9697V0.472656H52.4191V2.78114H45.2355V19.8324H51.142V22.036H45.2355V40.7137H52.5149V42.9697H43.4795Z"
          fill="#C298DC"/>
        <path
          d="M28.6533 42.9697V0.472656H34.7195C36.2733 0.472656 37.4865 0.962333 38.3592 1.94169C39.2318 2.88607 39.8384 4.18022 40.179 5.82414C40.5408 7.46805 40.7218 9.33932 40.7218 11.4379C40.7218 13.3267 40.5408 15.128 40.179 16.8419C39.8384 18.5558 39.2318 19.9548 38.3592 21.0391C37.4865 22.1234 36.2839 22.6655 34.7514 22.6655H30.3774V42.9697H28.6533ZM30.3774 20.462H34.3683C35.4112 20.462 36.2626 20.1647 36.9224 19.5701C37.6035 18.9755 38.1037 18.0311 38.423 16.7369C38.7636 15.4078 38.9338 13.6415 38.9338 11.4379C38.9338 9.16444 38.7848 7.3981 38.4869 6.13893C38.1889 4.84478 37.71 3.95287 37.0501 3.46319C36.3903 2.93853 35.507 2.67621 34.4002 2.67621H30.3774V20.462Z"
          fill="#C298DC"/>
        <path
          d="M16.7266 42.9697V0.472656H25.6661V2.78114H18.4826V19.8324H24.3891V22.036H18.4826V40.7137H25.7619V42.9697H16.7266Z"
          fill="#C298DC"/>
        <path d="M0 42.9697V0.472656H12.6112V42.9697H10.8871V2.67621H1.72406V42.9697H0Z" fill="#C298DC"/>
        <path
          d="M1294.64 133.345L1304.14 98.4974C1301.74 97.0493 1300.01 94.4365 1298.95 90.659C1297.89 86.8185 1297.36 82.3798 1297.36 77.3431C1297.36 73.6915 1297.71 70.3231 1298.42 67.2381C1299.17 64.1531 1300.42 61.6662 1302.19 59.7775C1303.96 57.8257 1306.42 56.8499 1309.56 56.8499H1320V133.345H1316.7V100.008H1309.39C1309.03 100.008 1308.62 100.008 1308.15 100.008C1307.71 99.9455 1307.34 99.8825 1307.03 99.8195L1298.24 133.345H1294.64ZM1309.8 96.042H1316.7V60.9107H1309.68C1306.77 60.9107 1304.51 62.1699 1302.9 64.6883C1301.33 67.2067 1300.54 71.4249 1300.54 77.3431C1300.54 83.5131 1301.25 88.1721 1302.66 91.32C1304.08 94.468 1306.46 96.042 1309.8 96.042Z"
          fill="#C298DC"/>
        <path
          d="M1271.71 133.345V56.8499H1274.9V89.1479H1280.26C1283.21 89.1479 1285.63 89.9979 1287.52 91.6978C1289.4 93.3347 1290.8 95.8216 1291.7 99.1585C1292.61 102.495 1293.06 106.682 1293.06 111.719C1293.06 114.867 1292.82 117.763 1292.35 120.407C1291.92 123.052 1291.19 125.35 1290.17 127.301C1289.19 129.19 1287.89 130.67 1286.28 131.74C1284.67 132.81 1282.7 133.345 1280.38 133.345H1271.71ZM1274.9 129.19H1280.5C1282.82 129.19 1284.65 128.529 1285.98 127.207C1287.36 125.822 1288.34 123.838 1288.93 121.257C1289.52 118.613 1289.81 115.402 1289.81 111.624C1289.81 105.895 1289.13 101.425 1287.75 98.2141C1286.38 94.9402 1283.94 93.3033 1280.44 93.3033H1274.9V129.19Z"
          fill="#C298DC"/>
        <path
          d="M1244.1 133.345V56.8499H1253.95C1256.15 56.8499 1257.96 57.3535 1259.37 58.3609C1260.83 59.3053 1261.95 60.6589 1262.73 62.4217C1263.56 64.1846 1264.13 66.2308 1264.44 68.5603C1264.8 70.8268 1264.97 73.2822 1264.97 75.9265C1264.97 77.9412 1264.82 80.1133 1264.5 82.4428C1264.23 84.7093 1263.7 86.724 1262.91 88.4869C1262.12 90.2497 1260.95 91.446 1259.37 92.0756C1261.22 92.957 1262.62 94.468 1263.56 96.6086C1264.54 98.7492 1265.19 101.142 1265.51 103.786C1265.86 106.367 1266.04 108.791 1266.04 111.058C1266.04 113.954 1265.9 116.724 1265.62 119.368C1265.39 122.013 1264.9 124.405 1264.15 126.546C1263.4 128.623 1262.32 130.292 1260.91 131.551C1259.53 132.747 1257.68 133.345 1255.36 133.345H1244.1ZM1247.29 129.284H1254.95C1258.1 129.284 1260.2 127.805 1261.26 124.846C1262.36 121.887 1262.91 117.322 1262.91 111.152C1262.91 108.067 1262.68 105.266 1262.2 102.747C1261.77 100.229 1261 98.2141 1259.9 96.7031C1258.84 95.192 1257.35 94.4365 1255.42 94.4365H1247.29V129.284ZM1247.29 90.3757H1255.36C1257.13 90.3757 1258.49 89.7146 1259.43 88.3924C1260.38 87.0703 1261.02 85.3389 1261.38 83.1983C1261.77 80.9947 1261.97 78.5708 1261.97 75.9265C1261.97 73.0933 1261.75 70.5435 1261.32 68.277C1260.89 66.0104 1260.04 64.2476 1258.78 62.9884C1257.56 61.6662 1255.7 61.0052 1253.18 61.0052H1247.29V90.3757Z"
          fill="#C298DC"/>
        <path
          d="M1225.67 134.384C1222.56 134.384 1220.14 133.408 1218.41 131.457C1216.72 129.442 1215.54 126.735 1214.88 123.335C1214.21 119.872 1213.87 115.969 1213.87 111.624V78.1931C1213.87 73.8489 1214.23 70.0084 1214.94 66.6716C1215.64 63.3348 1216.84 60.7219 1218.53 58.8332C1220.26 56.9444 1222.64 56 1225.67 56C1228.69 56 1231.05 56.9444 1232.74 58.8332C1234.47 60.7219 1235.69 63.3348 1236.4 66.6716C1237.11 70.0084 1237.46 73.8489 1237.46 78.1931V111.719C1237.46 116.063 1237.13 119.935 1236.46 123.335C1235.79 126.735 1234.59 129.442 1232.86 131.457C1231.17 133.408 1228.77 134.384 1225.67 134.384ZM1225.67 130.135C1227.95 130.135 1229.7 129.41 1230.91 127.962C1232.17 126.514 1233.04 124.5 1233.51 121.918C1233.98 119.274 1234.22 116.221 1234.22 112.758V77.4376C1234.22 73.9749 1233.98 70.9528 1233.51 68.3715C1233.04 65.7902 1232.19 63.8069 1230.97 62.4218C1229.76 60.9738 1227.99 60.2498 1225.67 60.2498C1223.39 60.2498 1221.62 60.9738 1220.36 62.4218C1219.14 63.8069 1218.3 65.7902 1217.82 68.3715C1217.35 70.9528 1217.12 73.9749 1217.12 77.4376V112.758C1217.12 116.221 1217.35 119.274 1217.82 121.918C1218.3 124.5 1219.14 126.514 1220.36 127.962C1221.62 129.41 1223.39 130.135 1225.67 130.135Z"
          fill="#C298DC"/>
        <path
          d="M1187.44 133.345V56.8499H1198.64C1201.51 56.8499 1203.75 57.7313 1205.36 59.4941C1206.98 61.194 1208.1 63.5235 1208.72 66.4826C1209.39 69.4417 1209.73 72.81 1209.73 76.5876C1209.73 79.9874 1209.39 83.2298 1208.72 86.3148C1208.1 89.3998 1206.98 91.9182 1205.36 93.8699C1203.75 95.8216 1201.53 96.7975 1198.7 96.7975H1190.62V133.345H1187.44ZM1190.62 92.8311H1197.99C1199.92 92.8311 1201.49 92.2959 1202.71 91.2256C1203.97 90.1553 1204.89 88.4554 1205.48 86.1259C1206.11 83.7335 1206.42 80.554 1206.42 76.5876C1206.42 72.4952 1206.15 69.3158 1205.6 67.0493C1205.05 64.7198 1204.16 63.1143 1202.95 62.2329C1201.73 61.2885 1200.1 60.8163 1198.05 60.8163H1190.62V92.8311Z"
          fill="#C298DC"/>
        <path
          d="M1169 134.384C1165.9 134.384 1163.48 133.408 1161.75 131.457C1160.06 129.442 1158.88 126.735 1158.21 123.335C1157.55 119.872 1157.21 115.969 1157.21 111.624V78.1931C1157.21 73.8489 1157.57 70.0084 1158.27 66.6716C1158.98 63.3348 1160.18 60.7219 1161.87 58.8332C1163.6 56.9444 1165.98 56 1169 56C1172.03 56 1174.39 56.9444 1176.08 58.8332C1177.81 60.7219 1179.03 63.3348 1179.74 66.6716C1180.44 70.0084 1180.8 73.8489 1180.8 78.1931V111.719C1180.8 116.063 1180.46 119.935 1179.8 123.335C1179.13 126.735 1177.93 129.442 1176.2 131.457C1174.51 133.408 1172.11 134.384 1169 134.384ZM1169 130.135C1171.28 130.135 1173.03 129.41 1174.25 127.962C1175.51 126.514 1176.38 124.5 1176.85 121.918C1177.32 119.274 1177.55 116.221 1177.55 112.758V77.4376C1177.55 73.9749 1177.32 70.9528 1176.85 68.3715C1176.38 65.7902 1175.53 63.8069 1174.31 62.4218C1173.09 60.9738 1171.32 60.2498 1169 60.2498C1166.72 60.2498 1164.96 60.9738 1163.7 62.4218C1162.48 63.8069 1161.63 65.7902 1161.16 68.3715C1160.69 70.9528 1160.45 73.9749 1160.45 77.4376V112.758C1160.45 116.221 1160.69 119.274 1161.16 121.918C1161.63 124.5 1162.48 126.514 1163.7 127.962C1164.96 129.41 1166.72 130.135 1169 130.135Z"
          fill="#C298DC"/>
        <path
          d="M1122.22 146V129.379H1123.93C1124.72 128.56 1125.42 127.742 1126.05 126.924C1126.72 126.042 1127.31 124.751 1127.82 123.052C1128.33 121.352 1128.8 118.959 1129.24 115.874C1129.71 112.726 1130.14 108.508 1130.53 103.219C1130.93 97.8678 1131.32 91.0997 1131.71 82.915L1132.95 56.8499H1149.76V129.379H1153.23V146H1150.64L1150.29 133.345H1125.17L1124.81 146H1122.22ZM1127.53 129.379H1146.51V60.8163H1135.9L1134.78 83.8594C1134.39 92.0441 1133.97 98.7807 1133.54 104.069C1133.15 109.295 1132.73 113.45 1132.3 116.535C1131.87 119.62 1131.4 121.981 1130.89 123.618C1130.42 125.192 1129.9 126.388 1129.35 127.207C1128.8 127.962 1128.19 128.686 1127.53 129.379Z"
          fill="#C298DC"/>
        <path
          d="M1109.78 134.384C1107.38 134.384 1105.44 133.629 1103.94 132.118C1102.49 130.607 1101.43 128.529 1100.76 125.885C1100.09 123.178 1099.76 119.998 1099.76 116.346V114.93H1102.71C1102.71 115.056 1102.71 115.371 1102.71 115.874C1102.71 116.315 1102.71 116.598 1102.71 116.724C1102.74 121.131 1103.31 124.5 1104.42 126.829C1105.52 129.159 1107.3 130.323 1109.78 130.323C1111.27 130.323 1112.61 129.851 1113.79 128.907C1114.97 127.899 1115.89 126.137 1116.56 123.618C1117.27 121.1 1117.62 117.574 1117.62 113.041C1117.62 108.004 1116.99 103.849 1115.74 100.575C1114.48 97.3013 1112.34 95.6014 1109.31 95.4754C1109.27 95.4754 1109.01 95.4754 1108.54 95.4754C1108.07 95.4754 1107.6 95.4754 1107.13 95.4754C1106.66 95.4754 1106.38 95.4754 1106.3 95.4754V91.6979C1106.38 91.6979 1106.66 91.6979 1107.13 91.6979C1107.6 91.6979 1108.07 91.6979 1108.54 91.6979C1109.01 91.6979 1109.25 91.6979 1109.25 91.6979C1112.32 91.572 1114.48 90.1554 1115.74 87.4481C1116.99 84.6779 1117.62 80.4597 1117.62 74.7933C1117.62 70.5121 1117.05 66.9864 1115.91 64.2162C1114.81 61.446 1112.93 60.0609 1110.25 60.0609C1107.74 60.0609 1105.79 61.0682 1104.42 63.0829C1103.08 65.0976 1102.39 68.6233 1102.35 73.6601C1102.35 73.786 1102.35 74.0378 1102.35 74.4156C1102.35 74.7304 1102.35 74.9507 1102.35 75.0767H1099.4V73.8489C1099.4 70.2603 1099.74 67.1438 1100.41 64.4995C1101.11 61.7923 1102.25 59.7146 1103.83 58.2665C1105.4 56.7555 1107.5 56 1110.13 56C1112.57 56 1114.58 56.8499 1116.15 58.5499C1117.72 60.1868 1118.88 62.4218 1119.63 65.255C1120.41 68.0882 1120.81 71.2991 1120.81 74.8878C1120.81 80.6171 1120.14 85.0242 1118.8 88.1092C1117.47 91.1313 1115.83 92.9571 1113.91 93.5867C1114.93 93.9644 1115.97 94.8459 1117.03 96.231C1118.09 97.5531 1118.98 99.5678 1119.69 102.275C1120.43 104.982 1120.81 108.539 1120.81 112.947C1120.81 117.102 1120.43 120.817 1119.69 124.09C1118.98 127.301 1117.82 129.82 1116.21 131.646C1114.6 133.471 1112.45 134.384 1109.78 134.384Z"
          fill="#C298DC"/>
        <path
          d="M1071.74 134.384C1068.64 134.384 1066.22 133.408 1064.49 131.457C1062.8 129.442 1061.62 126.735 1060.95 123.335C1060.29 119.872 1059.95 115.969 1059.95 111.624V78.1931C1059.95 73.8489 1060.31 70.0084 1061.01 66.6716C1061.72 63.3348 1062.92 60.7219 1064.61 58.8332C1066.34 56.9444 1068.72 56 1071.74 56C1074.77 56 1077.13 56.9444 1078.82 58.8332C1080.55 60.7219 1081.77 63.3348 1082.48 66.6716C1083.18 70.0084 1083.54 73.8489 1083.54 78.1931V111.719C1083.54 116.063 1083.2 119.935 1082.53 123.335C1081.87 126.735 1080.67 129.442 1078.94 131.457C1077.25 133.408 1074.85 134.384 1071.74 134.384ZM1071.74 130.135C1074.02 130.135 1075.77 129.41 1076.99 127.962C1078.25 126.514 1079.11 124.5 1079.59 121.918C1080.06 119.274 1080.29 116.221 1080.29 112.758V77.4376C1080.29 73.9749 1080.06 70.9528 1079.59 68.3715C1079.11 65.7902 1078.27 63.8069 1077.05 62.4218C1075.83 60.9738 1074.06 60.2498 1071.74 60.2498C1069.46 60.2498 1067.7 60.9738 1066.44 62.4218C1065.22 63.8069 1064.37 65.7902 1063.9 68.3715C1063.43 70.9528 1063.19 73.9749 1063.19 77.4376V112.758C1063.19 116.221 1063.43 119.274 1063.9 121.918C1064.37 124.5 1065.22 126.514 1066.44 127.962C1067.7 129.41 1069.46 130.135 1071.74 130.135Z"
          fill="#C298DC"/>
        <path d="M1040.37 133.345V56.8499H1056.58V61.0052H1043.55V133.345H1040.37Z" fill="#C298DC"/>
        <path
          d="M1018.34 133.345V56.8499H1034.85V61.0052H1021.58V91.6978H1032.49V95.6642H1021.58V129.284H1035.03V133.345H1018.34Z"
          fill="#C298DC"/>
        <path
          d="M978.177 133.345V56.8499H981.361V129.379H993.036V56.8499H996.279V129.379H1007.48V56.8499H1010.72V133.345H978.177Z"
          fill="#C298DC"/>
        <path
          d="M949.239 133.345L959.263 56.8499H962.978L973.061 133.345H969.818L967.341 112.191H954.959L952.423 133.345H949.239ZM955.43 108.413H966.928L961.15 63.0828L955.43 108.413Z"
          fill="#C298DC"/>
        <path
          d="M924.163 133.345V56.8499H934.01C936.211 56.8499 938.02 57.3535 939.435 58.3609C940.889 59.3053 942.009 60.6589 942.796 62.4217C943.621 64.1846 944.191 66.2308 944.506 68.5603C944.859 70.8268 945.036 73.2822 945.036 75.9265C945.036 77.9412 944.879 80.1133 944.564 82.4428C944.289 84.7093 943.759 86.724 942.972 88.4869C942.186 90.2497 941.007 91.446 939.435 92.0756C941.282 92.957 942.678 94.468 943.621 96.6086C944.604 98.7492 945.252 101.142 945.567 103.786C945.921 106.367 946.098 108.791 946.098 111.058C946.098 113.954 945.96 116.724 945.685 119.368C945.449 122.013 944.958 124.405 944.211 126.546C943.464 128.623 942.383 130.292 940.968 131.551C939.592 132.747 937.744 133.345 935.425 133.345H924.163ZM927.347 129.284H935.012C938.157 129.284 940.26 127.805 941.321 124.846C942.422 121.887 942.972 117.322 942.972 111.152C942.972 108.067 942.737 105.266 942.265 102.747C941.833 100.229 941.066 98.2141 939.965 96.7031C938.904 95.192 937.41 94.4365 935.484 94.4365H927.347V129.284ZM927.347 90.3757H935.425C937.194 90.3757 938.55 89.7146 939.494 88.3924C940.437 87.0703 941.086 85.3389 941.439 83.1983C941.832 80.9947 942.029 78.5708 942.029 75.9265C942.029 73.0933 941.813 70.5435 941.38 68.277C940.948 66.0104 940.103 64.2476 938.845 62.9884C937.626 61.6662 935.759 61.0052 933.243 61.0052H927.347V90.3757Z"
          fill="#C298DC"/>
        <path
          d="M881.19 133.345L890.684 98.4974C888.286 97.0493 886.556 94.4365 885.495 90.659C884.433 86.8185 883.903 82.3798 883.903 77.3431C883.903 73.6915 884.257 70.3231 884.964 67.2381C885.711 64.1531 886.969 61.6662 888.738 59.7775C890.507 57.8257 892.963 56.8499 896.108 56.8499H906.545V133.345H903.243V100.008H895.931C895.578 100.008 895.165 100.008 894.693 100.008C894.261 99.9455 893.887 99.8825 893.573 99.8195L884.787 133.345H881.19ZM896.344 96.042H903.243V60.9107H896.226C893.317 60.9107 891.057 62.1699 889.445 64.6883C887.873 67.2067 887.087 71.4249 887.087 77.3431C887.087 83.5131 887.794 88.1721 889.209 91.32C890.625 94.468 893.003 96.042 896.344 96.042Z"
          fill="#C298DC"/>
        <path
          d="M849.666 133.534V129.473C850.924 129.347 851.966 129.096 852.791 128.718C853.617 128.34 854.285 127.459 854.796 126.074C855.307 124.688 855.739 122.453 856.093 119.368C856.447 116.283 856.781 112.002 857.095 106.525C857.449 101.047 857.842 93.9958 858.275 85.3704L859.572 56.8499H876.671V133.345H873.428V60.8163H862.579L861.4 85.3704C860.967 93.9958 860.574 101.205 860.22 106.997C859.867 112.726 859.454 117.354 858.982 120.879C858.55 124.405 857.96 127.081 857.213 128.907C856.506 130.67 855.543 131.866 854.324 132.495C853.106 133.125 851.553 133.471 849.666 133.534Z"
          fill="#C298DC"/>
        <path
          d="M816.62 146V129.379H818.33C819.116 128.56 819.824 127.742 820.453 126.924C821.121 126.042 821.711 124.751 822.222 123.052C822.733 121.352 823.204 118.959 823.637 115.874C824.108 112.726 824.541 108.508 824.934 103.219C825.327 97.8678 825.72 91.0997 826.113 82.915L827.351 56.8499H844.156V129.379H847.635V146H845.041L844.687 133.345H819.568L819.215 146H816.62ZM821.927 129.379H840.913V60.8163H830.3L829.179 83.8594C828.786 92.0441 828.374 98.7807 827.941 104.069C827.548 109.295 827.135 113.45 826.703 116.535C826.27 119.62 825.799 121.981 825.288 123.618C824.816 125.192 824.305 126.388 823.755 127.207C823.204 127.962 822.595 128.686 821.927 129.379Z"
          fill="#C298DC"/>
        <path
          d="M773.854 133.345L775.27 56.8499H778.454L788.536 125.885L798.796 56.8499H802.098L803.454 133.345H800.683L799.504 69.2213L789.775 133.345H787.416L777.805 69.3158L776.626 133.345H773.854Z"
          fill="#C298DC"/>
        <path
          d="M736.121 133.345V56.8499H739.364V89.1479H744.73C747.678 89.1479 750.096 89.9979 751.982 91.6978C753.869 93.3347 755.265 95.8216 756.169 99.1585C757.073 102.495 757.525 106.682 757.525 111.719C757.525 114.867 757.289 117.763 756.817 120.407C756.385 123.052 755.658 125.35 754.636 127.301C753.653 129.19 752.356 130.67 750.744 131.74C749.132 132.81 747.167 133.345 744.848 133.345H736.121ZM739.364 129.19H744.907C747.226 129.19 749.054 128.529 750.39 127.207C751.766 125.822 752.749 123.838 753.338 121.257C753.928 118.613 754.223 115.402 754.223 111.624C754.223 105.895 753.535 101.425 752.159 98.2141C750.823 94.9402 748.405 93.3033 744.907 93.3033H739.364V129.19ZM763.657 133.345V56.8499H766.841V133.345H763.657Z"
          fill="#C298DC"/>
        <path
          d="M705.343 133.345V56.8499H708.586V91.32H725.331V56.8499H728.515V133.345H725.331V95.2865H708.586V133.345H705.343Z"
          fill="#C298DC"/>
        <path
          d="M669.406 146V129.379H671.116C671.902 128.56 672.61 127.742 673.239 126.924C673.907 126.042 674.497 124.751 675.008 123.052C675.519 121.352 675.991 118.959 676.423 115.874C676.895 112.726 677.327 108.508 677.72 103.219C678.113 97.8678 678.506 91.0997 678.899 82.915L680.138 56.8499H696.942V129.379H700.421V146H697.827L697.473 133.345H672.354L672.001 146H669.406ZM674.713 129.379H693.699V60.8163H683.086L681.966 83.8594C681.572 92.0441 681.16 98.7807 680.727 104.069C680.334 109.295 679.921 113.45 679.489 116.535C679.057 119.62 678.585 121.981 678.074 123.618C677.602 125.192 677.091 126.388 676.541 127.207C675.991 127.962 675.381 128.686 674.713 129.379Z"
          fill="#C298DC"/>
        <path
          d="M651.271 133.345V56.8499H667.78V61.0052H654.514V91.6978H665.422V95.6642H654.514V129.284H667.957V133.345H651.271Z"
          fill="#C298DC"/>
        <path
          d="M623.891 133.345V56.8499H635.094C637.963 56.8499 640.204 57.7313 641.816 59.4941C643.427 61.194 644.548 63.5235 645.176 66.4826C645.845 69.4417 646.179 72.81 646.179 76.5876C646.179 79.9874 645.845 83.2298 645.176 86.3148C644.548 89.3998 643.427 91.9182 641.816 93.8699C640.204 95.8216 637.983 96.7975 635.153 96.7975H627.075V133.345H623.891ZM627.075 92.8311H634.445C636.371 92.8311 637.944 92.2959 639.162 91.2256C640.42 90.1553 641.344 88.4554 641.933 86.1259C642.562 83.7335 642.877 80.554 642.877 76.5876C642.877 72.4952 642.602 69.3158 642.051 67.0493C641.501 64.7198 640.617 63.1143 639.398 62.2329C638.179 61.2885 636.548 60.8163 634.504 60.8163H627.075V92.8311Z"
          fill="#C298DC"/>
        <path
          d="M596.279 133.345V56.8499H606.126C608.328 56.8499 610.136 57.3535 611.551 58.3609C613.005 59.3053 614.126 60.6589 614.912 62.4217C615.737 64.1846 616.307 66.2308 616.622 68.5603C616.975 70.8268 617.152 73.2822 617.152 75.9265C617.152 77.9412 616.995 80.1133 616.681 82.4428C616.406 84.7093 615.875 86.724 615.089 88.4869C614.302 90.2497 613.123 91.446 611.551 92.0756C613.398 92.957 614.794 94.468 615.737 96.6086C616.72 98.7492 617.369 101.142 617.683 103.786C618.037 106.367 618.214 108.791 618.214 111.058C618.214 113.954 618.076 116.724 617.801 119.368C617.565 122.013 617.074 124.405 616.327 126.546C615.58 128.623 614.499 130.292 613.084 131.551C611.708 132.747 609.861 133.345 607.541 133.345H596.279ZM599.463 129.284H607.129C610.273 129.284 612.376 127.805 613.438 124.846C614.538 121.887 615.089 117.322 615.089 111.152C615.089 108.067 614.853 105.266 614.381 102.747C613.949 100.229 613.182 98.2141 612.082 96.7031C611.02 95.192 609.526 94.4365 607.6 94.4365H599.463V129.284ZM599.463 90.3757H607.541C609.31 90.3757 610.666 89.7146 611.61 88.3924C612.553 87.0703 613.202 85.3389 613.556 83.1983C613.949 80.9947 614.145 78.5708 614.145 75.9265C614.145 73.0933 613.929 70.5435 613.497 68.277C613.064 66.0104 612.219 64.2476 610.961 62.9884C609.743 61.6662 607.875 61.0052 605.36 61.0052H599.463V90.3757Z"
          fill="#C298DC"/>
        <path
          d="M560.289 133.345V56.8499H563.473V89.1479H568.839C571.787 89.1479 574.204 89.9979 576.091 91.6978C577.978 93.3347 579.374 95.8216 580.278 99.1585C581.182 102.495 581.634 106.682 581.634 111.719C581.634 114.867 581.398 117.763 580.926 120.407C580.494 123.052 579.767 125.35 578.745 127.301C577.762 129.19 576.465 130.67 574.853 131.74C573.241 132.81 571.276 133.345 568.957 133.345H560.289ZM563.473 129.19H569.075C571.394 129.19 573.222 128.529 574.558 127.207C575.934 125.822 576.917 123.838 577.506 121.257C578.096 118.613 578.391 115.402 578.391 111.624C578.391 105.895 577.703 101.425 576.327 98.2141C574.951 94.9402 572.514 93.3033 569.016 93.3033H563.473V129.19Z"
          fill="#C298DC"/>
        <path d="M543.362 133.345V61.0052H533.81V56.8499H555.862V61.0052H546.546V133.345H543.362Z" fill="#C298DC"/>
        <path
          d="M498.729 133.345V56.8499H501.973V89.1479H507.338C510.286 89.1479 512.704 89.9979 514.591 91.6978C516.478 93.3347 517.873 95.8216 518.777 99.1585C519.681 102.495 520.133 106.682 520.133 111.719C520.133 114.867 519.897 117.763 519.426 120.407C518.993 123.052 518.266 125.35 517.244 127.301C516.261 129.19 514.964 130.67 513.352 131.74C511.741 132.81 509.775 133.345 507.456 133.345H498.729ZM501.973 129.19H507.515C509.834 129.19 511.662 128.529 512.999 127.207C514.375 125.822 515.357 123.838 515.947 121.257C516.537 118.613 516.831 115.402 516.831 111.624C516.831 105.895 516.143 101.425 514.768 98.2141C513.431 94.9402 511.014 93.3033 507.515 93.3033H501.973V129.19ZM526.266 133.345V56.8499H529.45V133.345H526.266Z"
          fill="#C298DC"/>
        <path
          d="M472.212 133.345V56.8499H490.609V60.8163H475.455V89.1479H480.703C483.651 89.1479 486.068 89.9979 487.955 91.6978C489.842 93.3347 491.238 95.8216 492.142 99.1585C493.046 102.495 493.498 106.682 493.498 111.719C493.498 114.867 493.262 117.763 492.79 120.407C492.358 123.052 491.631 125.35 490.609 127.301C489.626 129.19 488.329 130.67 486.717 131.74C485.105 132.81 483.14 133.345 480.821 133.345H472.212ZM475.455 129.19H480.88C483.199 129.19 485.027 128.529 486.363 127.207C487.739 125.822 488.722 123.838 489.311 121.257C489.901 118.613 490.196 115.402 490.196 111.624C490.196 105.895 489.508 101.425 488.132 98.2141C486.796 94.9402 484.378 93.3033 480.88 93.3033H475.455V129.19Z"
          fill="#C298DC"/>
        <path d="M445.354 133.345V61.0052H435.802V56.8499H457.854V61.0052H448.538V133.345H445.354Z" fill="#C298DC"/>
        <path
          d="M417.017 133.345V56.8499H433.526V61.0052H420.26V91.6978H431.168V95.6642H420.26V129.284H433.703V133.345H417.017Z"
          fill="#C298DC"/>
        <path
          d="M378.852 133.345L391.116 93.3977L379.972 56.8499H383.097L393.828 93.1144H394.241V56.8499H397.189V93.1144H397.602L408.333 56.8499H411.458L400.314 93.3977L412.579 133.345H409.395L397.779 94.4365H397.189V133.345H394.241V94.4365H393.592L382.036 133.345H378.852Z"
          fill="#C298DC"/>
        <path
          d="M363.602 134.384C360.497 134.384 358.079 133.408 356.35 131.457C354.659 129.442 353.48 126.735 352.812 123.335C352.144 119.872 351.81 115.969 351.81 111.624V78.1931C351.81 73.8489 352.163 70.0084 352.871 66.6716C353.578 63.3348 354.777 60.7219 356.468 58.8332C358.197 56.9444 360.575 56 363.602 56C366.629 56 368.988 56.9444 370.678 58.8332C372.408 60.7219 373.626 63.3348 374.334 66.6716C375.041 70.0084 375.395 73.8489 375.395 78.1931V111.719C375.395 116.063 375.061 119.935 374.393 123.335C373.724 126.735 372.525 129.442 370.796 131.457C369.106 133.408 366.708 134.384 363.602 134.384ZM363.602 130.135C365.882 130.135 367.631 129.41 368.85 127.962C370.108 126.514 370.973 124.5 371.444 121.918C371.916 119.274 372.152 116.221 372.152 112.758V77.4376C372.152 73.9749 371.916 70.9528 371.444 68.3715C370.973 65.7902 370.128 63.8069 368.909 62.4218C367.69 60.9738 365.922 60.2498 363.602 60.2498C361.322 60.2498 359.553 60.9738 358.296 62.4218C357.077 63.8069 356.232 65.7902 355.76 68.3715C355.288 70.9528 355.053 73.9749 355.053 77.4376V112.758C355.053 116.221 355.288 119.274 355.76 121.918C356.232 124.5 357.077 126.514 358.296 127.962C359.553 129.41 361.322 130.135 363.602 130.135Z"
          fill="#C298DC"/>
        <path
          d="M316.147 133.345L317.563 56.8499H320.747L330.829 125.885L341.089 56.8499H344.391L345.747 133.345H342.976L341.797 69.2213L332.068 133.345H329.709L320.098 69.3158L318.919 133.345H316.147Z"
          fill="#C298DC"/>
        <path
          d="M284.718 133.345V56.8499H301.228V61.0052H287.961V91.6978H298.869V95.6642H287.961V129.284H301.404V133.345H284.718Z"
          fill="#C298DC"/>
        <path
          d="M255.264 133.345V56.8499H258.153V122.013L274.486 56.8499H277.139V133.345H274.25V68.1825L257.976 133.345H255.264Z"
          fill="#C298DC"/>
        <path
          d="M224.484 133.345V56.8499H227.727V91.32H244.473V56.8499H247.657V133.345H244.473V95.2865H227.727V133.345H224.484Z"
          fill="#C298DC"/>
        <path
          d="M202.517 133.345V56.8499H219.026V61.0052H205.76V91.6978H216.668V95.6642H205.76V129.284H219.203V133.345H202.517Z"
          fill="#C298DC"/>
        <path
          d="M191.674 133.345V97.8363C189.198 98.9696 186.859 99.5362 184.657 99.5362C182.181 99.5362 180.039 99.0011 178.23 97.9308C176.462 96.7975 175.086 95.0346 174.103 92.6422C173.12 90.1868 172.629 86.9759 172.629 83.0094V56.8499H175.872V83.0094C175.872 86.4722 176.285 89.1165 177.11 90.9423C177.936 92.7681 179.036 94.0273 180.412 94.7198C181.827 95.4124 183.4 95.7587 185.129 95.7587C186.387 95.7587 187.566 95.6013 188.667 95.2865C189.807 94.9087 190.809 94.468 191.674 93.9643V56.8499H194.917V133.345H191.674Z"
          fill="#C298DC"/>
        <path
          d="M151.956 133.345V56.8499H168.466V61.0052H155.199V91.6978H166.107V95.6642H155.199V129.284H168.643V133.345H151.956Z"
          fill="#C298DC"/>
        <path
          d="M117.426 133.534V129.473C118.684 129.347 119.725 129.096 120.551 128.718C121.376 128.34 122.045 127.459 122.556 126.074C123.067 124.688 123.499 122.453 123.853 119.368C124.207 116.283 124.541 112.002 124.855 106.525C125.209 101.047 125.602 93.9958 126.034 85.3704L127.332 56.8499H144.431V133.345H141.188V60.8163H130.339L129.16 85.3704C128.727 93.9958 128.334 101.205 127.98 106.997C127.626 112.726 127.214 117.354 126.742 120.879C126.31 124.405 125.72 127.081 124.973 128.907C124.266 130.67 123.302 131.866 122.084 132.495C120.865 133.125 119.313 133.471 117.426 133.534Z"
          fill="#C298DC"/>
        <path
          d="M101.94 134.384C98.8348 134.384 96.4173 133.408 94.6877 131.457C92.9974 129.442 91.8181 126.735 91.1498 123.335C90.4816 119.872 90.1475 115.969 90.1475 111.624V78.1931C90.1475 73.8489 90.5012 70.0084 91.2088 66.6716C91.9164 63.3348 93.1153 60.7219 94.8056 58.8332C96.5352 56.9444 98.9134 56 101.94 56C104.967 56 107.326 56.9444 109.016 58.8332C110.745 60.7219 111.964 63.3348 112.672 66.6716C113.379 70.0084 113.733 73.8489 113.733 78.1931V111.719C113.733 116.063 113.399 119.935 112.731 123.335C112.062 126.735 110.863 129.442 109.134 131.457C107.443 133.408 105.046 134.384 101.94 134.384ZM101.94 130.135C104.22 130.135 105.969 129.41 107.188 127.962C108.446 126.514 109.311 124.5 109.782 121.918C110.254 119.274 110.49 116.221 110.49 112.758V77.4376C110.49 73.9749 110.254 70.9528 109.782 68.3715C109.311 65.7902 108.465 63.8069 107.247 62.4218C106.028 60.9738 104.259 60.2498 101.94 60.2498C99.6603 60.2498 97.8914 60.9738 96.6335 62.4218C95.4149 63.8069 94.5697 65.7902 94.098 68.3715C93.6263 70.9528 93.3905 73.9749 93.3905 77.4376V112.758C93.3905 116.221 93.6263 119.274 94.098 121.918C94.5697 124.5 95.4149 126.514 96.6335 127.962C97.8914 129.41 99.6603 130.135 101.94 130.135Z"
          fill="#C298DC"/>
        <path
          d="M54.4844 133.345L55.8995 56.8499H59.0835L69.1663 125.885L79.426 56.8499H82.728L84.0841 133.345H81.3128L80.1336 69.2213L70.4046 133.345H68.046L58.4349 69.3158L57.2557 133.345H54.4844Z"
          fill="#C298DC"/>
        <path
          d="M26.1357 133.345L36.1596 56.8499H39.8743L49.9571 133.345H46.7141L44.2376 112.191H31.8552L29.3198 133.345H26.1357ZM32.3269 108.413H43.8248L38.0464 63.0828L32.3269 108.413Z"
          fill="#C298DC"/>
        <path
          d="M11.6158 134.384C8.47111 134.384 6.0536 133.345 4.36331 131.268C2.71233 129.19 1.57236 126.42 0.943418 122.957C0.314473 119.431 0 115.559 0 111.341V78.8542C0 74.4471 0.334127 70.5436 1.00238 67.1438C1.67064 63.681 2.84991 60.9738 4.5402 59.022C6.23049 57.0073 8.58904 56 11.6158 56C14.4068 56 16.5884 56.8814 18.1608 58.6443C19.7332 60.4072 20.8535 62.9255 21.5217 66.1994C22.19 69.4103 22.5241 73.1879 22.5241 77.5321V81.4041H19.458V77.5321C19.458 73.8804 19.2418 70.7954 18.8094 68.2771C18.377 65.6957 17.5908 63.7125 16.4509 62.3274C15.3109 60.9423 13.6992 60.2498 11.6158 60.2498C9.33591 60.2498 7.58665 61.0053 6.36807 62.5163C5.14949 64.0273 4.324 66.1364 3.8916 68.8437C3.4592 71.5509 3.243 74.6989 3.243 78.2876V112.097C3.243 115.874 3.47885 119.117 3.95056 121.824C4.42227 124.468 5.26742 126.514 6.486 127.962C7.70458 129.41 9.41453 130.135 11.6158 130.135C13.7385 130.135 15.3699 129.41 16.5098 127.962C17.6498 126.514 18.4163 124.5 18.8094 121.918C19.2418 119.274 19.458 116.221 19.458 112.758V108.13H22.5241V111.908C22.5241 116.252 22.2096 120.124 21.5807 123.524C20.9517 126.924 19.8511 129.599 18.2787 131.551C16.7064 133.44 14.4854 134.384 11.6158 134.384Z"
          fill="#C298DC"/>
      </g>
    </svg>

    <svg class="tablet" viewBox="0 0 728 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path d="M726.938 17.1877V15.0051H728V17.1877H726.938Z" fill="#C298DC"/>
        <path
          d="M721.681 17.4186C720.745 17.4186 720.018 17.2017 719.502 16.768C718.986 16.3203 718.62 15.7117 718.404 14.9422C718.2 14.1727 718.098 13.3053 718.098 12.3399V9.08705H716.549V17.1877H715.613V0.188876H716.549V8.14267H718.098V4.95276C718.098 3.9734 718.206 3.11296 718.422 2.37145C718.638 1.62993 719.004 1.04931 719.52 0.629588C720.048 0.209863 720.769 0 721.681 0C722.593 0 723.307 0.216858 723.823 0.650574C724.339 1.0703 724.706 1.65792 724.922 2.41342C725.138 3.15494 725.246 4.00838 725.246 4.97374V12.3819C725.246 13.3473 725.144 14.2077 724.94 14.9632C724.736 15.7187 724.375 16.3203 723.859 16.768C723.343 17.2017 722.617 17.4186 721.681 17.4186ZM721.699 16.4742C722.395 16.4742 722.929 16.3133 723.301 15.9915C723.685 15.6697 723.949 15.222 724.093 14.6484C724.249 14.0608 724.327 13.3822 724.327 12.6127V4.76388C724.327 3.99439 724.249 3.32283 724.093 2.7492C723.937 2.17558 723.667 1.73486 723.283 1.42707C722.911 1.10528 722.377 0.944382 721.681 0.944382C720.985 0.944382 720.444 1.10528 720.06 1.42707C719.676 1.73486 719.406 2.17558 719.25 2.7492C719.094 3.32283 719.016 3.99439 719.016 4.76388V12.6127C719.016 13.3822 719.088 14.0608 719.232 14.6484C719.388 15.222 719.658 15.6697 720.042 15.9915C720.438 16.3133 720.991 16.4742 721.699 16.4742Z"
          fill="#C298DC"/>
        <path
          d="M706.782 17.1878V0.188965H707.664V14.6695L712.652 0.188965H713.462V17.1878H712.58V2.70732L707.61 17.1878H706.782Z"
          fill="#C298DC"/>
        <path
          d="M697.563 17.1878V0.188965H698.554V7.84895H703.667V0.188965H704.639V17.1878H703.667V8.73037H698.554V17.1878H697.563Z"
          fill="#C298DC"/>
        <path
          d="M691.035 17.1878V0.188965H696.076V1.11236H692.025V7.9329H695.356V8.81432H692.025V16.2854H696.13V17.1878H691.035Z"
          fill="#C298DC"/>
        <path
          d="M681.817 17.1878V0.188965H682.808V7.84895H687.921V0.188965H688.893V17.1878H687.921V8.73037H682.808V17.1878H681.817Z"
          fill="#C298DC"/>
        <path
          d="M675.289 17.1878V0.188965H680.33V1.11236H676.279V7.9329H679.61V8.81432H676.279V16.2854H680.384V17.1878H675.289Z"
          fill="#C298DC"/>
        <path
          d="M664.291 17.1878L664.723 0.188965H665.695L668.774 15.5299L671.907 0.188965H672.915L673.329 17.1878H672.483L672.123 2.93817L669.152 17.1878H668.432L665.497 2.95915L665.137 17.1878H664.291Z"
          fill="#C298DC"/>
        <path
          d="M655.64 17.1878V0.188965H656.522V14.6695L661.509 0.188965H662.319V17.1878H661.437V2.70732L656.468 17.1878H655.64Z"
          fill="#C298DC"/>
        <path
          d="M647.459 17.1878V0.188965H650.88C651.756 0.188965 652.44 0.384836 652.932 0.77658C653.424 1.15433 653.767 1.67199 653.959 2.32956C654.163 2.98713 654.265 3.73564 654.265 4.57509C654.265 5.3306 654.163 6.05113 653.959 6.73668C653.767 7.42223 653.424 7.98186 652.932 8.41558C652.44 8.8493 651.762 9.06615 650.898 9.06615H648.431V17.1878H647.459ZM648.431 8.18473H650.682C651.27 8.18473 651.75 8.06581 652.122 7.82796C652.506 7.59012 652.788 7.21237 652.968 6.69471C653.16 6.16305 653.256 5.45652 653.256 4.57509C653.256 3.66569 653.172 2.95915 653.004 2.45548C652.836 1.93782 652.566 1.58105 652.194 1.38518C651.822 1.17532 651.324 1.07039 650.7 1.07039H648.431V8.18473Z"
          fill="#C298DC"/>
        <path d="M638.188 17.1878V0.188965H645.3V17.1878H644.328V1.07039H639.161V17.1878H638.188Z" fill="#C298DC"/>
        <path
          d="M627.465 19.1396L627.483 18.2371C628.071 18.2511 628.533 18.2092 628.869 18.1112C629.218 18.0273 629.476 17.8594 629.644 17.6076C629.812 17.3697 629.932 17.0339 630.004 16.6002C630.088 16.1525 630.16 15.5859 630.22 14.9003L626.961 0.188965H627.933L629.59 8.03783L630.706 13.5572L631.678 7.99585L633.137 0.188965H634.127L631.228 15.0053C631.108 15.6628 630.994 16.2365 630.886 16.7261C630.778 17.2018 630.652 17.5936 630.508 17.9014C630.364 18.2232 630.172 18.475 629.932 18.6569C629.692 18.8527 629.374 18.9857 628.978 19.0556C628.581 19.1256 628.077 19.1535 627.465 19.1396Z"
          fill="#C298DC"/>
        <path
          d="M616.701 17.1878L617.133 0.188965H618.106L621.184 15.5299L624.317 0.188965H625.325L625.739 17.1878H624.893L624.533 2.93817L621.562 17.1878H620.842L617.908 2.95915L617.547 17.1878H616.701Z"
          fill="#C298DC"/>
        <path
          d="M611.432 17.4186C610.484 17.4186 609.745 17.2017 609.217 16.768C608.701 16.3203 608.341 15.7187 608.137 14.9632C607.933 14.1937 607.831 13.3263 607.831 12.3609V4.93177C607.831 3.9664 607.939 3.11296 608.155 2.37145C608.371 1.62993 608.737 1.04931 609.253 0.629588C609.782 0.209863 610.508 0 611.432 0C612.356 0 613.076 0.209863 613.593 0.629588C614.121 1.04931 614.493 1.62993 614.709 2.37145C614.925 3.11296 615.033 3.9664 615.033 4.93177V12.3819C615.033 13.3473 614.931 14.2077 614.727 14.9632C614.523 15.7187 614.157 16.3203 613.629 16.768C613.112 17.2017 612.38 17.4186 611.432 17.4186ZM611.432 16.4742C612.128 16.4742 612.662 16.3133 613.034 15.9915C613.418 15.6697 613.682 15.222 613.827 14.6484C613.971 14.0608 614.043 13.3822 614.043 12.6127V4.76388C614.043 3.99439 613.971 3.32283 613.827 2.7492C613.682 2.17558 613.424 1.73486 613.052 1.42707C612.68 1.10528 612.14 0.944382 611.432 0.944382C610.736 0.944382 610.196 1.10528 609.812 1.42707C609.439 1.73486 609.181 2.17558 609.037 2.7492C608.893 3.32283 608.821 3.99439 608.821 4.76388V12.6127C608.821 13.3822 608.893 14.0608 609.037 14.6484C609.181 15.222 609.439 15.6697 609.812 15.9915C610.196 16.3133 610.736 16.4742 611.432 16.4742Z"
          fill="#C298DC"/>
        <path
          d="M600.221 17.1878V0.188965H601.211V8.16374H602.417L605.622 0.188965H606.63L603.281 8.43657L607.17 17.1878H606.18L602.417 8.8353H601.211V17.1878H600.221Z"
          fill="#C298DC"/>
        <path
          d="M595.211 17.4186C594.251 17.4186 593.513 17.1877 592.996 16.7261C592.492 16.2644 592.144 15.6488 591.952 14.8793C591.76 14.0958 591.664 13.2353 591.664 12.2979V5.07868C591.664 4.09932 591.766 3.23188 591.97 2.47638C592.174 1.70688 592.534 1.10528 593.05 0.67156C593.567 0.223853 594.287 0 595.211 0C596.063 0 596.729 0.195872 597.209 0.587617C597.69 0.97936 598.032 1.53899 598.236 2.26652C598.44 2.98005 598.542 3.8195 598.542 4.78487V5.64531H597.606V4.78487C597.606 3.9734 597.54 3.28785 597.407 2.72821C597.275 2.15459 597.035 1.71388 596.687 1.40608C596.339 1.09828 595.847 0.944382 595.211 0.944382C594.515 0.944382 593.981 1.11227 593.609 1.44805C593.236 1.78383 592.984 2.25253 592.852 2.85413C592.72 3.45574 592.654 4.15528 592.654 4.95276V12.4658C592.654 13.3053 592.726 14.0258 592.87 14.6274C593.014 15.215 593.272 15.6697 593.645 15.9915C594.017 16.3133 594.539 16.4742 595.211 16.4742C595.859 16.4742 596.357 16.3133 596.705 15.9915C597.053 15.6697 597.287 15.222 597.407 14.6484C597.54 14.0608 597.606 13.3822 597.606 12.6127V11.5844H598.542V12.4239C598.542 13.3892 598.446 14.2497 598.254 15.0052C598.062 15.7607 597.726 16.3553 597.245 16.789C596.765 17.2087 596.087 17.4186 595.211 17.4186Z"
          fill="#C298DC"/>
        <path
          d="M582.732 17.1878V0.188965H583.723V7.84895H588.836V0.188965H589.808V17.1878H588.836V8.73037H583.723V17.1878H582.732Z"
          fill="#C298DC"/>
        <path
          d="M573.92 17.1878V0.188965H574.802V14.6695L579.789 0.188965H580.6V17.1878H579.717V2.70732L574.748 17.1878H573.92Z"
          fill="#C298DC"/>
        <path
          d="M571.855 20L571.746 17.1878H564.473V0.188965H565.463V16.3064H570.648V0.188965H571.638V16.3064H572.647V20H571.855Z"
          fill="#C298DC"/>
        <path
          d="M555.659 17.1878V0.188965H556.541V14.6695L561.529 0.188965H562.339V17.1878H561.457V2.70732L556.487 17.1878H555.659Z"
          fill="#C298DC"/>
        <path
          d="M544.866 20V16.3064H545.388C545.628 16.1245 545.844 15.9427 546.036 15.7608C546.241 15.5649 546.421 15.2781 546.577 14.9003C546.733 14.5226 546.877 13.9909 547.009 13.3054C547.153 12.6058 547.285 11.6685 547.405 10.4932C547.525 9.304 547.645 7.79998 547.765 5.98117L548.143 0.188965H553.274V16.3064H554.337V20H553.544L553.436 17.1878H545.766L545.658 20H544.866ZM546.487 16.3064H552.284V1.07039H549.043L548.701 6.19104C548.581 8.00985 548.455 9.50686 548.323 10.6821C548.203 11.8433 548.077 12.7667 547.945 13.4523C547.813 14.1378 547.669 14.6625 547.513 15.0263C547.369 15.376 547.213 15.6419 547.045 15.8237C546.877 15.9916 546.691 16.1525 546.487 16.3064Z"
          fill="#C298DC"/>
        <path
          d="M539.509 17.1878V0.188965H544.55V1.11236H540.499V7.9329H543.83V8.81432H540.499V16.2854H544.604V17.1878H539.509Z"
          fill="#C298DC"/>
        <path
          d="M528.51 17.1878L528.942 0.188965H529.914L532.993 15.5299L536.126 0.188965H537.134L537.548 17.1878H536.702L536.342 2.93817L533.371 17.1878H532.651L529.716 2.95915L529.356 17.1878H528.51Z"
          fill="#C298DC"/>
        <path
          d="M520.371 17.4186C519.423 17.4186 518.685 17.2017 518.157 16.768C517.641 16.3203 517.281 15.7187 517.077 14.9632C516.873 14.1937 516.771 13.3263 516.771 12.3609V4.93177C516.771 3.9664 516.879 3.11296 517.095 2.37145C517.311 1.62993 517.677 1.04931 518.193 0.629588C518.721 0.209863 519.447 0 520.371 0C521.296 0 522.016 0.209863 522.532 0.629588C523.06 1.04931 523.432 1.62993 523.648 2.37145C523.864 3.11296 523.972 3.9664 523.972 4.93177V12.3819C523.972 13.3473 523.87 14.2077 523.666 14.9632C523.462 15.7187 523.096 16.3203 522.568 16.768C522.052 17.2017 521.32 17.4186 520.371 17.4186ZM520.371 16.4742C521.068 16.4742 521.602 16.3133 521.974 15.9915C522.358 15.6697 522.622 15.222 522.766 14.6484C522.91 14.0608 522.982 13.3822 522.982 12.6127V4.76388C522.982 3.99439 522.91 3.32283 522.766 2.7492C522.622 2.17558 522.364 1.73486 521.992 1.42707C521.62 1.10528 521.08 0.944382 520.371 0.944382C519.675 0.944382 519.135 1.10528 518.751 1.42707C518.379 1.73486 518.121 2.17558 517.977 2.7492C517.833 3.32283 517.761 3.99439 517.761 4.76388V12.6127C517.761 13.3822 517.833 14.0608 517.977 14.6484C518.121 15.222 518.379 15.6697 518.751 15.9915C519.135 16.3133 519.675 16.4742 520.371 16.4742Z"
          fill="#C298DC"/>
        <path d="M507.805 17.1878V0.188965H514.916V17.1878H513.944V1.07039H508.777V17.1878H507.805Z" fill="#C298DC"/>
        <path
          d="M499.515 17.4186C498.579 17.4186 497.852 17.2017 497.336 16.768C496.82 16.3203 496.454 15.7117 496.238 14.9422C496.034 14.1727 495.932 13.3053 495.932 12.3399V9.08705H494.384V17.1877H493.447V0.188876H494.384V8.14267H495.932V4.95276C495.932 3.9734 496.04 3.11296 496.256 2.37145C496.472 1.62993 496.838 1.04931 497.354 0.629588C497.882 0.209863 498.603 0 499.515 0C500.427 0 501.141 0.216858 501.657 0.650574C502.173 1.0703 502.54 1.65792 502.756 2.41342C502.972 3.15494 503.08 4.00838 503.08 4.97374V12.3819C503.08 13.3473 502.978 14.2077 502.774 14.9632C502.57 15.7187 502.209 16.3203 501.693 16.768C501.177 17.2017 500.451 17.4186 499.515 17.4186ZM499.533 16.4742C500.229 16.4742 500.763 16.3133 501.135 15.9915C501.519 15.6697 501.783 15.222 501.927 14.6484C502.083 14.0608 502.161 13.3822 502.161 12.6127V4.76388C502.161 3.99439 502.083 3.32283 501.927 2.7492C501.771 2.17558 501.501 1.73486 501.117 1.42707C500.745 1.10528 500.211 0.944382 499.515 0.944382C498.819 0.944382 498.278 1.10528 497.894 1.42707C497.51 1.73486 497.24 2.17558 497.084 2.7492C496.928 3.32283 496.85 3.99439 496.85 4.76388V12.6127C496.85 13.3822 496.922 14.0608 497.066 14.6484C497.222 15.222 497.492 15.6697 497.876 15.9915C498.272 16.3133 498.825 16.4742 499.533 16.4742Z"
          fill="#C298DC"/>
        <path
          d="M484.616 17.1878V0.188965H485.498V14.6695L490.486 0.188965H491.296V17.1878H490.414V2.70732L485.444 17.1878H484.616Z"
          fill="#C298DC"/>
        <path
          d="M482.552 20L482.444 17.1878H475.17V0.188965H476.16V16.3064H481.345V0.188965H482.336V16.3064H483.344V20H482.552Z"
          fill="#C298DC"/>
        <path
          d="M467.271 17.1878V0.188965H468.261V8.16374H469.467L472.672 0.188965H473.68L470.331 8.43657L474.22 17.1878H473.23L469.467 8.8353H468.261V17.1878H467.271Z"
          fill="#C298DC"/>
        <path
          d="M459.435 19.1396L459.453 18.2371C460.041 18.2511 460.503 18.2092 460.839 18.1112C461.187 18.0273 461.445 17.8594 461.613 17.6076C461.781 17.3697 461.901 17.0339 461.973 16.6002C462.057 16.1525 462.129 15.5859 462.189 14.9003L458.931 0.188965H459.903L461.559 8.03783L462.676 13.5572L463.648 7.99585L465.106 0.188965H466.096L463.198 15.0053C463.078 15.6628 462.964 16.2365 462.856 16.7261C462.748 17.2018 462.622 17.5936 462.478 17.9014C462.334 18.2232 462.141 18.475 461.901 18.6569C461.661 18.8527 461.343 18.9857 460.947 19.0556C460.551 19.1256 460.047 19.1535 459.435 19.1396Z"
          fill="#C298DC"/>
        <path
          d="M451.489 17.1878V0.188965H454.91C455.786 0.188965 456.471 0.384836 456.963 0.77658C457.455 1.15433 457.797 1.67199 457.989 2.32956C458.193 2.98713 458.295 3.73564 458.295 4.57509C458.295 5.3306 458.193 6.05113 457.989 6.73668C457.797 7.42223 457.455 7.98186 456.963 8.41558C456.471 8.8493 455.792 9.06615 454.928 9.06615H452.462V17.1878H451.489ZM452.462 8.18473H454.712C455.3 8.18473 455.78 8.06581 456.152 7.82796C456.537 7.59012 456.819 7.21237 456.999 6.69471C457.191 6.16305 457.287 5.45652 457.287 4.57509C457.287 3.66569 457.203 2.95915 457.035 2.45548C456.867 1.93782 456.597 1.58105 456.224 1.38518C455.852 1.17532 455.354 1.07039 454.73 1.07039H452.462V8.18473Z"
          fill="#C298DC"/>
        <path d="M446.5 17.1878V1.11236H443.583V0.188965H450.317V1.11236H447.472V17.1878H446.5Z" fill="#C298DC"/>
        <path
          d="M439.546 17.4186C438.586 17.4186 437.847 17.1877 437.331 16.7261C436.827 16.2644 436.479 15.6488 436.287 14.8793C436.095 14.0958 435.999 13.2353 435.999 12.2979V5.07868C435.999 4.09932 436.101 3.23188 436.305 2.47638C436.509 1.70688 436.869 1.10528 437.385 0.67156C437.902 0.223853 438.622 0 439.546 0C440.398 0 441.064 0.195872 441.544 0.587617C442.025 0.97936 442.367 1.53899 442.571 2.26652C442.775 2.98005 442.877 3.8195 442.877 4.78487V5.64531H441.941V4.78487C441.941 3.9734 441.875 3.28785 441.742 2.72821C441.61 2.15459 441.37 1.71388 441.022 1.40608C440.674 1.09828 440.182 0.944382 439.546 0.944382C438.85 0.944382 438.316 1.11227 437.944 1.44805C437.571 1.78383 437.319 2.25253 437.187 2.85413C437.055 3.45574 436.989 4.15528 436.989 4.95276V12.4658C436.989 13.3053 437.061 14.0258 437.205 14.6274C437.349 15.215 437.607 15.6697 437.98 15.9915C438.352 16.3133 438.874 16.4742 439.546 16.4742C440.194 16.4742 440.692 16.3133 441.04 15.9915C441.388 15.6697 441.622 15.222 441.742 14.6484C441.875 14.0608 441.941 13.3822 441.941 12.6127V11.5844H442.877V12.4239C442.877 13.3892 442.781 14.2497 442.589 15.0052C442.397 15.7607 442.061 16.3553 441.58 16.789C441.1 17.2087 440.422 17.4186 439.546 17.4186Z"
          fill="#C298DC"/>
        <path
          d="M427.069 17.1878V0.188965H428.06V7.84895H433.173V0.188965H434.145V17.1878H433.173V8.73037H428.06V17.1878H427.069Z"
          fill="#C298DC"/>
        <path
          d="M418.255 17.1878V0.188965H419.137V14.6695L424.124 0.188965H424.935V17.1878H424.052V2.70732L419.083 17.1878H418.255Z"
          fill="#C298DC"/>
        <path
          d="M407.644 17.1878V0.188965H408.616V7.36627H410.254C411.154 7.36627 411.893 7.55514 412.469 7.9329C413.045 8.29666 413.471 8.8493 413.747 9.59081C414.023 10.3323 414.161 11.2627 414.161 12.382C414.161 13.0815 414.089 13.7251 413.945 14.3127C413.813 14.9003 413.591 15.411 413.279 15.8447C412.979 16.2644 412.583 16.5932 412.091 16.8311C411.599 17.0689 410.998 17.1878 410.29 17.1878H407.644ZM408.616 16.2644H410.326C411.034 16.2644 411.593 16.1175 412.001 15.8237C412.421 15.5159 412.721 15.0752 412.901 14.5016C413.081 13.914 413.171 13.2004 413.171 12.361C413.171 11.0878 412.961 10.0945 412.541 9.38095C412.121 8.65342 411.376 8.28966 410.308 8.28966H408.616V16.2644Z"
          fill="#C298DC"/>
        <path d="M402.655 17.1878V1.11236H399.738V0.188965H406.472V1.11236H403.627V17.1878H402.655Z" fill="#C298DC"/>
        <path
          d="M392.723 17.1878L395.783 0.188965H396.918L399.996 17.1878H399.006L398.25 12.4869H394.469L393.695 17.1878H392.723ZM394.613 11.6475H398.124L396.36 1.57406L394.613 11.6475Z"
          fill="#C298DC"/>
        <path d="M388.509 17.1878V1.11236H385.592V0.188965H392.326V1.11236H389.481V17.1878H388.509Z" fill="#C298DC"/>
        <path
          d="M377.751 17.1878V0.188965H378.633V14.6695L383.62 0.188965H384.431V17.1878H383.548V2.70732L378.579 17.1878H377.751Z"
          fill="#C298DC"/>
        <path
          d="M374.62 17.1878V9.297C373.864 9.54884 373.15 9.67476 372.478 9.67476C371.721 9.67476 371.067 9.55583 370.515 9.31799C369.975 9.06615 369.555 8.67441 369.255 8.14276C368.955 7.59712 368.805 6.88358 368.805 6.00216V0.188965H369.795V6.00216C369.795 6.77166 369.921 7.35927 370.173 7.76501C370.425 8.17074 370.761 8.45056 371.181 8.60446C371.613 8.75836 372.094 8.8353 372.622 8.8353C373.006 8.8353 373.366 8.80033 373.702 8.73037C374.05 8.64643 374.356 8.54849 374.62 8.43657V0.188965H375.61V17.1878H374.62Z"
          fill="#C298DC"/>
        <path
          d="M363.771 17.4186C362.823 17.4186 362.084 17.2017 361.556 16.768C361.04 16.3203 360.68 15.7187 360.476 14.9632C360.272 14.1937 360.17 13.3263 360.17 12.3609V4.93177C360.17 3.9664 360.278 3.11296 360.494 2.37145C360.71 1.62993 361.076 1.04931 361.592 0.629588C362.12 0.209863 362.847 0 363.771 0C364.695 0 365.415 0.209863 365.931 0.629588C366.46 1.04931 366.832 1.62993 367.048 2.37145C367.264 3.11296 367.372 3.9664 367.372 4.93177V12.3819C367.372 13.3473 367.27 14.2077 367.066 14.9632C366.862 15.7187 366.496 16.3203 365.967 16.768C365.451 17.2017 364.719 17.4186 363.771 17.4186ZM363.771 16.4742C364.467 16.4742 365.001 16.3133 365.373 15.9915C365.757 15.6697 366.021 15.222 366.165 14.6484C366.309 14.0608 366.382 13.3822 366.382 12.6127V4.76388C366.382 3.99439 366.309 3.32283 366.165 2.7492C366.021 2.17558 365.763 1.73486 365.391 1.42707C365.019 1.10528 364.479 0.944382 363.771 0.944382C363.075 0.944382 362.535 1.10528 362.15 1.42707C361.778 1.73486 361.52 2.17558 361.376 2.7492C361.232 3.32283 361.16 3.99439 361.16 4.76388V12.6127C361.16 13.3822 361.232 14.0608 361.376 14.6484C361.52 15.222 361.778 15.6697 362.15 15.9915C362.535 16.3133 363.075 16.4742 363.771 16.4742Z"
          fill="#C298DC"/>
        <path
          d="M352.277 17.1878V0.188965H355.698C356.574 0.188965 357.259 0.384836 357.751 0.77658C358.243 1.15433 358.585 1.67199 358.777 2.32956C358.981 2.98713 359.083 3.73564 359.083 4.57509C359.083 5.3306 358.981 6.05113 358.777 6.73668C358.585 7.42223 358.243 7.98186 357.751 8.41558C357.259 8.8493 356.58 9.06615 355.716 9.06615H353.25V17.1878H352.277ZM353.25 8.18473H355.5C356.088 8.18473 356.568 8.06581 356.941 7.82796C357.325 7.59012 357.607 7.21237 357.787 6.69471C357.979 6.16305 358.075 5.45652 358.075 4.57509C358.075 3.66569 357.991 2.95915 357.823 2.45548C357.655 1.93782 357.385 1.58105 357.013 1.38518C356.64 1.17532 356.142 1.07039 355.518 1.07039H353.25V8.18473Z"
          fill="#C298DC"/>
        <path d="M343.006 17.1878V0.188965H350.118V17.1878H349.145V1.07039H343.978V17.1878H343.006Z" fill="#C298DC"/>
        <path
          d="M334.687 17.4186C333.739 17.4186 333 17.2017 332.472 16.768C331.956 16.3203 331.596 15.7187 331.392 14.9632C331.188 14.1937 331.086 13.3263 331.086 12.3609V4.93177C331.086 3.9664 331.194 3.11296 331.41 2.37145C331.626 1.62993 331.992 1.04931 332.508 0.629588C333.036 0.209863 333.763 0 334.687 0C335.611 0 336.331 0.209863 336.847 0.629588C337.376 1.04931 337.748 1.62993 337.964 2.37145C338.18 3.11296 338.288 3.9664 338.288 4.93177V12.3819C338.288 13.3473 338.186 14.2077 337.982 14.9632C337.778 15.7187 337.412 16.3203 336.883 16.768C336.367 17.2017 335.635 17.4186 334.687 17.4186ZM334.687 16.4742C335.383 16.4742 335.917 16.3133 336.289 15.9915C336.673 15.6697 336.937 15.222 337.081 14.6484C337.225 14.0608 337.298 13.3822 337.298 12.6127V4.76388C337.298 3.99439 337.225 3.32283 337.081 2.7492C336.937 2.17558 336.679 1.73486 336.307 1.42707C335.935 1.10528 335.395 0.944382 334.687 0.944382C333.991 0.944382 333.451 1.10528 333.066 1.42707C332.694 1.73486 332.436 2.17558 332.292 2.7492C332.148 3.32283 332.076 3.99439 332.076 4.76388V12.6127C332.076 13.3822 332.148 14.0608 332.292 14.6484C332.436 15.222 332.694 15.6697 333.066 15.9915C333.451 16.3133 333.991 16.4742 334.687 16.4742Z"
          fill="#C298DC"/>
        <path
          d="M322.155 17.1878V0.188965H323.146V7.84895H328.259V0.188965H329.231V17.1878H328.259V8.73037H323.146V17.1878H322.155Z"
          fill="#C298DC"/>
        <path
          d="M314.414 17.1878V0.188965H315.386V7.36627H317.025C317.925 7.36627 318.663 7.55514 319.239 7.9329C319.815 8.29666 320.242 8.8493 320.518 9.59081C320.794 10.3323 320.932 11.2627 320.932 12.382C320.932 13.0815 320.86 13.7251 320.716 14.3127C320.584 14.9003 320.362 15.411 320.05 15.8447C319.749 16.2644 319.353 16.5932 318.861 16.8311C318.369 17.0689 317.769 17.1878 317.061 17.1878H314.414ZM315.386 16.2644H317.097C317.805 16.2644 318.363 16.1175 318.771 15.8237C319.191 15.5159 319.491 15.0752 319.671 14.5016C319.851 13.914 319.941 13.2004 319.941 12.361C319.941 11.0878 319.731 10.0945 319.311 9.38095C318.891 8.65342 318.147 8.28966 317.079 8.28966H315.386V16.2644Z"
          fill="#C298DC"/>
        <path
          d="M304.032 17.2298V16.3274C304.416 16.2994 304.734 16.2435 304.986 16.1595C305.239 16.0756 305.443 15.8797 305.599 15.5719C305.755 15.2641 305.887 14.7674 305.995 14.0819C306.103 13.3963 306.205 12.4449 306.301 11.2277C306.409 10.0105 306.529 8.44356 306.661 6.52682L307.057 0.188965H312.278V17.1878H311.288V1.07039H307.975L307.615 6.52682C307.483 8.44356 307.363 10.0455 307.255 11.3327C307.147 12.6058 307.021 13.6342 306.877 14.4177C306.745 15.2011 306.565 15.7957 306.337 16.2015C306.121 16.5932 305.827 16.8591 305.455 16.999C305.083 17.1389 304.608 17.2158 304.032 17.2298Z"
          fill="#C298DC"/>
        <path
          d="M298.387 17.1878V0.188965H303.428V1.11236H299.377V7.9329H302.708V8.81432H299.377V16.2854H303.482V17.1878H298.387Z"
          fill="#C298DC"/>
        <path d="M293.416 17.1878V1.11236H290.499V0.188965H297.233V1.11236H294.388V17.1878H293.416Z" fill="#C298DC"/>
        <path
          d="M283.483 17.1878L286.544 0.188965H287.678L290.757 17.1878H289.767L289.011 12.4869H285.23L284.456 17.1878H283.483ZM285.374 11.6475H288.885L287.12 1.57406L285.374 11.6475Z"
          fill="#C298DC"/>
        <path
          d="M273.242 17.1878L273.674 0.188965H274.647L277.725 15.5299L280.858 0.188965H281.866L282.28 17.1878H281.434L281.074 2.93817L278.103 17.1878H277.383L274.448 2.95915L274.088 17.1878H273.242Z"
          fill="#C298DC"/>
        <path
          d="M264.591 17.1878V0.188965H265.473V14.6695L270.46 0.188965H271.271V17.1878H270.388V2.70732L265.419 17.1878H264.591Z"
          fill="#C298DC"/>
        <path
          d="M255.372 17.1878V0.188965H256.362V7.84895H261.476V0.188965H262.448V17.1878H261.476V8.73037H256.362V17.1878H255.372Z"
          fill="#C298DC"/>
        <path
          d="M247.14 17.1878V0.188965H250.146C250.819 0.188965 251.371 0.300891 251.803 0.524745C252.247 0.734608 252.589 1.03541 252.829 1.42715C253.081 1.8189 253.255 2.2736 253.351 2.79126C253.459 3.29493 253.513 3.84057 253.513 4.42819C253.513 4.8759 253.465 5.35858 253.369 5.87624C253.285 6.37991 253.123 6.82762 252.883 7.21936C252.643 7.61111 252.283 7.87693 251.803 8.01684C252.367 8.21271 252.793 8.54849 253.081 9.02418C253.381 9.49987 253.579 10.0315 253.675 10.6191C253.783 11.1928 253.837 11.7314 253.837 12.2351C253.837 12.8787 253.795 13.4943 253.711 14.0819C253.639 14.6695 253.489 15.2011 253.261 15.6768C253.033 16.1385 252.703 16.5093 252.271 16.7891C251.851 17.0549 251.287 17.1878 250.579 17.1878H247.14ZM248.112 16.2854H250.452C251.413 16.2854 252.055 15.9566 252.379 15.2991C252.715 14.6415 252.883 13.6272 252.883 12.2561C252.883 11.5705 252.811 10.9479 252.667 10.3883C252.535 9.82865 252.301 9.38095 251.965 9.04517C251.641 8.70939 251.185 8.5415 250.597 8.5415H248.112V16.2854ZM248.112 7.63909H250.579C251.119 7.63909 251.533 7.49218 251.821 7.19838C252.109 6.90457 252.307 6.51982 252.415 6.04413C252.535 5.55445 252.595 5.01581 252.595 4.42819C252.595 3.7986 252.529 3.23197 252.397 2.7283C252.265 2.22463 252.007 1.83289 251.623 1.55307C251.251 1.25926 250.681 1.11236 249.912 1.11236H248.112V7.63909Z"
          fill="#C298DC"/>
        <path
          d="M233.252 17.1878L233.684 0.188965H234.656L237.735 15.5299L240.868 0.188965H241.876L242.29 17.1878H241.444L241.084 2.93817L238.113 17.1878H237.393L234.458 2.95915L234.098 17.1878H233.252Z"
          fill="#C298DC"/>
        <path
          d="M226.887 17.1878V0.188965H231.928V1.11236H227.877V7.9329H231.208V8.81432H227.877V16.2854H231.982V17.1878H226.887Z"
          fill="#C298DC"/>
        <path
          d="M218.072 17.1878V0.188965H218.954V14.6695L223.942 0.188965H224.752V17.1878H223.87V2.70732L218.9 17.1878H218.072Z"
          fill="#C298DC"/>
        <path
          d="M208.855 17.1878V0.188965H209.846V7.84895H214.959V0.188965H215.931V17.1878H214.959V8.73037H209.846V17.1878H208.855Z"
          fill="#C298DC"/>
        <path
          d="M202.327 17.1878V0.188965H207.368V1.11236H203.317V7.9329H206.648V8.81432H203.317V16.2854H207.422V17.1878H202.327Z"
          fill="#C298DC"/>
        <path
          d="M193.108 17.1878V0.188965H194.099V7.84895H199.212V0.188965H200.184V17.1878H199.212V8.73037H194.099V17.1878H193.108Z"
          fill="#C298DC"/>
        <path
          d="M186.58 17.1878V0.188965H191.621V1.11236H187.57V7.9329H190.901V8.81432H187.57V16.2854H191.675V17.1878H186.58Z"
          fill="#C298DC"/>
        <path
          d="M175.583 17.1878L176.015 0.188965H176.987L180.066 15.5299L183.199 0.188965H184.207L184.621 17.1878H183.775L183.415 2.93817L180.444 17.1878H179.724L176.789 2.95915L176.429 17.1878H175.583Z"
          fill="#C298DC"/>
        <path
          d="M166.931 17.1878V0.188965H167.813V14.6695L172.8 0.188965H173.61V17.1878H172.728V2.70732L167.759 17.1878H166.931Z"
          fill="#C298DC"/>
        <path
          d="M158.751 17.1878V0.188965H162.172C163.048 0.188965 163.732 0.384836 164.224 0.77658C164.716 1.15433 165.059 1.67199 165.251 2.32956C165.455 2.98713 165.557 3.73564 165.557 4.57509C165.557 5.3306 165.455 6.05113 165.251 6.73668C165.059 7.42223 164.716 7.98186 164.224 8.41558C163.732 8.8493 163.054 9.06615 162.19 9.06615H159.723V17.1878H158.751ZM159.723 8.18473H161.974C162.562 8.18473 163.042 8.06581 163.414 7.82796C163.798 7.59012 164.08 7.21237 164.26 6.69471C164.452 6.16305 164.548 5.45652 164.548 4.57509C164.548 3.66569 164.464 2.95915 164.296 2.45548C164.128 1.93782 163.858 1.58105 163.486 1.38518C163.114 1.17532 162.616 1.07039 161.992 1.07039H159.723V8.18473Z"
          fill="#C298DC"/>
        <path d="M149.479 17.1878V0.188965H156.591V17.1878H155.619V1.07039H150.452V17.1878H149.479Z" fill="#C298DC"/>
        <path
          d="M137.777 17.1878V0.188965H138.66V14.6695L143.647 0.188965H144.457V17.1878H143.575V2.70732L138.606 17.1878H137.777Z"
          fill="#C298DC"/>
        <path
          d="M123.908 17.1878L124.34 0.188965H125.313L128.391 15.5299L131.524 0.188965H132.532L132.946 17.1878H132.1L131.74 2.93817L128.769 17.1878H128.049L125.115 2.95915L124.754 17.1878H123.908Z"
          fill="#C298DC"/>
        <path
          d="M117.543 17.1878V0.188965H122.584V1.11236H118.533V7.9329H121.864V8.81432H118.533V16.2854H122.638V17.1878H117.543Z"
          fill="#C298DC"/>
        <path
          d="M108.729 17.1878V0.188965H109.611V14.6695L114.598 0.188965H115.408V17.1878H114.526V2.70732L109.557 17.1878H108.729Z"
          fill="#C298DC"/>
        <path
          d="M99.5098 17.1878V0.188965H100.5V7.84895H105.613V0.188965H106.586V17.1878H105.613V8.73037H100.5V17.1878H99.5098Z"
          fill="#C298DC"/>
        <path
          d="M92.9814 17.1878V0.188965H98.0227V1.11236H93.9717V7.9329H97.3025V8.81432H93.9717V16.2854H98.0767V17.1878H92.9814Z"
          fill="#C298DC"/>
        <path
          d="M89.8516 17.1878V9.297C89.0954 9.54884 88.3812 9.67476 87.7091 9.67476C86.9529 9.67476 86.2987 9.55583 85.7466 9.31799C85.2064 9.06615 84.7863 8.67441 84.4862 8.14276C84.1862 7.59712 84.0361 6.88358 84.0361 6.00216V0.188965H85.0264V6.00216C85.0264 6.77166 85.1524 7.35927 85.4045 7.76501C85.6565 8.17074 85.9926 8.45056 86.4127 8.60446C86.8448 8.75836 87.325 8.8353 87.8531 8.8353C88.2372 8.8353 88.5973 8.80033 88.9334 8.73037C89.2815 8.64643 89.5875 8.54849 89.8516 8.43657V0.188965H90.8418V17.1878H89.8516Z"
          fill="#C298DC"/>
        <path
          d="M76.3047 17.1878L79.3655 0.188965H80.4997L83.5785 17.1878H82.5883L81.8321 12.4869H78.0511L77.2769 17.1878H76.3047ZM78.1952 11.6475H81.7061L79.9416 1.57406L78.1952 11.6475Z"
          fill="#C298DC"/>
        <path
          d="M67.8418 17.1878V0.188965H68.832V7.84895H73.9453V0.188965H74.9176V17.1878H73.9453V8.73037H68.832V17.1878H67.8418Z"
          fill="#C298DC"/>
        <path
          d="M63.0526 17.4186C62.3204 17.4186 61.7263 17.2507 61.2701 16.9149C60.826 16.5791 60.5019 16.1174 60.2979 15.5298C60.0938 14.9282 59.9918 14.2217 59.9918 13.4102V13.0954H60.892C60.892 13.1234 60.892 13.1934 60.892 13.3053C60.892 13.4032 60.892 13.4662 60.892 13.4942C60.904 14.4735 61.0781 15.222 61.4142 15.7397C61.7503 16.2574 62.2964 16.5162 63.0526 16.5162C63.5087 16.5162 63.9168 16.4113 64.2769 16.2014C64.637 15.9775 64.9191 15.5858 65.1231 15.0262C65.3392 14.4665 65.4472 13.683 65.4472 12.6757C65.4472 11.5564 65.2551 10.633 64.871 9.90552C64.487 9.17799 63.8328 8.80024 62.9086 8.77226C62.8965 8.77226 62.8185 8.77226 62.6745 8.77226C62.5305 8.77226 62.3864 8.77226 62.2424 8.77226C62.0983 8.77226 62.0143 8.77226 61.9903 8.77226V7.93281C62.0143 7.93281 62.0983 7.93281 62.2424 7.93281C62.3864 7.93281 62.5305 7.93281 62.6745 7.93281C62.8185 7.93281 62.8905 7.93281 62.8905 7.93281C63.8268 7.90483 64.487 7.59003 64.871 6.98842C65.2551 6.37283 65.4472 5.43544 65.4472 4.17627C65.4472 3.22489 65.2731 2.4414 64.9251 1.8258C64.589 1.21021 64.0128 0.90241 63.1966 0.90241C62.4284 0.90241 61.8343 1.12626 61.4142 1.57397C61.0061 2.02168 60.796 2.80516 60.784 3.92443C60.784 3.95241 60.784 4.00838 60.784 4.09232C60.784 4.16228 60.784 4.21124 60.784 4.23922H59.8838V3.9664C59.8838 3.16893 59.9858 2.47638 60.1899 1.88876C60.4059 1.28716 60.754 0.82546 61.2341 0.503671C61.7142 0.16789 62.3564 0 63.1606 0C63.9048 0 64.517 0.188876 64.9971 0.566629C65.4772 0.930391 65.8313 1.42707 66.0594 2.05665C66.2994 2.68624 66.4194 3.39977 66.4194 4.19725C66.4194 5.47042 66.2154 6.44978 65.8073 7.13533C65.3992 7.80689 64.9011 8.21262 64.3129 8.35253C64.625 8.43648 64.9431 8.63235 65.2671 8.94015C65.5912 9.23396 65.8613 9.68166 66.0774 10.2833C66.3054 10.8849 66.4194 11.6754 66.4194 12.6547C66.4194 13.5781 66.3054 14.4036 66.0774 15.1311C65.8613 15.8446 65.5072 16.4043 65.0151 16.81C64.523 17.2157 63.8688 17.4186 63.0526 17.4186Z"
          fill="#C298DC"/>
        <path
          d="M51.4619 17.1878L54.5227 0.188965H55.657L58.7358 17.1878H57.7455L56.9893 12.4869H53.2084L52.4342 17.1878H51.4619ZM53.3524 11.6475H56.8633L55.0988 1.57406L53.3524 11.6475Z"
          fill="#C298DC"/>
        <path
          d="M43.001 17.1878V0.188965H43.9912V7.84895H49.1045V0.188965H50.0768V17.1878H49.1045V8.73037H43.9912V17.1878H43.001Z"
          fill="#C298DC"/>
        <path
          d="M29.3369 20V16.3064H29.859C30.0991 16.1245 30.3152 15.9427 30.5072 15.7608C30.7113 15.5649 30.8913 15.2781 31.0473 14.9003C31.2034 14.5226 31.3474 13.9909 31.4795 13.3054C31.6235 12.6058 31.7555 11.6685 31.8756 10.4932C31.9956 9.304 32.1156 7.79998 32.2356 5.98117L32.6137 0.188965H37.745V16.3064H38.8073V20H38.0151L37.9071 17.1878H30.2371L30.1291 20H29.3369ZM30.9573 16.3064H36.7548V1.07039H33.514L33.1719 6.19104C33.0519 8.00985 32.9258 9.50686 32.7938 10.6821C32.6738 11.8433 32.5477 12.7667 32.4157 13.4523C32.2837 14.1378 32.1396 14.6625 31.9836 15.0263C31.8395 15.376 31.6835 15.6419 31.5155 15.8237C31.3474 15.9916 31.1614 16.1525 30.9573 16.3064Z"
          fill="#C298DC"/>
        <path
          d="M23.9785 17.1878V0.188965H29.0198V1.11236H24.9688V7.9329H28.2996V8.81432H24.9688V16.2854H29.0738V17.1878H23.9785Z"
          fill="#C298DC"/>
        <path
          d="M15.7988 17.1878V0.188965H19.2197C20.0959 0.188965 20.7801 0.384836 21.2722 0.77658C21.7643 1.15433 22.1064 1.67199 22.2985 2.32956C22.5025 2.98713 22.6045 3.73564 22.6045 4.57509C22.6045 5.3306 22.5025 6.05113 22.2985 6.73668C22.1064 7.42223 21.7643 7.98186 21.2722 8.41558C20.7801 8.8493 20.1019 9.06615 19.2377 9.06615H16.7711V17.1878H15.7988ZM16.7711 8.18473H19.0216C19.6098 8.18473 20.0899 8.06581 20.462 7.82796C20.8461 7.59012 21.1282 7.21237 21.3082 6.69471C21.5003 6.16305 21.5963 5.45652 21.5963 4.57509C21.5963 3.66569 21.5123 2.95915 21.3442 2.45548C21.1762 1.93782 20.9061 1.58105 20.534 1.38518C20.1619 1.17532 19.6638 1.07039 19.0396 1.07039H16.7711V8.18473Z"
          fill="#C298DC"/>
        <path
          d="M9.25293 17.1878V0.188965H14.2942V1.11236H10.2432V7.9329H13.574V8.81432H10.2432V16.2854H14.3482V17.1878H9.25293Z"
          fill="#C298DC"/>
        <path d="M0 17.1878V0.188965H7.1118V17.1878H6.13955V1.07039H0.972246V17.1878H0Z" fill="#C298DC"/>
        <path
          d="M702.375 87.0721V26.6714H706.198V52.1739H712.639C716.179 52.1739 719.081 52.845 721.346 54.1873C723.611 55.4798 725.287 57.4434 726.372 60.0782C727.458 62.713 728 66.0188 728 69.9958C728 72.4815 727.717 74.7682 727.151 76.8562C726.632 78.9441 725.759 80.7586 724.532 82.2997C723.352 83.7911 721.794 84.9593 719.86 85.8044C717.925 86.6495 715.565 87.0721 712.781 87.0721H702.375ZM706.198 83.7911H712.922C715.707 83.7911 717.901 83.2691 719.506 82.2251C721.157 81.1314 722.337 79.5655 723.045 77.5273C723.753 75.4394 724.107 72.904 724.107 69.9213C724.107 65.3974 723.281 61.8678 721.629 59.3325C719.978 56.7475 717.052 55.4549 712.852 55.4549H706.198V83.7911Z"
          fill="#C298DC"/>
        <path d="M680.641 87.0721V29.9524H669.174V26.6714H695.648V29.9524H684.464V87.0721H680.641Z" fill="#C298DC"/>
        <path
          d="M625.643 87.0721V26.6714H629.536V52.1739H635.978C639.517 52.1739 642.419 52.845 644.685 54.1873C646.95 55.4798 648.625 57.4434 649.71 60.0782C650.796 62.713 651.339 66.0188 651.339 69.9958C651.339 72.4815 651.055 74.7682 650.489 76.8562C649.97 78.9441 649.097 80.7586 647.87 82.2997C646.69 83.7911 645.133 84.9593 643.198 85.8044C641.263 86.6495 638.903 87.0721 636.119 87.0721H625.643ZM629.536 83.7911H636.19C638.974 83.7911 641.169 83.2691 642.773 82.2251C644.425 81.1314 645.605 79.5655 646.313 77.5273C647.02 75.4394 647.374 72.904 647.374 69.9213C647.374 65.3974 646.549 61.8678 644.897 59.3325C643.292 56.7475 640.39 55.4549 636.19 55.4549H629.536V83.7911ZM658.701 87.0721V26.6714H662.523V87.0721H658.701Z"
          fill="#C298DC"/>
        <path
          d="M592.391 87.0721V26.6714H614.476V29.8033H596.284V52.1739H602.584C606.123 52.1739 609.026 52.845 611.291 54.1873C613.556 55.4798 615.232 57.4434 616.317 60.0782C617.402 62.713 617.945 66.0188 617.945 69.9958C617.945 72.4815 617.662 74.7682 617.096 76.8562C616.576 78.9441 615.703 80.7586 614.476 82.2997C613.297 83.7911 611.739 84.9593 609.804 85.8044C607.87 86.6495 605.51 87.0721 602.726 87.0721H592.391ZM596.284 83.7911H602.796C605.581 83.7911 607.775 83.2691 609.38 82.2251C611.031 81.1314 612.211 79.5655 612.919 77.5273C613.627 75.4394 613.981 72.904 613.981 69.9213C613.981 65.3974 613.155 61.8678 611.503 59.3325C609.899 56.7475 606.996 55.4549 602.796 55.4549H596.284V83.7911Z"
          fill="#C298DC"/>
        <path d="M557.313 87.0721V29.9524H545.846V26.6714H572.32V29.9524H561.136V87.0721H557.313Z" fill="#C298DC"/>
        <path
          d="M521.879 87.0721V26.6714H541.699V29.9524H525.772V54.1873H538.868V57.3192H525.772V83.8656H541.912V87.0721H521.879Z"
          fill="#C298DC"/>
        <path
          d="M474.646 87.0721L489.369 55.5295L475.99 26.6714H479.742L492.626 55.3058H493.121V26.6714H496.661V55.3058H497.156L510.039 26.6714H513.791L500.412 55.5295L515.136 87.0721H511.314L497.368 56.3498H496.661V87.0721H493.121V56.3498H492.342L478.468 87.0721H474.646Z"
          fill="#C298DC"/>
        <path
          d="M454.922 87.8921C451.194 87.8921 448.292 87.1215 446.215 85.5804C444.186 83.9896 442.77 81.852 441.968 79.1675C441.166 76.4333 440.765 73.3512 440.765 69.921V43.5237C440.765 40.0935 441.189 37.061 442.039 34.4263C442.888 31.7915 444.328 29.7284 446.357 28.2371C448.433 26.7457 451.288 26 454.922 26C458.556 26 461.387 26.7457 463.417 28.2371C465.493 29.7284 466.956 31.7915 467.806 34.4263C468.655 37.061 469.08 40.0935 469.08 43.5237V69.9956C469.08 73.4257 468.679 76.4831 467.876 79.1675C467.074 81.852 465.635 83.9896 463.558 85.5804C461.529 87.1215 458.65 87.8921 454.922 87.8921ZM454.922 84.5365C457.659 84.5365 459.759 83.9648 461.222 82.8214C462.732 81.678 463.771 80.0872 464.337 78.049C464.903 75.9611 465.186 73.55 465.186 70.8158V42.9271C465.186 40.1929 464.903 37.8067 464.337 35.7685C463.771 33.7303 462.756 32.1644 461.293 31.0707C459.83 29.9273 457.707 29.3556 454.922 29.3556C452.185 29.3556 450.061 29.9273 448.551 31.0707C447.088 32.1644 446.074 33.7303 445.507 35.7685C444.941 37.8067 444.658 40.1929 444.658 42.9271V70.8158C444.658 73.55 444.941 75.9611 445.507 78.049C446.074 80.0872 447.088 81.678 448.551 82.8214C450.061 83.9648 452.185 84.5365 454.922 84.5365Z"
          fill="#C298DC"/>
        <path
          d="M396.533 87.0721L398.232 26.6714H402.055L414.159 81.1812L426.476 26.6714H430.441L432.069 87.0721H428.742L427.326 36.4399L415.646 87.0721H412.814L401.276 36.5145L399.86 87.0721H396.533Z"
          fill="#C298DC"/>
        <path
          d="M355.97 87.0721V26.6714H375.79V29.9524H359.863V54.1873H372.959V57.3192H359.863V83.8656H376.003V87.0721H355.97Z"
          fill="#C298DC"/>
        <path
          d="M319.191 87.0721V26.6714H322.66V78.1238L342.268 26.6714H345.454V87.0721H341.985V35.6196L322.448 87.0721H319.191Z"
          fill="#C298DC"/>
        <path
          d="M280.828 87.0721V26.6714H284.721V53.889H304.825V26.6714H308.648V87.0721H304.825V57.0209H284.721V87.0721H280.828Z"
          fill="#C298DC"/>
        <path
          d="M253.037 87.0721V26.6714H272.858V29.9524H256.93V54.1873H270.026V57.3192H256.93V83.8656H273.07V87.0721H253.037Z"
          fill="#C298DC"/>
        <path
          d="M238.606 87.0721V59.0342C235.633 59.9291 232.825 60.3765 230.182 60.3765C227.209 60.3765 224.637 59.9539 222.466 59.1088C220.342 58.214 218.691 56.822 217.511 54.933C216.331 52.9942 215.741 50.4588 215.741 47.3269V26.6714H219.635V47.3269C219.635 50.0611 220.13 52.149 221.121 53.5907C222.112 55.0324 223.434 56.0266 225.085 56.5735C226.784 57.1203 228.672 57.3937 230.748 57.3937C232.258 57.3937 233.674 57.2694 234.996 57.0209C236.364 56.7226 237.567 56.3746 238.606 55.9769V26.6714H242.499V87.0721H238.606Z"
          fill="#C298DC"/>
        <path
          d="M189.51 87.0721V26.6714H209.33V29.9524H193.403V54.1873H206.499V57.3192H193.403V83.8656H209.543V87.0721H189.51Z"
          fill="#C298DC"/>
        <path
          d="M146.636 87.2212V84.0148C148.146 83.9153 149.396 83.7165 150.387 83.4182C151.379 83.1199 152.181 82.424 152.794 81.3303C153.408 80.2366 153.927 78.4718 154.352 76.0359C154.776 73.6 155.177 70.2195 155.555 65.8946C155.98 61.5696 156.452 56.0018 156.971 49.1912L158.528 26.6714H179.057V87.0721H175.163V29.8033H162.138L160.723 49.1912C160.203 56.0018 159.731 61.6938 159.307 66.2674C158.882 70.7912 158.387 74.4451 157.82 77.229C157.301 80.0129 156.593 82.1257 155.697 83.5673C154.847 84.9593 153.691 85.9038 152.228 86.401C150.765 86.8981 148.901 87.1715 146.636 87.2212Z"
          fill="#C298DC"/>
        <path
          d="M126.628 87.8921C122.9 87.8921 119.998 87.1215 117.921 85.5804C115.892 83.9896 114.476 81.852 113.674 79.1675C112.872 76.4333 112.471 73.3512 112.471 69.921V43.5237C112.471 40.0935 112.895 37.061 113.745 34.4263C114.594 31.7915 116.034 29.7284 118.063 28.2371C120.139 26.7457 122.995 26 126.628 26C130.262 26 133.094 26.7457 135.123 28.2371C137.199 29.7284 138.662 31.7915 139.512 34.4263C140.361 37.061 140.786 40.0935 140.786 43.5237V69.9956C140.786 73.4257 140.385 76.4831 139.582 79.1675C138.78 81.852 137.341 83.9896 135.264 85.5804C133.235 87.1215 130.356 87.8921 126.628 87.8921ZM126.628 84.5365C129.365 84.5365 131.465 83.9648 132.928 82.8214C134.439 81.678 135.477 80.0872 136.043 78.049C136.609 75.9611 136.893 73.55 136.893 70.8158V42.9271C136.893 40.1929 136.609 37.8067 136.043 35.7685C135.477 33.7303 134.462 32.1644 132.999 31.0707C131.536 29.9273 129.413 29.3556 126.628 29.3556C123.891 29.3556 121.768 29.9273 120.257 31.0707C118.794 32.1644 117.78 33.7303 117.213 35.7685C116.647 37.8067 116.364 40.1929 116.364 42.9271V70.8158C116.364 73.55 116.647 75.9611 117.213 78.049C117.78 80.0872 118.794 81.678 120.257 82.8214C121.768 83.9648 123.891 84.5365 126.628 84.5365Z"
          fill="#C298DC"/>
        <path
          d="M68.2412 87.0721L69.9401 26.6714H73.7627L85.8674 81.1812L98.1845 26.6714H102.149L103.777 87.0721H100.45L99.0339 36.4399L87.3539 87.0721H84.5224L72.984 36.5145L71.5682 87.0721H68.2412Z"
          fill="#C298DC"/>
        <path
          d="M32.792 87.0721L44.8259 26.6714H49.2856L61.3903 87.0721H57.497L54.5239 70.3687H39.6584L36.6145 87.0721H32.792ZM40.2247 67.3859H54.0284L47.0911 31.5929L40.2247 67.3859Z"
          fill="#C298DC"/>
        <path
          d="M13.9452 87.8921C10.1699 87.8921 7.26755 87.0718 5.2383 85.4313C3.25624 83.7908 1.88768 81.6034 1.13261 78.8693C0.377535 76.0854 0 73.028 0 69.6973V44.0456C0 40.5658 0.401131 37.4836 1.20339 34.7991C2.00566 32.0649 3.42141 29.9273 5.45067 28.3862C7.47992 26.7954 10.3114 26 13.9452 26C17.2958 26 19.915 26.696 21.8027 28.0879C23.6903 29.4799 25.0353 31.4684 25.8376 34.0534C26.6398 36.5888 27.041 39.5715 27.041 43.0017V46.059H23.36V43.0017C23.36 40.1184 23.1004 37.6824 22.5813 35.6939C22.0622 33.6557 21.1184 32.0898 19.7498 30.9961C18.3812 29.9024 16.4464 29.3556 13.9452 29.3556C11.2081 29.3556 9.10804 29.9521 7.64509 31.1452C6.18214 32.3383 5.19111 34.0037 4.672 36.1414C4.15289 38.279 3.89333 40.7646 3.89333 43.5982V70.2938C3.89333 73.2766 4.17648 75.8368 4.74279 77.9744C5.30909 80.0624 6.32372 81.678 7.78667 82.8214C9.24961 83.9648 11.3025 84.5365 13.9452 84.5365C16.4936 84.5365 18.452 83.9648 19.8206 82.8214C21.1892 81.678 22.1094 80.0872 22.5813 78.049C23.1004 75.9611 23.36 73.55 23.36 70.8158V67.162H27.041V70.1447C27.041 73.5749 26.6634 76.6322 25.9084 79.3167C25.1533 82.0011 23.8319 84.1139 21.9442 85.655C20.0566 87.1464 17.3902 87.8921 13.9452 87.8921Z"
          fill="#C298DC"/>
        <path
          d="M701.235 156.008L711.205 128.492C708.687 127.348 706.87 125.285 705.756 122.303C704.641 119.27 704.084 115.765 704.084 111.788C704.084 108.905 704.455 106.245 705.199 103.809C705.983 101.374 707.304 99.4099 709.162 97.9186C711.019 96.3775 713.6 95.6069 716.902 95.6069H727.863V156.008H724.395V129.685H716.717C716.345 129.685 715.911 129.685 715.416 129.685C714.962 129.635 714.57 129.585 714.24 129.536L705.013 156.008H701.235ZM717.15 126.553H724.395V98.8134H717.026C713.971 98.8134 711.597 99.8076 709.905 101.796C708.253 103.785 707.428 107.115 707.428 111.788C707.428 116.66 708.171 120.339 709.657 122.825C711.143 125.31 713.641 126.553 717.15 126.553Z"
          fill="#C298DC"/>
        <path
          d="M678.388 156.008V95.6069H681.732V121.109H687.367C690.463 121.109 693.002 121.781 694.984 123.123C696.965 124.415 698.431 126.379 699.38 129.014C700.33 131.649 700.804 134.954 700.804 138.931C700.804 141.417 700.557 143.704 700.061 145.792C699.607 147.88 698.844 149.694 697.77 151.235C696.738 152.727 695.376 153.895 693.683 154.74C691.99 155.585 689.926 156.008 687.491 156.008H678.388ZM681.732 152.727H687.614C690.05 152.727 691.97 152.205 693.373 151.161C694.818 150.067 695.85 148.501 696.47 146.463C697.089 144.375 697.399 141.84 697.399 138.857C697.399 134.333 696.676 130.803 695.231 128.268C693.786 125.683 691.227 124.39 687.553 124.39H681.732V152.727Z"
          fill="#C298DC"/>
        <path
          d="M650.63 156.008V95.6069H660.971C663.283 95.6069 665.182 96.0046 666.668 96.8C668.196 97.5457 669.372 98.6145 670.198 100.006C671.065 101.398 671.664 103.014 671.994 104.853C672.365 106.643 672.551 108.582 672.551 110.67C672.551 112.261 672.386 113.976 672.056 115.815C671.767 117.605 671.21 119.196 670.384 120.587C669.558 121.979 668.32 122.924 666.668 123.421C668.609 124.117 670.074 125.31 671.065 127C672.097 128.691 672.778 130.58 673.109 132.668C673.48 134.706 673.666 136.62 673.666 138.409C673.666 140.696 673.521 142.884 673.232 144.971C672.985 147.059 672.469 148.948 671.684 150.639C670.9 152.279 669.765 153.597 668.278 154.591C666.833 155.535 664.893 156.008 662.457 156.008H650.63ZM653.974 152.801H662.024C665.327 152.801 667.535 151.633 668.65 149.296C669.806 146.96 670.384 143.356 670.384 138.484C670.384 136.048 670.136 133.836 669.641 131.847C669.187 129.859 668.382 128.268 667.226 127.075C666.111 125.882 664.542 125.285 662.519 125.285H653.974V152.801ZM653.974 122.079H662.457C664.315 122.079 665.739 121.557 666.73 120.513C667.721 119.469 668.402 118.102 668.774 116.412C669.187 114.672 669.393 112.758 669.393 110.67C669.393 108.433 669.166 106.419 668.712 104.63C668.258 102.84 667.37 101.448 666.049 100.454C664.769 99.4099 662.808 98.888 660.166 98.888H653.974V122.079Z"
          fill="#C298DC"/>
        <path
          d="M632.508 156.828C629.247 156.828 626.708 156.057 624.891 154.516C623.116 152.925 621.878 150.788 621.176 148.103C620.474 145.369 620.123 142.287 620.123 138.857V112.459C620.123 109.029 620.495 105.997 621.238 103.362C621.981 100.727 623.24 98.6642 625.015 97.1729C626.832 95.6815 629.329 94.9358 632.508 94.9358C635.687 94.9358 638.164 95.6815 639.939 97.1729C641.755 98.6642 643.035 100.727 643.778 103.362C644.521 105.997 644.893 109.029 644.893 112.459V138.931C644.893 142.362 644.542 145.419 643.84 148.103C643.138 150.788 641.879 152.925 640.063 154.516C638.288 156.057 635.769 156.828 632.508 156.828ZM632.508 153.472C634.902 153.472 636.739 152.901 638.019 151.757C639.34 150.614 640.249 149.023 640.744 146.985C641.239 144.897 641.487 142.486 641.487 139.752V111.863C641.487 109.129 641.239 106.743 640.744 104.704C640.249 102.666 639.361 101.1 638.081 100.006C636.801 98.8631 634.944 98.2914 632.508 98.2914C630.114 98.2914 628.256 98.8631 626.935 100.006C625.655 101.1 624.767 102.666 624.272 104.704C623.777 106.743 623.529 109.129 623.529 111.863V139.752C623.529 142.486 623.777 144.897 624.272 146.985C624.767 149.023 625.655 150.614 626.935 151.757C628.256 152.901 630.114 153.472 632.508 153.472Z"
          fill="#C298DC"/>
        <path
          d="M593.599 156.008V95.6069H605.364C608.378 95.6069 610.731 96.3029 612.424 97.6949C614.116 99.0371 615.293 100.876 615.953 103.213C616.655 105.549 617.006 108.209 617.006 111.192C617.006 113.876 616.655 116.436 615.953 118.872C615.293 121.308 614.116 123.297 612.424 124.838C610.731 126.379 608.399 127.15 605.426 127.15H596.943V156.008H593.599ZM596.943 124.018H604.683C606.706 124.018 608.357 123.595 609.637 122.75C610.958 121.905 611.928 120.563 612.548 118.723C613.208 116.834 613.538 114.324 613.538 111.192C613.538 107.96 613.249 105.45 612.671 103.66C612.093 101.821 611.165 100.553 609.885 99.8574C608.605 99.1117 606.892 98.7388 604.745 98.7388H596.943V124.018Z"
          fill="#C298DC"/>
        <path
          d="M575.478 156.828C572.216 156.828 569.677 156.057 567.861 154.516C566.086 152.925 564.847 150.788 564.145 148.103C563.444 145.369 563.093 142.287 563.093 138.857V112.459C563.093 109.029 563.464 105.997 564.207 103.362C564.95 100.727 566.21 98.6642 567.985 97.1729C569.801 95.6815 572.299 94.9358 575.478 94.9358C578.656 94.9358 581.133 95.6815 582.909 97.1729C584.725 98.6642 586.005 100.727 586.748 103.362C587.491 105.997 587.863 109.029 587.863 112.459V138.931C587.863 142.362 587.512 145.419 586.81 148.103C586.108 150.788 584.849 152.925 583.033 154.516C581.257 156.057 578.739 156.828 575.478 156.828ZM575.478 153.472C577.872 153.472 579.709 152.901 580.989 151.757C582.31 150.614 583.218 149.023 583.714 146.985C584.209 144.897 584.457 142.486 584.457 139.752V111.863C584.457 109.129 584.209 106.743 583.714 104.704C583.218 102.666 582.331 101.1 581.051 100.006C579.771 98.8631 577.913 98.2914 575.478 98.2914C573.083 98.2914 571.226 98.8631 569.904 100.006C568.625 101.1 567.737 102.666 567.242 104.704C566.746 106.743 566.499 109.129 566.499 111.863V139.752C566.499 142.486 566.746 144.897 567.242 146.985C567.737 149.023 568.625 150.614 569.904 151.757C571.226 152.901 573.083 153.472 575.478 153.472Z"
          fill="#C298DC"/>
        <path
          d="M527.581 166V152.876H529.377C530.203 152.229 530.946 151.583 531.606 150.937C532.308 150.241 532.927 149.222 533.464 147.88C534.001 146.537 534.496 144.648 534.95 142.212C535.445 139.727 535.9 136.396 536.312 132.22C536.725 127.995 537.138 122.651 537.551 116.188L538.851 95.6069H556.5V152.876H560.153V166H557.429L557.057 156.008H530.677L530.306 166H527.581ZM533.154 152.876H553.094V98.7388H541.948L540.771 116.934C540.358 123.396 539.925 128.715 539.471 132.891C539.058 137.017 538.624 140.298 538.17 142.734C537.716 145.17 537.221 147.035 536.684 148.327C536.189 149.57 535.652 150.514 535.074 151.161C534.496 151.757 533.856 152.329 533.154 152.876Z"
          fill="#C298DC"/>
        <path
          d="M515.754 156.828C513.236 156.828 511.192 156.231 509.624 155.038C508.096 153.845 506.982 152.205 506.28 150.117C505.578 147.979 505.227 145.469 505.227 142.585V141.467H508.323C508.323 141.566 508.323 141.815 508.323 142.212C508.323 142.56 508.323 142.784 508.323 142.884C508.365 146.363 508.963 149.023 510.119 150.862C511.275 152.702 513.153 153.621 515.754 153.621C517.323 153.621 518.727 153.249 519.965 152.503C521.204 151.707 522.174 150.316 522.876 148.327C523.619 146.339 523.99 143.555 523.99 139.975C523.99 135.998 523.33 132.717 522.009 130.132C520.688 127.547 518.438 126.205 515.259 126.106C515.218 126.106 514.949 126.106 514.454 126.106C513.958 126.106 513.463 126.106 512.968 126.106C512.472 126.106 512.183 126.106 512.101 126.106V123.123C512.183 123.123 512.472 123.123 512.968 123.123C513.463 123.123 513.958 123.123 514.454 123.123C514.949 123.123 515.197 123.123 515.197 123.123C518.417 123.023 520.688 121.905 522.009 119.767C523.33 117.58 523.99 114.249 523.99 109.775C523.99 106.395 523.392 103.611 522.194 101.423C521.038 99.2359 519.057 98.1423 516.25 98.1423C513.607 98.1423 511.564 98.9377 510.119 100.528C508.715 102.119 507.993 104.903 507.952 108.88C507.952 108.98 507.952 109.178 507.952 109.477C507.952 109.725 507.952 109.899 507.952 109.999H504.855V109.029C504.855 106.196 505.206 103.735 505.908 101.647C506.651 99.5093 507.848 97.8688 509.5 96.7254C511.151 95.5323 513.36 94.9358 516.126 94.9358C518.685 94.9358 520.791 95.6069 522.442 96.9491C524.093 98.2417 525.311 100.006 526.096 102.244C526.921 104.481 527.334 107.016 527.334 109.85C527.334 114.373 526.632 117.853 525.229 120.289C523.825 122.675 522.112 124.117 520.089 124.614C521.162 124.912 522.256 125.608 523.371 126.702C524.486 127.746 525.414 129.337 526.158 131.474C526.942 133.612 527.334 136.421 527.334 139.901C527.334 143.182 526.942 146.115 526.158 148.7C525.414 151.235 524.197 153.224 522.504 154.665C520.811 156.107 518.561 156.828 515.754 156.828Z"
          fill="#C298DC"/>
        <path
          d="M478.288 156.828C475.027 156.828 472.488 156.057 470.671 154.516C468.896 152.925 467.658 150.788 466.956 148.103C466.254 145.369 465.903 142.287 465.903 138.857V112.459C465.903 109.029 466.275 105.997 467.018 103.362C467.761 100.727 469.02 98.6642 470.795 97.1729C472.612 95.6815 475.109 94.9358 478.288 94.9358C481.467 94.9358 483.944 95.6815 485.719 97.1729C487.536 98.6642 488.815 100.727 489.559 103.362C490.302 105.997 490.673 109.029 490.673 112.459V138.931C490.673 142.362 490.322 145.419 489.62 148.103C488.919 150.788 487.66 152.925 485.843 154.516C484.068 156.057 481.55 156.828 478.288 156.828ZM478.288 153.472C480.683 153.472 482.52 152.901 483.8 151.757C485.121 150.614 486.029 149.023 486.524 146.985C487.02 144.897 487.267 142.486 487.267 139.752V111.863C487.267 109.129 487.02 106.743 486.524 104.704C486.029 102.666 485.141 101.1 483.861 100.006C482.582 98.8631 480.724 98.2914 478.288 98.2914C475.894 98.2914 474.036 98.8631 472.715 100.006C471.435 101.1 470.548 102.666 470.052 104.704C469.557 106.743 469.309 109.129 469.309 111.863V139.752C469.309 142.486 469.557 144.897 470.052 146.985C470.548 149.023 471.435 150.614 472.715 151.757C474.036 152.901 475.894 153.472 478.288 153.472Z"
          fill="#C298DC"/>
        <path d="M446.572 156.008V95.6069H463.602V98.888H449.916V156.008H446.572Z" fill="#C298DC"/>
        <path
          d="M424.678 156.008V95.6069H442.017V98.888H428.084V123.123H439.54V126.255H428.084V152.801H442.202V156.008H424.678Z"
          fill="#C298DC"/>
        <path
          d="M383.738 156.008V95.6069H387.082V152.876H399.343V95.6069H402.749V152.876H414.515V95.6069H417.921V156.008H383.738Z"
          fill="#C298DC"/>
        <path
          d="M354.583 156.008L365.11 95.6069H369.011L379.601 156.008H376.195L373.594 139.304H360.59L357.927 156.008H354.583ZM361.085 136.321H373.16L367.092 100.528L361.085 136.321Z"
          fill="#C298DC"/>
        <path
          d="M329.487 156.008V95.6069H339.829C342.141 95.6069 344.04 96.0046 345.526 96.8C347.053 97.5457 348.23 98.6145 349.056 100.006C349.922 101.398 350.521 103.014 350.851 104.853C351.223 106.643 351.409 108.582 351.409 110.67C351.409 112.261 351.244 113.976 350.913 115.815C350.624 117.605 350.067 119.196 349.241 120.587C348.416 121.979 347.177 122.924 345.526 123.421C347.466 124.117 348.932 125.31 349.922 127C350.955 128.691 351.636 130.58 351.966 132.668C352.338 134.706 352.523 136.62 352.523 138.409C352.523 140.696 352.379 142.884 352.09 144.971C351.842 147.059 351.326 148.948 350.542 150.639C349.757 152.279 348.622 153.597 347.136 154.591C345.691 155.535 343.751 156.008 341.315 156.008H329.487ZM332.831 152.801H340.881C344.184 152.801 346.393 151.633 347.507 149.296C348.663 146.96 349.241 143.356 349.241 138.484C349.241 136.048 348.994 133.836 348.498 131.847C348.044 129.859 347.239 128.268 346.083 127.075C344.968 125.882 343.4 125.285 341.377 125.285H332.831V152.801ZM332.831 122.079H341.315C343.173 122.079 344.597 121.557 345.588 120.513C346.579 119.469 347.26 118.102 347.631 116.412C348.044 114.672 348.25 112.758 348.25 110.67C348.25 108.433 348.023 106.419 347.569 104.63C347.115 102.84 346.228 101.448 344.907 100.454C343.627 99.4099 341.666 98.888 339.024 98.888H332.831V122.079Z"
          fill="#C298DC"/>
        <path
          d="M286.833 156.008L296.803 128.492C294.285 127.348 292.468 125.285 291.353 122.303C290.239 119.27 289.682 115.765 289.682 111.788C289.682 108.905 290.053 106.245 290.796 103.809C291.581 101.374 292.902 99.4099 294.759 97.9186C296.617 96.3775 299.197 95.6069 302.5 95.6069H313.461V156.008H309.993V129.685H302.314C301.943 129.685 301.509 129.685 301.014 129.685C300.56 129.635 300.167 129.585 299.837 129.536L290.61 156.008H286.833ZM302.748 126.553H309.993V98.8134H302.624C299.569 98.8134 297.195 99.8076 295.502 101.796C293.851 103.785 293.025 107.115 293.025 111.788C293.025 116.66 293.769 120.339 295.255 122.825C296.741 125.31 299.239 126.553 302.748 126.553Z"
          fill="#C298DC"/>
        <path
          d="M254.965 156.157V152.95C256.286 152.851 257.38 152.652 258.247 152.354C259.114 152.055 259.816 151.36 260.352 150.266C260.889 149.172 261.343 147.407 261.715 144.971C262.086 142.536 262.437 139.155 262.767 134.83C263.139 130.505 263.552 124.937 264.006 118.127L265.368 95.6069H283.326V156.008H279.92V98.7388H268.526L267.288 118.127C266.834 124.937 266.421 130.629 266.049 135.203C265.678 139.727 265.244 143.381 264.749 146.165C264.295 148.948 263.676 151.061 262.891 152.503C262.148 153.895 261.137 154.839 259.857 155.337C258.577 155.834 256.946 156.107 254.965 156.157Z"
          fill="#C298DC"/>
        <path
          d="M221.495 166V152.876H223.291C224.117 152.229 224.86 151.583 225.52 150.937C226.222 150.241 226.841 149.222 227.378 147.88C227.915 146.537 228.41 144.648 228.864 142.212C229.36 139.727 229.814 136.396 230.226 132.22C230.639 127.995 231.052 122.651 231.465 116.188L232.765 95.6069H250.414V152.876H254.067V166H251.343L250.971 156.008H224.591L224.22 166H221.495ZM227.068 152.876H247.008V98.7388H235.862L234.685 116.934C234.272 123.396 233.839 128.715 233.385 132.891C232.972 137.017 232.538 140.298 232.084 142.734C231.63 145.17 231.135 147.035 230.598 148.327C230.103 149.57 229.566 150.514 228.988 151.161C228.41 151.757 227.77 152.329 227.068 152.876Z"
          fill="#C298DC"/>
        <path
          d="M179.062 156.008L180.548 95.6069H183.892L194.481 150.117L205.256 95.6069H208.723L210.148 156.008H207.237L205.999 105.375L195.781 156.008H193.304L183.21 105.45L181.972 156.008H179.062Z"
          fill="#C298DC"/>
        <path
          d="M140.671 156.008V95.6069H144.077V121.109H149.712C152.808 121.109 155.347 121.781 157.329 123.123C159.31 124.415 160.776 126.379 161.725 129.014C162.675 131.649 163.15 134.954 163.15 138.931C163.15 141.417 162.902 143.704 162.406 145.792C161.952 147.88 161.189 149.694 160.115 151.235C159.083 152.727 157.721 153.895 156.028 154.74C154.336 155.585 152.271 156.008 149.836 156.008H140.671ZM144.077 152.727H149.898C152.333 152.727 154.253 152.205 155.657 151.161C157.102 150.067 158.134 148.501 158.753 146.463C159.372 144.375 159.682 141.84 159.682 138.857C159.682 134.333 158.959 130.803 157.514 128.268C156.111 125.683 153.572 124.39 149.898 124.39H144.077V152.727ZM169.59 156.008V95.6069H172.934V156.008H169.59Z"
          fill="#C298DC"/>
        <path
          d="M109.585 156.008V95.6069H112.991V122.825H130.577V95.6069H133.921V156.008H130.577V125.956H112.991V156.008H109.585Z"
          fill="#C298DC"/>
        <path
          d="M73.085 166V152.876H74.8808C75.7064 152.229 76.4495 151.583 77.1101 150.937C77.8119 150.241 78.4311 149.222 78.9678 147.88C79.5045 146.537 79.9999 144.648 80.454 142.212C80.9494 139.727 81.4035 136.396 81.8163 132.22C82.2292 127.995 82.642 122.651 83.0548 116.188L84.3553 95.6069H102.004V152.876H105.657V166H102.933L102.561 156.008H76.1812L75.8096 166H73.085ZM78.6582 152.876H98.5979V98.7388H87.4515L86.2749 116.934C85.8621 123.396 85.4286 128.715 84.9745 132.891C84.5617 137.017 84.1282 140.298 83.6741 142.734C83.22 145.17 82.7246 147.035 82.1879 148.327C81.6925 149.57 81.1558 150.514 80.5778 151.161C79.9999 151.757 79.36 152.329 78.6582 152.876Z"
          fill="#C298DC"/>
        <path
          d="M55.2754 156.008V95.6069H72.6143V98.888H58.6812V123.123H70.1373V126.255H58.6812V152.801H72.8001V156.008H55.2754Z"
          fill="#C298DC"/>
        <path
          d="M27.7598 156.008V95.6069H39.5255C42.5391 95.6069 44.8923 96.3029 46.5849 97.6949C48.2775 99.0371 49.454 100.876 50.1146 103.213C50.8164 105.549 51.1673 108.209 51.1673 111.192C51.1673 113.876 50.8164 116.436 50.1146 118.872C49.454 121.308 48.2775 123.297 46.5849 124.838C44.8923 126.379 42.5598 127.15 39.5874 127.15H31.1037V156.008H27.7598ZM31.1037 124.018H38.8443C40.8672 124.018 42.5185 123.595 43.7983 122.75C45.1193 121.905 46.0895 120.563 46.7087 118.723C47.3692 116.834 47.6995 114.324 47.6995 111.192C47.6995 107.96 47.4105 105.45 46.8326 103.66C46.2546 101.821 45.3257 100.553 44.046 99.8574C42.7662 99.1117 41.0529 98.7388 38.9062 98.7388H31.1037V124.018Z"
          fill="#C298DC"/>
        <path
          d="M0 156.008V95.6069H10.3414C12.6533 95.6069 14.5523 96.0046 16.0385 96.8C17.566 97.5457 18.7425 98.6145 19.5682 100.006C20.4351 101.398 21.0338 103.014 21.364 104.853C21.7356 106.643 21.9213 108.582 21.9213 110.67C21.9213 112.261 21.7562 113.976 21.4259 115.815C21.137 117.605 20.5796 119.196 19.754 120.587C18.9283 121.979 17.6898 122.924 16.0385 123.421C17.9788 124.117 19.4443 125.31 20.4351 127C21.4672 128.691 22.1484 130.58 22.4787 132.668C22.8502 134.706 23.036 136.62 23.036 138.409C23.036 140.696 22.8915 142.884 22.6025 144.971C22.3548 147.059 21.8388 148.948 21.0544 150.639C20.27 152.279 19.1347 153.597 17.6485 154.591C16.2036 155.535 14.2633 156.008 11.8276 156.008H0ZM3.34393 152.801H11.3941C14.6968 152.801 16.9054 151.633 18.0201 149.296C19.176 146.96 19.754 143.356 19.754 138.484C19.754 136.048 19.5063 133.836 19.0109 131.847C18.5568 129.859 17.7517 128.268 16.5958 127.075C15.4812 125.882 13.9124 125.285 11.8895 125.285H3.34393V152.801ZM3.34393 122.079H11.8276C13.6854 122.079 15.1096 121.557 16.1004 120.513C17.0912 119.469 17.7724 118.102 18.1439 116.412C18.5568 114.672 18.7632 112.758 18.7632 110.67C18.7632 108.433 18.5361 106.419 18.082 104.63C17.6279 102.84 16.7403 101.448 15.4192 100.454C14.1395 99.4099 12.1785 98.888 9.5364 98.888H3.34393V122.079Z"
          fill="#C298DC"/>
      </g>
    </svg>

    <svg class="mob" viewBox="0 0 320 103" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M314.639 11.8996C313.867 11.8996 313.267 11.7515 312.837 11.4552C312.418 11.1493 312.125 10.7383 311.959 10.2222C311.793 9.69651 311.71 9.10392 311.71 8.44442V3.36917C311.71 2.70967 311.798 2.12664 311.974 1.62007C312.149 1.1135 312.447 0.716844 312.867 0.430107C313.296 0.143369 313.887 0 314.639 0C315.39 0 315.976 0.143369 316.396 0.430107C316.825 0.716844 317.128 1.1135 317.304 1.62007C317.479 2.12664 317.567 2.70967 317.567 3.36917V8.45876C317.567 9.11826 317.484 9.70607 317.318 10.2222C317.152 10.7383 316.855 11.1493 316.425 11.4552C316.005 11.7515 315.41 11.8996 314.639 11.8996ZM314.639 11.2544C315.205 11.2544 315.639 11.1445 315.942 10.9247C316.254 10.7049 316.469 10.399 316.586 10.0071C316.703 9.60571 316.762 9.14215 316.762 8.61646V3.25447C316.762 2.72879 316.703 2.27001 316.586 1.87813C316.469 1.48626 316.259 1.18518 315.956 0.974908C315.654 0.755076 315.215 0.64516 314.639 0.64516C314.072 0.64516 313.633 0.755076 313.321 0.974908C313.018 1.18518 312.808 1.48626 312.691 1.87813C312.574 2.27001 312.515 2.72879 312.515 3.25447V8.61646C312.515 9.14215 312.574 9.60571 312.691 10.0071C312.808 10.399 313.018 10.7049 313.321 10.9247C313.633 11.1445 314.072 11.2544 314.639 11.2544Z"
        fill="#C298DC"/>
      <path
        d="M303.437 11.742V0.12915H304.242V5.36211H308.401V0.12915H309.191V11.742H308.401V5.96426H304.242V11.742H303.437Z"
        fill="#C298DC"/>
      <path
        d="M296.131 11.742V0.12915H296.922V5.03236H298.254C298.986 5.03236 299.587 5.16139 300.055 5.41946C300.524 5.66796 300.87 6.0455 301.095 6.55207C301.319 7.05864 301.432 7.69424 301.432 8.45888C301.432 8.93677 301.373 9.37644 301.256 9.77787C301.149 10.1793 300.968 10.5282 300.714 10.8245C300.47 11.1112 300.148 11.3358 299.748 11.4983C299.347 11.6608 298.859 11.742 298.283 11.742H296.131ZM296.922 11.1112H298.313C298.889 11.1112 299.343 11.0108 299.675 10.8101C300.016 10.5999 300.26 10.2988 300.407 9.9069C300.553 9.50547 300.626 9.01801 300.626 8.44454C300.626 7.57477 300.455 6.89616 300.114 6.4087C299.772 5.91169 299.167 5.66319 298.298 5.66319H296.922V11.1112Z"
        fill="#C298DC"/>
      <path
        d="M286.676 11.7707V11.1542C286.988 11.1351 287.247 11.0969 287.452 11.0395C287.657 10.9822 287.823 10.8484 287.95 10.6381C288.077 10.4278 288.184 10.0885 288.272 9.62016C288.36 9.15183 288.443 8.50189 288.521 7.67035C288.609 6.83881 288.706 5.76832 288.814 4.45889L289.136 0.12915H293.382V11.742H292.577V0.7313H289.883L289.59 4.45889C289.482 5.76832 289.385 6.8627 289.297 7.74203C289.209 8.6118 289.107 9.31431 288.989 9.84955C288.882 10.3848 288.736 10.791 288.55 11.0682C288.374 11.3358 288.135 11.5174 287.833 11.613C287.53 11.7086 287.144 11.7611 286.676 11.7707Z"
        fill="#C298DC"/>
      <path
        d="M281.074 11.742V0.12915H285.174V0.759974H281.88V5.41946H284.589V6.02161H281.88V11.1255H285.218V11.742H281.074Z"
        fill="#C298DC"/>
      <path d="M276.021 11.742V0.759974H273.648V0.12915H279.125V0.759974H276.811V11.742H276.021Z" fill="#C298DC"/>
      <path
        d="M266.933 11.742L269.422 0.12915H270.344L272.848 11.742H272.043L271.428 8.53056H268.353L267.723 11.742H266.933ZM268.47 7.95709H271.326L269.891 1.07539L268.47 7.95709Z"
        fill="#C298DC"/>
      <path
        d="M257.593 11.742L257.944 0.12915H258.735L261.239 10.6094L263.787 0.12915H264.607L264.944 11.742H264.255L263.963 2.00728L261.546 11.742H260.961L258.574 2.02162L258.281 11.742H257.593Z"
        fill="#C298DC"/>
      <path
        d="M249.546 11.742V0.12915H250.263V10.0216L254.32 0.12915H254.979V11.742H254.261V1.84958L250.219 11.742H249.546Z"
        fill="#C298DC"/>
      <path
        d="M241.039 11.742V0.12915H241.844V5.36211H246.003V0.12915H246.794V11.742H246.003V5.96426H241.844V11.742H241.039Z"
        fill="#C298DC"/>
      <path
        d="M233.332 11.742V0.12915H235.777C236.324 0.12915 236.773 0.205614 237.125 0.358541C237.486 0.50191 237.764 0.707405 237.959 0.975026C238.164 1.24265 238.306 1.55328 238.384 1.90692C238.472 2.25101 238.516 2.62377 238.516 3.0252C238.516 3.33105 238.477 3.6608 238.399 4.01444C238.33 4.35853 238.198 4.66438 238.003 4.93201C237.808 5.19963 237.515 5.38123 237.125 5.47681C237.583 5.61062 237.93 5.84001 238.164 6.16498C238.408 6.48995 238.569 6.85315 238.648 7.25458C238.735 7.64645 238.779 8.01443 238.779 8.35852C238.779 8.79818 238.745 9.21873 238.677 9.62016C238.618 10.0216 238.496 10.3848 238.311 10.7098C238.125 11.0252 237.857 11.2785 237.505 11.4696C237.164 11.6512 236.705 11.742 236.129 11.742H233.332ZM234.123 11.1255H236.026C236.807 11.1255 237.33 10.9009 237.593 10.4517C237.867 10.0025 238.003 9.30953 238.003 8.37286C238.003 7.90452 237.945 7.47919 237.827 7.09687C237.72 6.71456 237.53 6.4087 237.256 6.17931C236.993 5.94992 236.622 5.83523 236.144 5.83523H234.123V11.1255ZM234.123 5.21874H236.129C236.568 5.21874 236.905 5.11838 237.139 4.91767C237.374 4.71695 237.535 4.45411 237.622 4.12914C237.72 3.79461 237.769 3.42663 237.769 3.0252C237.769 2.59509 237.715 2.208 237.608 1.86391C237.5 1.51983 237.291 1.25221 236.978 1.06105C236.676 0.860332 236.212 0.759974 235.587 0.759974H234.123V5.21874Z"
        fill="#C298DC"/>
      <path
        d="M220.017 11.742L220.368 0.12915H221.159L223.663 10.6094L226.211 0.12915H227.031L227.368 11.742H226.679L226.386 2.00728L223.97 11.742H223.385L220.998 2.02162L220.705 11.742H220.017Z"
        fill="#C298DC"/>
      <path
        d="M213.829 11.742V0.12915H217.929V0.759974H214.634V5.41946H217.343V6.02161H214.634V11.1255H217.973V11.742H213.829Z"
        fill="#C298DC"/>
      <path
        d="M205.65 11.742V0.12915H206.368V10.0216L210.424 0.12915H211.083V11.742H210.366V1.84958L206.324 11.742H205.65Z"
        fill="#C298DC"/>
      <path
        d="M197.143 11.742V0.12915H197.948V5.36211H202.107V0.12915H202.897V11.742H202.107V5.96426H197.948V11.742H197.143Z"
        fill="#C298DC"/>
      <path
        d="M190.823 11.742V0.12915H194.923V0.759974H191.629V5.41946H194.338V6.02161H191.629V11.1255H194.967V11.742H190.823Z"
        fill="#C298DC"/>
      <path
        d="M182.316 11.742V0.12915H183.122V5.36211H187.28V0.12915H188.071V11.742H187.28V5.96426H183.122V11.742H182.316Z"
        fill="#C298DC"/>
      <path
        d="M175.996 11.742V0.12915H180.096V0.759974H176.801V5.41946H179.51V6.02161H176.801V11.1255H180.14V11.742H175.996Z"
        fill="#C298DC"/>
      <path
        d="M166.041 11.742L166.392 0.12915H167.183L169.687 10.6094L172.235 0.12915H173.055L173.392 11.742H172.704L172.411 2.00728L169.995 11.742H169.409L167.022 2.02162L166.729 11.742H166.041Z"
        fill="#C298DC"/>
      <path
        d="M157.994 11.742V0.12915H158.712V10.0216L162.768 0.12915H163.427V11.742H162.709V1.84958L158.668 11.742H157.994Z"
        fill="#C298DC"/>
      <path
        d="M150.33 11.742V0.12915H153.112C153.825 0.12915 154.381 0.262961 154.782 0.530583C155.182 0.788647 155.46 1.14229 155.616 1.59151C155.782 2.04073 155.865 2.55208 155.865 3.12556C155.865 3.64169 155.782 4.13392 155.616 4.60226C155.46 5.07059 155.182 5.45291 154.782 5.74921C154.381 6.0455 153.83 6.19365 153.127 6.19365H151.121V11.742H150.33ZM151.121 5.5915H152.951C153.43 5.5915 153.82 5.51026 154.123 5.34777C154.435 5.18529 154.664 4.92723 154.811 4.57358C154.967 4.21038 155.045 3.72771 155.045 3.12556C155.045 2.50429 154.977 2.02162 154.84 1.67753C154.704 1.32389 154.484 1.08016 154.181 0.946353C153.879 0.802984 153.473 0.7313 152.966 0.7313H151.121V5.5915Z"
        fill="#C298DC"/>
      <path d="M141.78 11.742V0.12915H147.564V11.742H146.774V0.7313H142.571V11.742H141.78Z" fill="#C298DC"/>
      <path
        d="M130.241 11.742V0.12915H130.959V10.0216L135.015 0.12915H135.674V11.742H134.956V1.84958L130.915 11.742H130.241Z"
        fill="#C298DC"/>
      <path
        d="M116.941 11.742L117.293 0.12915H118.084L120.588 10.6094L123.135 0.12915H123.955L124.292 11.742H123.604L123.311 2.00728L120.895 11.742H120.309L117.922 2.02162L117.63 11.742H116.941Z"
        fill="#C298DC"/>
      <path
        d="M110.754 11.742V0.12915H114.854V0.759974H111.559V5.41946H114.268V6.02161H111.559V11.1255H114.898V11.742H110.754Z"
        fill="#C298DC"/>
      <path
        d="M102.575 11.742V0.12915H103.293V10.0216L107.349 0.12915H108.008V11.742H107.29V1.84958L103.249 11.742H102.575Z"
        fill="#C298DC"/>
      <path
        d="M94.0674 11.742V0.12915H94.8728V5.36211H99.0314V0.12915H99.8222V11.742H99.0314V5.96426H94.8728V11.742H94.0674Z"
        fill="#C298DC"/>
      <path
        d="M87.7471 11.742V0.12915H91.8472V0.759974H88.5524V5.41946H91.2614V6.02161H88.5524V11.1255H91.8911V11.742H87.7471Z"
        fill="#C298DC"/>
      <path
        d="M84.1907 11.742V6.35136C83.5757 6.5234 82.9948 6.60942 82.4482 6.60942C81.8331 6.60942 81.3011 6.52818 80.852 6.36569C80.4127 6.19365 80.0711 5.92603 79.827 5.56283C79.583 5.19007 79.4609 4.70261 79.4609 4.10047V0.12915H80.2663V4.10047C80.2663 4.62615 80.3688 5.02758 80.5738 5.30476C80.7788 5.58194 81.0522 5.7731 81.3938 5.87824C81.7453 5.98338 82.1358 6.03594 82.5653 6.03594C82.8777 6.03594 83.1705 6.01205 83.4439 5.96426C83.727 5.90691 83.9759 5.84001 84.1907 5.76354V0.12915H84.9961V11.742H84.1907Z"
        fill="#C298DC"/>
      <path
        d="M72.1621 11.742L74.6515 0.12915H75.574L78.078 11.742H77.2726L76.6576 8.53056H73.5825L72.9528 11.742H72.1621ZM73.6996 7.95709H76.5551L75.12 1.07539L73.6996 7.95709Z"
        fill="#C298DC"/>
      <path
        d="M64.2695 11.742V0.12915H65.0749V5.36211H69.2336V0.12915H70.0243V11.742H69.2336V5.96426H65.0749V11.742H64.2695Z"
        fill="#C298DC"/>
      <path
        d="M59.3653 11.8996C58.7698 11.8996 58.2866 11.7849 57.9156 11.5555C57.5544 11.3261 57.2908 11.0107 57.1249 10.6093C56.9589 10.1983 56.8759 9.71562 56.8759 9.16127V8.94621H57.6081C57.6081 8.96533 57.6081 9.01312 57.6081 9.08958C57.6081 9.15649 57.6081 9.1995 57.6081 9.21861C57.6179 9.88767 57.7594 10.399 58.0328 10.7527C58.3061 11.1063 58.7503 11.2831 59.3653 11.2831C59.7363 11.2831 60.0682 11.2114 60.361 11.0681C60.6539 10.9151 60.8833 10.6475 61.0493 10.2652C61.225 9.88289 61.3128 9.34764 61.3128 8.65947C61.3128 7.89484 61.1566 7.26402 60.8443 6.76701C60.5319 6.26999 59.9998 6.01193 59.2481 5.99281C59.2384 5.99281 59.1749 5.99281 59.0578 5.99281C58.9406 5.99281 58.8235 5.99281 58.7063 5.99281C58.5892 5.99281 58.5209 5.99281 58.5013 5.99281V5.41934C58.5209 5.41934 58.5892 5.41934 58.7063 5.41934C58.8235 5.41934 58.9406 5.41934 59.0578 5.41934C59.1749 5.41934 59.2335 5.41934 59.2335 5.41934C59.9949 5.40022 60.5319 5.18517 60.8443 4.77418C61.1566 4.35363 61.3128 3.71325 61.3128 2.85304C61.3128 2.2031 61.1713 1.66786 60.8882 1.24731C60.6148 0.82676 60.1463 0.616486 59.4824 0.616486C58.8577 0.616486 58.3744 0.769413 58.0328 1.07527C57.7008 1.38112 57.53 1.91636 57.5202 2.681C57.5202 2.70011 57.5202 2.73834 57.5202 2.79569C57.5202 2.84348 57.5202 2.87693 57.5202 2.89605H56.7881V2.70967C56.7881 2.16487 56.8711 1.69175 57.037 1.29032C57.2127 0.879329 57.4958 0.563918 57.8863 0.344086C58.2768 0.114695 58.7991 0 59.4531 0C60.0584 0 60.5563 0.129032 60.9468 0.387096C61.3372 0.635602 61.6252 0.974908 61.8107 1.40501C62.0059 1.83512 62.1036 2.32257 62.1036 2.86738C62.1036 3.73715 61.9376 4.4062 61.6057 4.87454C61.2738 5.33332 60.8687 5.6105 60.3903 5.70608C60.6441 5.76343 60.9028 5.89724 61.1664 6.10751C61.43 6.30823 61.6496 6.61408 61.8253 7.02507C62.0108 7.43606 62.1036 7.97608 62.1036 8.64514C62.1036 9.27596 62.0108 9.83988 61.8253 10.3369C61.6496 10.8243 61.3616 11.2067 60.9614 11.4838C60.5612 11.761 60.0291 11.8996 59.3653 11.8996Z"
        fill="#C298DC"/>
      <path
        d="M48.9277 11.742L51.4171 0.12915H52.3396L54.8436 11.742H54.0382L53.4232 8.53056H50.3481L49.7185 11.742H48.9277ZM50.4653 7.95709H53.3207L51.8857 1.07539L50.4653 7.95709Z"
        fill="#C298DC"/>
      <path
        d="M41.0342 11.742V0.12915H41.8396V5.36211H45.9982V0.12915H46.789V11.742H45.9982V5.96426H41.8396V11.742H41.0342Z"
        fill="#C298DC"/>
      <path
        d="M27.9014 13.6632V11.1399H28.326C28.5213 11.0156 28.697 10.8914 28.8532 10.7671C29.0191 10.6333 29.1656 10.4374 29.2925 10.1793C29.4194 9.92124 29.5365 9.55804 29.6439 9.0897C29.7611 8.6118 29.8684 7.97142 29.9661 7.16856C30.0637 6.35613 30.1613 5.32866 30.2589 4.08613L30.5664 0.12915H34.7397V11.1399H35.6037V13.6632H34.9594L34.8715 11.742H28.6335L28.5457 13.6632H27.9014ZM29.2193 11.1399H33.9344V0.7313H31.2986L31.0204 4.2295C30.9227 5.47203 30.8202 6.49472 30.7129 7.29759C30.6152 8.0909 30.5127 8.72172 30.4054 9.19006C30.298 9.6584 30.1808 10.0168 30.0539 10.2653C29.9368 10.5043 29.8099 10.6859 29.6732 10.8101C29.5365 10.9248 29.3852 11.0347 29.2193 11.1399Z"
        fill="#C298DC"/>
      <path
        d="M22.5332 11.742V0.12915H26.6333V0.759974H23.3386V5.41946H26.0476V6.02161H23.3386V11.1255H26.6772V11.742H22.5332Z"
        fill="#C298DC"/>
      <path
        d="M14.8691 11.742V0.12915H17.6513C18.364 0.12915 18.9204 0.262961 19.3207 0.530583C19.7209 0.788647 19.9991 1.14229 20.1553 1.59151C20.3213 2.04073 20.4043 2.55208 20.4043 3.12556C20.4043 3.64169 20.3213 4.13392 20.1553 4.60226C19.9991 5.07059 19.7209 5.45291 19.3207 5.74921C18.9204 6.0455 18.3689 6.19365 17.666 6.19365H15.6599V11.742H14.8691ZM15.6599 5.5915H17.4903C17.9686 5.5915 18.3591 5.51026 18.6617 5.34777C18.9741 5.18529 19.2035 4.92723 19.35 4.57358C19.5062 4.21038 19.5843 3.72771 19.5843 3.12556C19.5843 2.50429 19.5159 2.02162 19.3792 1.67753C19.2426 1.32389 19.0229 1.08016 18.7203 0.946353C18.4177 0.802984 18.0125 0.7313 17.5049 0.7313H15.6599V5.5915Z"
        fill="#C298DC"/>
      <path
        d="M8.53613 11.742V0.12915H12.6362V0.759974H9.34151V5.41946H12.0505V6.02161H9.34151V11.1255H12.6802V11.742H8.53613Z"
        fill="#C298DC"/>
      <path d="M0 11.742V0.12915H5.78406V11.742H4.99333V0.7313H0.790733V11.742H0Z" fill="#C298DC"/>
      <path d="M319.136 27.0787V25.5876H320V27.0787H319.136Z" fill="#C298DC"/>
      <path
        d="M314.714 27.2363C313.953 27.2363 313.362 27.0881 312.942 26.7918C312.522 26.486 312.225 26.0702 312.049 25.5445C311.883 25.0188 311.8 24.4263 311.8 23.7668V21.5445H310.541V27.0786H309.779V15.4657H310.541V20.8994H311.8V18.7202C311.8 18.0511 311.888 17.4633 312.064 16.9567C312.239 16.4502 312.537 16.0535 312.957 15.7668C313.386 15.48 313.972 15.3367 314.714 15.3367C315.456 15.3367 316.037 15.4848 316.457 15.7811C316.876 16.0679 317.174 16.4693 317.35 16.9854C317.526 17.492 317.613 18.075 317.613 18.7345V23.7954C317.613 24.4549 317.53 25.0427 317.364 25.5589C317.199 26.075 316.906 26.486 316.486 26.7918C316.066 27.0881 315.476 27.2363 314.714 27.2363ZM314.729 26.5911C315.295 26.5911 315.729 26.4812 316.032 26.2614C316.344 26.0415 316.559 25.7357 316.676 25.3438C316.803 24.9424 316.867 24.4788 316.867 23.9531V18.5911C316.867 18.0655 316.803 17.6067 316.676 17.2148C316.549 16.8229 316.33 16.5219 316.017 16.3116C315.715 16.0917 315.28 15.9818 314.714 15.9818C314.148 15.9818 313.709 16.0917 313.396 16.3116C313.084 16.5219 312.864 16.8229 312.737 17.2148C312.61 17.6067 312.547 18.0655 312.547 18.5911V23.9531C312.547 24.4788 312.605 24.9424 312.723 25.3438C312.849 25.7357 313.069 26.0415 313.382 26.2614C313.704 26.4812 314.153 26.5911 314.729 26.5911Z"
        fill="#C298DC"/>
      <path
        d="M302.449 27.0789V15.4661H303.167V25.3585L307.223 15.4661H307.882V27.0789H307.164V17.1865L303.123 27.0789H302.449Z"
        fill="#C298DC"/>
      <path
        d="M294.807 27.0789V15.4661H295.612V20.699H299.771V15.4661H300.561V27.0789H299.771V21.3012H295.612V27.0789H294.807Z"
        fill="#C298DC"/>
      <path
        d="M289.351 27.0789V15.4661H293.451V16.0969H290.156V20.7564H292.865V21.3585H290.156V26.4625H293.495V27.0789H289.351Z"
        fill="#C298DC"/>
      <path
        d="M281.707 27.0789V15.4661H282.512V20.699H286.671V15.4661H287.462V27.0789H286.671V21.3012H282.512V27.0789H281.707Z"
        fill="#C298DC"/>
      <path
        d="M276.251 27.0789V15.4661H280.351V16.0969H277.056V20.7564H279.765V21.3585H277.056V26.4625H280.395V27.0789H276.251Z"
        fill="#C298DC"/>
      <path
        d="M267.16 27.0789L267.512 15.4661H268.302L270.806 25.9463L273.354 15.4661H274.174L274.511 27.0789H273.823L273.53 17.3442L271.114 27.0789H270.528L268.141 17.3585L267.848 27.0789H267.16Z"
        fill="#C298DC"/>
      <path
        d="M259.977 27.0789V15.4661H260.694V25.3585L264.75 15.4661H265.409V27.0789H264.692V17.1865L260.65 27.0789H259.977Z"
        fill="#C298DC"/>
      <path
        d="M253.178 27.0789V15.4661H255.96C256.673 15.4661 257.229 15.5999 257.629 15.8675C258.03 16.1256 258.308 16.4792 258.464 16.9284C258.63 17.3776 258.713 17.889 258.713 18.4625C258.713 18.9786 258.63 19.4708 258.464 19.9392C258.308 20.4075 258.03 20.7898 257.629 21.0861C257.229 21.3824 256.677 21.5306 255.975 21.5306H253.968V27.0789H253.178ZM253.968 20.9284H255.799C256.277 20.9284 256.668 20.8472 256.97 20.6847C257.283 20.5222 257.512 20.2641 257.659 19.9105C257.815 19.5473 257.893 19.0646 257.893 18.4625C257.893 17.8412 257.824 17.3585 257.688 17.0144C257.551 16.6608 257.332 16.4171 257.029 16.2833C256.726 16.1399 256.321 16.0682 255.814 16.0682H253.968V20.9284Z"
        fill="#C298DC"/>
      <path d="M245.491 27.0789V15.4661H251.275V27.0789H250.485V16.0682H246.282V27.0789H245.491Z" fill="#C298DC"/>
      <path
        d="M236.477 28.4123L236.492 27.7958C236.97 27.8053 237.346 27.7767 237.62 27.7098C237.903 27.6524 238.113 27.5377 238.249 27.3657C238.386 27.2032 238.484 26.9738 238.542 26.6775C238.61 26.3717 238.669 25.9846 238.718 25.5162L236.067 15.4661H236.858L238.205 20.8281L239.113 24.5987L239.904 20.7994L241.09 15.4661H241.895L239.538 25.5879C239.44 26.0371 239.347 26.429 239.26 26.7635C239.172 27.0885 239.069 27.3561 238.952 27.5664C238.835 27.7862 238.679 27.9583 238.484 28.0825C238.288 28.2163 238.03 28.3071 237.707 28.3549C237.385 28.4027 236.975 28.4218 236.477 28.4123Z"
        fill="#C298DC"/>
      <path
        d="M227.576 27.0789L227.928 15.4661H228.718L231.222 25.9463L233.77 15.4661H234.59L234.927 27.0789H234.239L233.946 17.3442L231.53 27.0789H230.944L228.557 17.3585L228.264 27.0789H227.576Z"
        fill="#C298DC"/>
      <path
        d="M223.144 27.2363C222.373 27.2363 221.773 27.0881 221.343 26.7918C220.924 26.486 220.631 26.075 220.465 25.5589C220.299 25.0332 220.216 24.4406 220.216 23.7811V18.7058C220.216 18.0463 220.304 17.4633 220.479 16.9567C220.655 16.4502 220.953 16.0535 221.373 15.7668C221.802 15.48 222.393 15.3367 223.144 15.3367C223.896 15.3367 224.482 15.48 224.902 15.7668C225.331 16.0535 225.634 16.4502 225.81 16.9567C225.985 17.4633 226.073 18.0463 226.073 18.7058V23.7954C226.073 24.4549 225.99 25.0427 225.824 25.5589C225.658 26.075 225.36 26.486 224.931 26.7918C224.511 27.0881 223.916 27.2363 223.144 27.2363ZM223.144 26.5911C223.711 26.5911 224.145 26.4812 224.448 26.2614C224.76 26.0415 224.975 25.7357 225.092 25.3438C225.209 24.9424 225.268 24.4788 225.268 23.9531V18.5911C225.268 18.0655 225.209 17.6067 225.092 17.2148C224.975 16.8229 224.765 16.5219 224.462 16.3116C224.16 16.0917 223.72 15.9818 223.144 15.9818C222.578 15.9818 222.139 16.0917 221.827 16.3116C221.524 16.5219 221.314 16.8229 221.197 17.2148C221.08 17.6067 221.021 18.0655 221.021 18.5911V23.9531C221.021 24.4788 221.08 24.9424 221.197 25.3438C221.314 25.7357 221.524 26.0415 221.827 26.2614C222.139 26.4812 222.578 26.5911 223.144 26.5911Z"
        fill="#C298DC"/>
      <path
        d="M213.88 27.0789V15.4661H214.685V20.9141H215.666L218.273 15.4661H219.093L216.369 21.1005L219.532 27.0789H218.727L215.666 21.3729H214.685V27.0789H213.88Z"
        fill="#C298DC"/>
      <path
        d="M209.658 27.2363C208.877 27.2363 208.277 27.0786 207.857 26.7632C207.447 26.4478 207.164 26.0272 207.008 25.5015C206.852 24.9663 206.773 24.3785 206.773 23.7381V18.8062C206.773 18.1371 206.856 17.5445 207.022 17.0284C207.188 16.5027 207.481 16.0917 207.901 15.7955C208.321 15.4896 208.906 15.3367 209.658 15.3367C210.351 15.3367 210.893 15.4705 211.284 15.7381C211.674 16.0057 211.952 16.388 212.118 16.8851C212.284 17.3725 212.367 17.946 212.367 18.6055V19.1933H211.606V18.6055C211.606 18.0511 211.552 17.5828 211.445 17.2005C211.337 16.8086 211.142 16.5075 210.859 16.2972C210.576 16.087 210.176 15.9818 209.658 15.9818C209.092 15.9818 208.658 16.0965 208.355 16.3259C208.052 16.5553 207.847 16.8755 207.74 17.2865C207.633 17.6975 207.579 18.1754 207.579 18.7202V23.8528C207.579 24.4263 207.637 24.9185 207.755 25.3295C207.872 25.7309 208.082 26.0415 208.384 26.2614C208.687 26.4812 209.111 26.5911 209.658 26.5911C210.185 26.5911 210.59 26.4812 210.874 26.2614C211.157 26.0415 211.347 25.7357 211.445 25.3438C211.552 24.9424 211.606 24.4788 211.606 23.9531V23.2506H212.367V23.8241C212.367 24.4836 212.289 25.0714 212.133 25.5875C211.977 26.1037 211.703 26.5099 211.313 26.8062C210.922 27.0929 210.371 27.2363 209.658 27.2363Z"
        fill="#C298DC"/>
      <path
        d="M199.364 27.0789V15.4661H200.17V20.699H204.328V15.4661H205.119V27.0789H204.328V21.3012H200.17V27.0789H199.364Z"
        fill="#C298DC"/>
      <path
        d="M192.049 27.0789V15.4661H192.766V25.3585L196.823 15.4661H197.481V27.0789H196.764V17.1865L192.722 27.0789H192.049Z"
        fill="#C298DC"/>
      <path
        d="M190.223 29.0001L190.136 27.0789H184.22V15.4661H185.025V26.4768H189.242V15.4661H190.048V26.4768H190.868V29.0001H190.223Z"
        fill="#C298DC"/>
      <path
        d="M176.905 27.0789V15.4661H177.623V25.3585L181.679 15.4661H182.338V27.0789H181.62V17.1865L177.579 27.0789H176.905Z"
        fill="#C298DC"/>
      <path
        d="M167.98 29.0001V26.4768H168.405C168.6 26.3525 168.776 26.2283 168.932 26.104C169.098 25.9702 169.245 25.7743 169.372 25.5162C169.498 25.2582 169.616 24.895 169.723 24.4266C169.84 23.9487 169.948 23.3083 170.045 22.5055C170.143 21.693 170.24 20.6656 170.338 19.423L170.646 15.4661H174.819V26.4768H175.683V29.0001H175.038L174.951 27.0789H168.713L168.625 29.0001H167.98ZM169.298 26.4768H174.013V16.0682H171.378L171.099 19.5664C171.002 20.8089 170.899 21.8316 170.792 22.6345C170.694 23.4278 170.592 24.0586 170.484 24.527C170.377 24.9953 170.26 25.3537 170.133 25.6022C170.016 25.8412 169.889 26.0228 169.752 26.147C169.616 26.2617 169.464 26.3716 169.298 26.4768Z"
        fill="#C298DC"/>
      <path
        d="M163.477 27.0789V15.4661H167.577V16.0969H164.282V20.7564H166.991V21.3585H164.282V26.4625H167.621V27.0789H163.477Z"
        fill="#C298DC"/>
      <path
        d="M154.386 27.0789L154.737 15.4661H155.528L158.032 25.9463L160.58 15.4661H161.4L161.737 27.0789H161.048L160.756 17.3442L158.339 27.0789H157.754L155.367 17.3585L155.074 27.0789H154.386Z"
        fill="#C298DC"/>
      <path
        d="M147.474 27.2363C146.702 27.2363 146.102 27.0881 145.672 26.7918C145.253 26.486 144.96 26.075 144.794 25.5589C144.628 25.0332 144.545 24.4406 144.545 23.7811V18.7058C144.545 18.0463 144.633 17.4633 144.809 16.9567C144.984 16.4502 145.282 16.0535 145.702 15.7668C146.131 15.48 146.722 15.3367 147.474 15.3367C148.225 15.3367 148.811 15.48 149.231 15.7668C149.66 16.0535 149.963 16.4502 150.139 16.9567C150.314 17.4633 150.402 18.0463 150.402 18.7058V23.7954C150.402 24.4549 150.319 25.0427 150.153 25.5589C149.987 26.075 149.69 26.486 149.26 26.7918C148.84 27.0881 148.245 27.2363 147.474 27.2363ZM147.474 26.5911C148.04 26.5911 148.474 26.4812 148.777 26.2614C149.089 26.0415 149.304 25.7357 149.421 25.3438C149.538 24.9424 149.597 24.4788 149.597 23.9531V18.5911C149.597 18.0655 149.538 17.6067 149.421 17.2148C149.304 16.8229 149.094 16.5219 148.791 16.3116C148.489 16.0917 148.05 15.9818 147.474 15.9818C146.907 15.9818 146.468 16.0917 146.156 16.3116C145.853 16.5219 145.643 16.8229 145.526 17.2148C145.409 17.6067 145.35 18.0655 145.35 18.5911V23.9531C145.35 24.4788 145.409 24.9424 145.526 25.3438C145.643 25.7357 145.853 26.0415 146.156 26.2614C146.468 26.4812 146.907 26.5911 147.474 26.5911Z"
        fill="#C298DC"/>
      <path d="M137.106 27.0789V15.4661H142.891V27.0789H142.1V16.0682H137.897V27.0789H137.106Z" fill="#C298DC"/>
      <path
        d="M130.072 27.2363C129.311 27.2363 128.72 27.0881 128.301 26.7918C127.881 26.486 127.583 26.0702 127.407 25.5445C127.241 25.0188 127.158 24.4263 127.158 23.7668V21.5445H125.899V27.0786H125.138V15.4657H125.899V20.8994H127.158V18.7202C127.158 18.0511 127.246 17.4633 127.422 16.9567C127.598 16.4502 127.895 16.0535 128.315 15.7668C128.745 15.48 129.331 15.3367 130.072 15.3367C130.814 15.3367 131.395 15.4848 131.815 15.7811C132.235 16.0679 132.533 16.4693 132.708 16.9854C132.884 17.492 132.972 18.075 132.972 18.7345V23.7954C132.972 24.4549 132.889 25.0427 132.723 25.5589C132.557 26.075 132.264 26.486 131.844 26.7918C131.425 27.0881 130.834 27.2363 130.072 27.2363ZM130.087 26.5911C130.653 26.5911 131.088 26.4812 131.39 26.2614C131.703 26.0415 131.917 25.7357 132.035 25.3438C132.162 24.9424 132.225 24.4788 132.225 23.9531V18.5911C132.225 18.0655 132.162 17.6067 132.035 17.2148C131.908 16.8229 131.688 16.5219 131.376 16.3116C131.073 16.0917 130.639 15.9818 130.072 15.9818C129.506 15.9818 129.067 16.0917 128.755 16.3116C128.442 16.5219 128.223 16.8229 128.096 17.2148C127.969 17.6067 127.905 18.0655 127.905 18.5911V23.9531C127.905 24.4788 127.964 24.9424 128.081 25.3438C128.208 25.7357 128.428 26.0415 128.74 26.2614C129.062 26.4812 129.511 26.5911 130.087 26.5911Z"
        fill="#C298DC"/>
      <path
        d="M117.808 27.0789V15.4661H118.525V25.3585L122.581 15.4661H123.24V27.0789H122.523V17.1865L118.481 27.0789H117.808Z"
        fill="#C298DC"/>
      <path
        d="M115.982 29.0001L115.894 27.0789H109.979V15.4661H110.784V26.4768H115.001V15.4661H115.807V26.4768H116.627V29.0001H115.982Z"
        fill="#C298DC"/>
      <path
        d="M103.407 27.0789V15.4661H104.213V20.9141H105.194L107.8 15.4661H108.62L105.897 21.1005L109.059 27.0789H108.254L105.194 21.3729H104.213V27.0789H103.407Z"
        fill="#C298DC"/>
      <path
        d="M96.8885 28.4123L96.9032 27.7958C97.3815 27.8053 97.7573 27.7767 98.0307 27.7098C98.3138 27.6524 98.5237 27.5377 98.6603 27.3657C98.797 27.2032 98.8946 26.9738 98.9532 26.6775C99.0216 26.3717 99.0801 25.9846 99.1289 25.5162L96.4785 15.4661H97.2692L98.6164 20.8281L99.5243 24.5987L100.315 20.7994L101.501 15.4661H102.307L99.949 25.5879C99.8513 26.0371 99.7586 26.429 99.6707 26.7635C99.5829 27.0885 99.4804 27.3561 99.3632 27.5664C99.2461 27.7862 99.0899 27.9583 98.8946 28.0825C98.6994 28.2163 98.4407 28.3071 98.1185 28.3549C97.7964 28.4027 97.3864 28.4218 96.8885 28.4123Z"
        fill="#C298DC"/>
      <path
        d="M90.2793 27.0789V15.4661H93.0615C93.7741 15.4661 94.3306 15.5999 94.7308 15.8675C95.1311 16.1256 95.4093 16.4792 95.5655 16.9284C95.7314 17.3776 95.8144 17.889 95.8144 18.4625C95.8144 18.9786 95.7314 19.4708 95.5655 19.9392C95.4093 20.4075 95.1311 20.7898 94.7308 21.0861C94.3306 21.3824 93.779 21.5306 93.0761 21.5306H91.07V27.0789H90.2793ZM91.07 20.9284H92.9004C93.3788 20.9284 93.7693 20.8472 94.0719 20.6847C94.3843 20.5222 94.6137 20.2641 94.7601 19.9105C94.9163 19.5473 94.9944 19.0646 94.9944 18.4625C94.9944 17.8412 94.9261 17.3585 94.7894 17.0144C94.6527 16.6608 94.4331 16.4171 94.1305 16.2833C93.8278 16.1399 93.4227 16.0682 92.9151 16.0682H91.07V20.9284Z"
        fill="#C298DC"/>
      <path d="M86.0763 27.0789V16.0969H83.7041V15.4661H89.1807V16.0969H86.867V27.0789H86.0763Z" fill="#C298DC"/>
      <path
        d="M80.2734 27.2363C79.4924 27.2363 78.892 27.0786 78.4723 26.7632C78.0623 26.4478 77.7792 26.0272 77.623 25.5015C77.4668 24.9663 77.3887 24.3785 77.3887 23.7381V18.8062C77.3887 18.1371 77.4717 17.5445 77.6376 17.0284C77.8036 16.5027 78.0964 16.0917 78.5162 15.7955C78.936 15.4896 79.5217 15.3367 80.2734 15.3367C80.9665 15.3367 81.5083 15.4705 81.8988 15.7381C82.2893 16.0057 82.5675 16.388 82.7334 16.8851C82.8994 17.3725 82.9824 17.946 82.9824 18.6055V19.1933H82.2209V18.6055C82.2209 18.0511 82.1672 17.5828 82.0599 17.2005C81.9525 16.8086 81.7572 16.5075 81.4741 16.2972C81.191 16.087 80.7908 15.9818 80.2734 15.9818C79.7072 15.9818 79.2728 16.0965 78.9701 16.3259C78.6675 16.5553 78.4625 16.8755 78.3551 17.2865C78.2477 17.6975 78.194 18.1754 78.194 18.7202V23.8528C78.194 24.4263 78.2526 24.9185 78.3698 25.3295C78.4869 25.7309 78.6968 26.0415 78.9994 26.2614C79.3021 26.4812 79.7267 26.5911 80.2734 26.5911C80.8005 26.5911 81.2057 26.4812 81.4888 26.2614C81.7719 26.0415 81.9622 25.7357 82.0599 25.3438C82.1672 24.9424 82.2209 24.4788 82.2209 23.9531V23.2506H82.9824V23.8241C82.9824 24.4836 82.9043 25.0714 82.7481 25.5875C82.5919 26.1037 82.3185 26.5099 81.9281 26.8062C81.5376 27.0929 80.986 27.2363 80.2734 27.2363Z"
        fill="#C298DC"/>
      <path
        d="M69.9795 27.0789V15.4661H70.7849V20.699H74.9435V15.4661H75.7343V27.0789H74.9435V21.3012H70.7849V27.0789H69.9795Z"
        fill="#C298DC"/>
      <path
        d="M62.6641 27.0789V15.4661H63.3816V25.3585L67.4377 15.4661H68.0967V27.0789H67.3792V17.1865L63.3376 27.0789H62.6641Z"
        fill="#C298DC"/>
      <path
        d="M53.7412 27.0789V15.4661H54.5319V20.3693H55.8645C56.5966 20.3693 57.197 20.4983 57.6656 20.7564C58.1342 21.0049 58.4807 21.3824 58.7053 21.889C58.9298 22.3956 59.042 23.0312 59.042 23.7958C59.042 24.2737 58.9835 24.7134 58.8663 25.1148C58.7589 25.5162 58.5783 25.8651 58.3245 26.1614C58.0805 26.4481 57.7583 26.6727 57.3581 26.8352C56.9578 26.9977 56.4697 27.0789 55.8938 27.0789H53.7412ZM54.5319 26.4481H55.923C56.499 26.4481 56.953 26.3478 57.2849 26.147C57.6265 25.9368 57.8706 25.6357 58.017 25.2438C58.1635 24.8424 58.2367 24.3549 58.2367 23.7815C58.2367 22.9117 58.0658 22.2331 57.7242 21.7456C57.3825 21.2486 56.7772 21.0001 55.9084 21.0001H54.5319V26.4481Z"
        fill="#C298DC"/>
      <path d="M49.5382 27.0789V16.0969H47.166V15.4661H52.6426V16.0969H50.3289V27.0789H49.5382Z" fill="#C298DC"/>
      <path
        d="M41.3135 27.0789L43.8028 15.4661H44.7253L47.2293 27.0789H46.424L45.8089 23.8675H42.7339L42.1042 27.0789H41.3135ZM42.851 23.294H45.7064L44.2714 16.4123L42.851 23.294Z"
        fill="#C298DC"/>
      <path d="M37.7404 27.0789V16.0969H35.3682V15.4661H40.8447V16.0969H38.5311V27.0789H37.7404Z" fill="#C298DC"/>
      <path
        d="M28.8438 27.0789V15.4661H29.5613V25.3585L33.6174 15.4661H34.2764V27.0789H33.5589V17.1865L29.5173 27.0789H28.8438Z"
        fill="#C298DC"/>
      <path
        d="M26.1507 27.0789V21.6883C25.5356 21.8603 24.9548 21.9463 24.4081 21.9463C23.7931 21.9463 23.2611 21.8651 22.812 21.7026C22.3727 21.5306 22.031 21.2629 21.787 20.8997C21.5429 20.527 21.4209 20.0395 21.4209 19.4374V15.4661H22.2263V19.4374C22.2263 19.9631 22.3288 20.3645 22.5338 20.6417C22.7388 20.9189 23.0121 21.11 23.3538 21.2152C23.7052 21.3203 24.0957 21.3729 24.5253 21.3729C24.8376 21.3729 25.1305 21.349 25.4038 21.3012C25.687 21.2438 25.9359 21.1769 26.1507 21.1005V15.4661H26.956V27.0789H26.1507Z"
        fill="#C298DC"/>
      <path
        d="M17.1806 27.2363C16.4094 27.2363 15.809 27.0881 15.3795 26.7918C14.9597 26.486 14.6668 26.075 14.5009 25.5589C14.3349 25.0332 14.252 24.4406 14.252 23.7811V18.7058C14.252 18.0463 14.3398 17.4633 14.5155 16.9567C14.6912 16.4502 14.989 16.0535 15.4088 15.7668C15.8383 15.48 16.4289 15.3367 17.1806 15.3367C17.9323 15.3367 18.518 15.48 18.9378 15.7668C19.3673 16.0535 19.6699 16.4502 19.8457 16.9567C20.0214 17.4633 20.1092 18.0463 20.1092 18.7058V23.7954C20.1092 24.4549 20.0263 25.0427 19.8603 25.5589C19.6943 26.075 19.3966 26.486 18.9671 26.7918C18.5473 27.0881 17.9518 27.2363 17.1806 27.2363ZM17.1806 26.5911C17.7468 26.5911 18.1812 26.4812 18.4838 26.2614C18.7962 26.0415 19.011 25.7357 19.1281 25.3438C19.2453 24.9424 19.3039 24.4788 19.3039 23.9531V18.5911C19.3039 18.0655 19.2453 17.6067 19.1281 17.2148C19.011 16.8229 18.8011 16.5219 18.4985 16.3116C18.1959 16.0917 17.7566 15.9818 17.1806 15.9818C16.6144 15.9818 16.1751 16.0917 15.8627 16.3116C15.5601 16.5219 15.3502 16.8229 15.233 17.2148C15.1159 17.6067 15.0573 18.0655 15.0573 18.5911V23.9531C15.0573 24.4788 15.1159 24.9424 15.233 25.3438C15.3502 25.7357 15.5601 26.0415 15.8627 26.2614C16.1751 26.4812 16.6144 26.5911 17.1806 26.5911Z"
        fill="#C298DC"/>
      <path
        d="M7.68555 27.0789V15.4661H10.4678C11.1804 15.4661 11.7368 15.5999 12.1371 15.8675C12.5373 16.1256 12.8155 16.4792 12.9717 16.9284C13.1377 17.3776 13.2207 17.889 13.2207 18.4625C13.2207 18.9786 13.1377 19.4708 12.9717 19.9392C12.8155 20.4075 12.5373 20.7898 12.1371 21.0861C11.7368 21.3824 11.1853 21.5306 10.4824 21.5306H8.47628V27.0789H7.68555ZM8.47628 20.9284H10.3067C10.785 20.9284 11.1755 20.8472 11.4781 20.6847C11.7905 20.5222 12.0199 20.2641 12.1664 19.9105C12.3226 19.5473 12.4007 19.0646 12.4007 18.4625C12.4007 17.8412 12.3323 17.3585 12.1957 17.0144C12.059 16.6608 11.8393 16.4171 11.5367 16.2833C11.2341 16.1399 10.829 16.0682 10.3213 16.0682H8.47628V20.9284Z"
        fill="#C298DC"/>
      <path d="M0 27.0789V15.4661H5.78406V27.0789H4.99333V16.0682H0.790733V27.0789H0Z" fill="#C298DC"/>
      <path
        d="M308.735 62.0074V31.3408H310.416V44.2889H313.247C314.803 44.2889 316.079 44.6297 317.074 45.3112C318.07 45.9674 318.806 46.9644 319.283 48.3021C319.761 49.6398 319.999 51.3183 319.999 53.3375C319.999 54.5995 319.875 55.7605 319.626 56.8206C319.398 57.8807 319.014 58.802 318.475 59.5844C317.956 60.3416 317.271 60.9347 316.421 61.3638C315.57 61.7929 314.533 62.0074 313.309 62.0074H308.735ZM310.416 60.3416H313.372C314.595 60.3416 315.56 60.0766 316.265 59.5465C316.991 58.9913 317.51 58.1962 317.821 57.1613C318.132 56.1013 318.288 54.814 318.288 53.2996C318.288 51.0028 317.925 49.2107 317.199 47.9235C316.473 46.611 315.187 45.9548 313.34 45.9548H310.416V60.3416Z"
        fill="#C298DC"/>
      <path d="M299.182 62.0074V33.0067H294.142V31.3408H305.779V33.0067H300.863V62.0074H299.182Z" fill="#C298DC"/>
      <path
        d="M275.007 62.0074V31.3408H276.718V44.2889H279.55C281.105 44.2889 282.381 44.6297 283.377 45.3112C284.373 45.9674 285.109 46.9644 285.586 48.3021C286.063 49.6398 286.302 51.3183 286.302 53.3375C286.302 54.5995 286.177 55.7605 285.928 56.8206C285.7 57.8807 285.316 58.802 284.777 59.5844C284.258 60.3416 283.574 60.9347 282.723 61.3638C281.873 61.7929 280.836 62.0074 279.612 62.0074H275.007ZM276.718 60.3416H279.643C280.867 60.3416 281.831 60.0766 282.537 59.5465C283.263 58.9913 283.781 58.1962 284.093 57.1613C284.404 56.1013 284.559 54.814 284.559 53.2996C284.559 51.0028 284.196 49.2107 283.47 47.9235C282.765 46.611 281.489 45.9548 279.643 45.9548H276.718V60.3416ZM289.538 62.0074V31.3408H291.218V62.0074H289.538Z"
        fill="#C298DC"/>
      <path
        d="M260.391 62.0074V31.3408H270.099V32.9309H262.102V44.2889H264.871C266.427 44.2889 267.703 44.6297 268.698 45.3112C269.694 45.9674 270.431 46.9644 270.908 48.3021C271.385 49.6398 271.623 51.3183 271.623 53.3375C271.623 54.5995 271.499 55.7605 271.25 56.8206C271.022 57.8807 270.638 58.802 270.099 59.5844C269.58 60.3416 268.896 60.9347 268.045 61.3638C267.195 61.7929 266.157 62.0074 264.933 62.0074H260.391ZM262.102 60.3416H264.965C266.188 60.3416 267.153 60.0766 267.858 59.5465C268.584 58.9913 269.103 58.1962 269.414 57.1613C269.725 56.1013 269.881 54.814 269.881 53.2996C269.881 51.0028 269.518 49.2107 268.792 47.9235C268.087 46.611 266.811 45.9548 264.965 45.9548H262.102V60.3416Z"
        fill="#C298DC"/>
      <path d="M244.972 62.0074V33.0067H239.932V31.3408H251.569V33.0067H246.653V62.0074H244.972Z" fill="#C298DC"/>
      <path
        d="M229.396 62.0074V31.3408H238.109V33.0067H231.108V45.3112H236.864V46.9013H231.108V60.3795H238.202V62.0074H229.396Z"
        fill="#C298DC"/>
      <path
        d="M208.635 62.0074L215.107 45.9926L209.226 31.3408H210.875L216.538 45.8791H216.756V31.3408H218.312V45.8791H218.529L224.193 31.3408H225.842L219.961 45.9926L226.433 62.0074H224.753L218.623 46.4091H218.312V62.0074H216.756V46.4091H216.414L210.315 62.0074H208.635Z"
        fill="#C298DC"/>
      <path
        d="M199.965 62.4238C198.327 62.4238 197.051 62.0326 196.138 61.2502C195.246 60.4425 194.624 59.3572 194.271 57.9942C193.918 56.606 193.742 55.0411 193.742 53.2995V39.8971C193.742 38.1555 193.929 36.6159 194.302 35.2782C194.676 33.9405 195.308 32.893 196.2 32.1358C197.113 31.3786 198.368 31 199.965 31C201.563 31 202.807 31.3786 203.699 32.1358C204.612 32.893 205.255 33.9405 205.628 35.2782C206.002 36.6159 206.188 38.1555 206.188 39.8971V53.3374C206.188 55.079 206.012 56.6312 205.659 57.9942C205.307 59.3572 204.674 60.4425 203.761 61.2502C202.869 62.0326 201.604 62.4238 199.965 62.4238ZM199.965 60.7201C201.168 60.7201 202.091 60.4299 202.735 59.8493C203.398 59.2688 203.855 58.4611 204.104 57.4263C204.353 56.3662 204.477 55.1421 204.477 53.7539V39.5942C204.477 38.206 204.353 36.9945 204.104 35.9597C203.855 34.9248 203.409 34.1298 202.766 33.5745C202.123 32.994 201.189 32.7037 199.965 32.7037C198.762 32.7037 197.829 32.994 197.165 33.5745C196.522 34.1298 196.076 34.9248 195.827 35.9597C195.578 36.9945 195.454 38.206 195.454 39.5942V53.7539C195.454 55.1421 195.578 56.3662 195.827 57.4263C196.076 58.4611 196.522 59.2688 197.165 59.8493C197.829 60.4299 198.762 60.7201 199.965 60.7201Z"
        fill="#C298DC"/>
      <path
        d="M174.3 62.0074L175.047 31.3408H176.727L182.048 59.0165L187.462 31.3408H189.204L189.92 62.0074H188.457L187.835 36.3005L182.701 62.0074H181.456L176.385 36.3383L175.762 62.0074H174.3Z"
        fill="#C298DC"/>
      <path
        d="M156.47 62.0074V31.3408H165.182V33.0067H158.181V45.3112H163.937V46.9013H158.181V60.3795H165.275V62.0074H156.47Z"
        fill="#C298DC"/>
      <path
        d="M140.304 62.0074V31.3408H141.828V57.4642L150.447 31.3408H151.848V62.0074H150.323V35.884L141.735 62.0074H140.304Z"
        fill="#C298DC"/>
      <path
        d="M123.44 62.0074V31.3408H125.152V45.1597H133.989V31.3408H135.669V62.0074H133.989V46.7498H125.152V62.0074H123.44Z"
        fill="#C298DC"/>
      <path
        d="M111.225 62.0074V31.3408H119.937V33.0067H112.936V45.3112H118.692V46.9013H112.936V60.3795H120.03V62.0074H111.225Z"
        fill="#C298DC"/>
      <path
        d="M104.881 62.0074V47.7721C103.575 48.2264 102.34 48.4535 101.179 48.4535C99.8718 48.4535 98.7412 48.239 97.787 47.8099C96.8536 47.3556 96.1275 46.6489 95.6089 45.6898C95.0904 44.7054 94.8311 43.4182 94.8311 41.828V31.3408H96.5424V41.828C96.5424 43.2162 96.7602 44.2763 97.1958 45.0083C97.6314 45.7402 98.2123 46.245 98.9383 46.5227C99.6851 46.8003 100.515 46.9391 101.428 46.9391C102.091 46.9391 102.714 46.876 103.294 46.7498C103.896 46.5984 104.425 46.4217 104.881 46.2198V31.3408H106.593V62.0074H104.881Z"
        fill="#C298DC"/>
      <path
        d="M83.3008 62.0074V31.3408H92.0131V33.0067H85.0121V45.3112H90.7685V46.9013H85.0121V60.3795H92.1065V62.0074H83.3008Z"
        fill="#C298DC"/>
      <path
        d="M64.4551 62.0831V60.4552C65.1189 60.4047 65.6686 60.3037 66.1042 60.1523C66.5398 60.0008 66.8925 59.6475 67.1621 59.0922C67.4318 58.5369 67.66 57.6409 67.8467 56.4041C68.0334 55.1674 68.2097 53.4511 68.3756 51.2552C68.5623 49.0593 68.7698 46.2324 68.9979 42.7745L69.6825 31.3408H78.706V62.0074H76.9946V32.9309H71.2694L70.6471 42.7745C70.4189 46.2324 70.2114 49.1224 70.0247 51.4445C69.8381 53.7413 69.6202 55.5965 69.3713 57.0099C69.1431 58.4233 68.832 59.496 68.4379 60.228C68.0645 60.9347 67.5563 61.4143 66.9132 61.6667C66.2701 61.9191 65.4508 62.0579 64.4551 62.0831Z"
        fill="#C298DC"/>
      <path
        d="M55.6606 62.4238C54.0218 62.4238 52.7461 62.0326 51.8334 61.2502C50.9414 60.4425 50.3191 59.3572 49.9665 57.9942C49.6138 56.606 49.4375 55.0411 49.4375 53.2995V39.8971C49.4375 38.1555 49.6242 36.6159 49.9976 35.2782C50.371 33.9405 51.0036 32.893 51.8956 32.1358C52.8083 31.3786 54.0633 31 55.6606 31C57.2579 31 58.5025 31.3786 59.3944 32.1358C60.3072 32.893 60.9502 33.9405 61.3236 35.2782C61.697 36.6159 61.8837 38.1555 61.8837 39.8971V53.3374C61.8837 55.079 61.7074 56.6312 61.3547 57.9942C61.0021 59.3572 60.3694 60.4425 59.4567 61.2502C58.5647 62.0326 57.2993 62.4238 55.6606 62.4238ZM55.6606 60.7201C56.8637 60.7201 57.7868 60.4299 58.4299 59.8493C59.0937 59.2688 59.55 58.4611 59.799 57.4263C60.0479 56.3662 60.1723 55.1421 60.1723 53.7539V39.5942C60.1723 38.206 60.0479 36.9945 59.799 35.9597C59.55 34.9248 59.104 34.1298 58.461 33.5745C57.8179 32.994 56.8845 32.7037 55.6606 32.7037C54.4575 32.7037 53.524 32.994 52.8602 33.5745C52.2171 34.1298 51.7712 34.9248 51.5222 35.9597C51.2733 36.9945 51.1489 38.206 51.1489 39.5942V53.7539C51.1489 55.1421 51.2733 56.3662 51.5222 57.4263C51.7712 58.4611 52.2171 59.2688 52.8602 59.8493C53.524 60.4299 54.4575 60.7201 55.6606 60.7201Z"
        fill="#C298DC"/>
      <path
        d="M29.9961 62.0074L30.7429 31.3408H32.4231L37.7438 59.0165L43.1579 31.3408H44.9004L45.6161 62.0074H44.1536L43.5313 36.3005L38.3973 62.0074H37.1526L32.0808 36.3383L31.4585 62.0074H29.9961Z"
        fill="#C298DC"/>
      <path
        d="M14.4141 62.0074L19.7037 31.3408H21.664L26.9847 62.0074H25.2734L23.9665 53.5268H17.4323L16.0943 62.0074H14.4141ZM17.6812 52.0124H23.7487L20.6994 33.8396L17.6812 52.0124Z"
        fill="#C298DC"/>
      <path
        d="M6.12975 62.4238C4.47025 62.4238 3.19452 62.0074 2.30254 61.1744C1.43131 60.3415 0.829745 59.231 0.497847 57.8428C0.165949 56.4293 0 54.877 0 53.186V40.1621C0 38.3953 0.176321 36.8304 0.528963 35.4675C0.881605 34.0793 1.50391 32.994 2.39589 32.2115C3.28787 31.4038 4.53249 31 6.12975 31C7.60254 31 8.75382 31.3534 9.58356 32.0601C10.4133 32.7668 11.0045 33.7764 11.3571 35.0889C11.7098 36.3761 11.8861 37.8905 11.8861 39.6321V41.1843H10.2681V39.6321C10.2681 38.1682 10.154 36.9314 9.92583 35.9218C9.69765 34.887 9.28278 34.0919 8.68121 33.5366C8.07965 32.9813 7.22916 32.7037 6.12975 32.7037C4.92662 32.7037 4.00352 33.0066 3.36047 33.6123C2.71742 34.2181 2.2818 35.0636 2.05362 36.149C1.82544 37.2343 1.71135 38.4963 1.71135 39.935V53.4888C1.71135 55.0032 1.83581 56.3031 2.08474 57.3884C2.33366 58.4485 2.77965 59.2688 3.4227 59.8493C4.06575 60.4299 4.9681 60.7201 6.12975 60.7201C7.2499 60.7201 8.11076 60.4299 8.71233 59.8493C9.31389 59.2688 9.7184 58.4611 9.92583 57.4263C10.154 56.3662 10.2681 55.1421 10.2681 53.7539V51.8987H11.8861V53.4131C11.8861 55.1547 11.7202 56.707 11.3883 58.0699C11.0564 59.4329 10.4755 60.5056 9.64579 61.288C8.81605 62.0452 7.64403 62.4238 6.12975 62.4238Z"
        fill="#C298DC"/>
      <path
        d="M308.234 97.0074L312.617 83.0371C311.51 82.4566 310.711 81.4091 310.221 79.8947C309.731 78.3551 309.486 76.5756 309.486 74.5564C309.486 73.0925 309.65 71.7422 309.976 70.5054C310.321 69.2687 310.902 68.2717 311.718 67.5145C312.535 66.732 313.669 66.3408 315.121 66.3408H319.939V97.0074H318.414V83.6428H315.039C314.876 83.6428 314.685 83.6428 314.468 83.6428C314.268 83.6176 314.096 83.5924 313.95 83.5671L309.895 97.0074H308.234ZM315.23 82.0527H318.414V67.9688H315.175C313.833 67.9688 312.789 68.4736 312.045 69.4832C311.319 70.4928 310.956 72.1839 310.956 74.5564C310.956 77.03 311.283 78.8977 311.936 80.1597C312.59 81.4217 313.687 82.0527 315.23 82.0527Z"
        fill="#C298DC"/>
      <path
        d="M298.191 97.0074V66.3408H299.661V79.2889H302.138C303.499 79.2889 304.615 79.6297 305.486 80.3112C306.357 80.9674 307.001 81.9644 307.419 83.3021C307.836 84.6398 308.045 86.3183 308.045 88.3375C308.045 89.5995 307.936 90.7605 307.718 91.8206C307.519 92.8807 307.183 93.802 306.711 94.5844C306.257 95.3416 305.659 95.9347 304.915 96.3638C304.171 96.7929 303.263 97.0074 302.193 97.0074H298.191ZM299.661 95.3416H302.247C303.318 95.3416 304.162 95.0766 304.779 94.5465C305.414 93.9913 305.867 93.1962 306.14 92.1613C306.412 91.1013 306.548 89.814 306.548 88.2996C306.548 86.0028 306.23 84.2107 305.595 82.9235C304.96 81.611 303.835 80.9548 302.22 80.9548H299.661V95.3416Z"
        fill="#C298DC"/>
      <path
        d="M285.99 97.0074V66.3408H290.536C291.552 66.3408 292.387 66.5427 293.04 66.9466C293.712 67.3252 294.229 67.8678 294.592 68.5746C294.973 69.2813 295.236 70.1016 295.381 71.0355C295.544 71.9441 295.626 72.9285 295.626 73.9885C295.626 74.7962 295.553 75.667 295.408 76.6009C295.281 77.5095 295.036 78.3172 294.673 79.0239C294.31 79.7306 293.766 80.2102 293.04 80.4626C293.893 80.816 294.537 81.4217 294.973 82.2799C295.426 83.138 295.726 84.0972 295.871 85.1572C296.034 86.1921 296.116 87.1638 296.116 88.0725C296.116 89.2335 296.052 90.3441 295.925 91.4041C295.816 92.4642 295.59 93.4234 295.245 94.2815C294.9 95.1144 294.401 95.7833 293.748 96.2881C293.113 96.7677 292.26 97.0074 291.189 97.0074H285.99ZM287.46 95.3795H290.999C292.45 95.3795 293.421 94.7863 293.911 93.6C294.419 92.4138 294.673 90.5838 294.673 88.1103C294.673 86.8736 294.564 85.7504 294.347 84.7408C294.147 83.7312 293.793 82.9235 293.285 82.3177C292.795 81.712 292.106 81.4091 291.216 81.4091H287.46V95.3795ZM287.46 79.7811H291.189C292.006 79.7811 292.632 79.5161 293.067 78.9861C293.503 78.456 293.802 77.7619 293.966 76.9038C294.147 76.0204 294.238 75.0486 294.238 73.9885C294.238 72.8527 294.138 71.8305 293.938 70.9219C293.739 70.0132 293.349 69.3065 292.768 68.8017C292.205 68.2717 291.343 68.0067 290.182 68.0067H287.46V79.7811Z"
        fill="#C298DC"/>
      <path
        d="M278.025 97.4238C276.591 97.4238 275.475 97.0326 274.677 96.2502C273.897 95.4425 273.352 94.3572 273.044 92.9942C272.735 91.606 272.581 90.0411 272.581 88.2995V74.8971C272.581 73.1555 272.744 71.6159 273.071 70.2782C273.398 68.9405 273.951 67.893 274.731 67.1358C275.53 66.3786 276.628 66 278.025 66C279.422 66 280.511 66.3786 281.291 67.1358C282.09 67.893 282.652 68.9405 282.979 70.2782C283.306 71.6159 283.469 73.1555 283.469 74.8971V88.3374C283.469 90.079 283.315 91.6312 283.006 92.9942C282.698 94.3572 282.144 95.4425 281.346 96.2502C280.565 97.0326 279.459 97.4238 278.025 97.4238ZM278.025 95.7201C279.077 95.7201 279.885 95.4299 280.448 94.8493C281.028 94.2688 281.427 93.4611 281.645 92.4263C281.863 91.3662 281.972 90.1421 281.972 88.7539V74.5942C281.972 73.206 281.863 71.9945 281.645 70.9597C281.427 69.9248 281.037 69.1298 280.475 68.5745C279.912 67.994 279.096 67.7037 278.025 67.7037C276.972 67.7037 276.156 67.994 275.575 68.5745C275.013 69.1298 274.623 69.9248 274.405 70.9597C274.187 71.9945 274.078 73.206 274.078 74.5942V88.7539C274.078 90.1421 274.187 91.3662 274.405 92.4263C274.623 93.4611 275.013 94.2688 275.575 94.8493C276.156 95.4299 276.972 95.7201 278.025 95.7201Z"
        fill="#C298DC"/>
      <path
        d="M260.922 97.0074V66.3408H266.094C267.418 66.3408 268.453 66.6942 269.197 67.4009C269.941 68.0824 270.458 69.0163 270.748 70.2025C271.057 71.3888 271.211 72.7392 271.211 74.2536C271.211 75.6165 271.057 76.9164 270.748 78.1531C270.458 79.3899 269.941 80.3995 269.197 81.1819C268.453 81.9644 267.427 82.3556 266.121 82.3556H262.392V97.0074H260.922ZM262.392 80.7655H265.794C266.683 80.7655 267.409 80.5509 267.972 80.1219C268.552 79.6928 268.979 79.0113 269.251 78.0774C269.541 77.1183 269.687 75.8437 269.687 74.2536C269.687 72.613 269.56 71.3383 269.306 70.4297C269.051 69.4958 268.643 68.8522 268.081 68.4988C267.518 68.1202 266.765 67.9309 265.821 67.9309H262.392V80.7655Z"
        fill="#C298DC"/>
      <path
        d="M252.957 97.4238C251.523 97.4238 250.407 97.0326 249.609 96.2502C248.828 95.4425 248.284 94.3572 247.975 92.9942C247.667 91.606 247.513 90.0411 247.513 88.2995V74.8971C247.513 73.1555 247.676 71.6159 248.003 70.2782C248.329 68.9405 248.883 67.893 249.663 67.1358C250.461 66.3786 251.559 66 252.957 66C254.354 66 255.443 66.3786 256.223 67.1358C257.021 67.893 257.584 68.9405 257.911 70.2782C258.237 71.6159 258.401 73.1555 258.401 74.8971V88.3374C258.401 90.079 258.246 91.6312 257.938 92.9942C257.629 94.3572 257.076 95.4425 256.277 96.2502C255.497 97.0326 254.39 97.4238 252.957 97.4238ZM252.957 95.7201C254.009 95.7201 254.817 95.4299 255.379 94.8493C255.96 94.2688 256.359 93.4611 256.577 92.4263C256.795 91.3662 256.903 90.1421 256.903 88.7539V74.5942C256.903 73.206 256.795 71.9945 256.577 70.9597C256.359 69.9248 255.969 69.1298 255.406 68.5745C254.844 67.994 254.027 67.7037 252.957 67.7037C251.904 67.7037 251.088 67.994 250.507 68.5745C249.944 69.1298 249.554 69.9248 249.336 70.9597C249.119 71.9945 249.01 73.206 249.01 74.5942V88.7539C249.01 90.1421 249.119 91.3662 249.336 92.4263C249.554 93.4611 249.944 94.2688 250.507 94.8493C251.088 95.4299 251.904 95.7201 252.957 95.7201Z"
        fill="#C298DC"/>
      <path
        d="M231.903 102.081V95.4173H232.693C233.056 95.0892 233.382 94.7611 233.673 94.433C233.981 94.0796 234.253 93.5622 234.489 92.8807C234.725 92.1992 234.943 91.2401 235.142 90.0033C235.36 88.7413 235.56 87.0502 235.741 84.9301C235.923 82.7847 236.104 80.0714 236.286 76.7902L236.857 66.3408H244.615V95.4173H246.221V102.081H245.023L244.86 97.0074H233.264L233.101 102.081H231.903ZM234.353 95.4173H243.118V67.9309H238.218L237.701 77.1688C237.52 80.45 237.329 83.1507 237.129 85.2708C236.948 87.3657 236.757 89.0316 236.558 90.2684C236.358 91.5051 236.14 92.4516 235.905 93.1079C235.687 93.7389 235.451 94.2184 235.197 94.5465C234.943 94.8494 234.662 95.1397 234.353 95.4173Z"
        fill="#C298DC"/>
      <path
        d="M226.705 97.4238C225.598 97.4238 224.7 97.1209 224.01 96.5152C223.339 95.9094 222.849 95.0765 222.54 94.0164C222.232 92.9311 222.077 91.6565 222.077 90.1925V89.6247H223.438C223.438 89.6751 223.438 89.8013 223.438 90.0033C223.438 90.1799 223.438 90.2935 223.438 90.344C223.457 92.1108 223.72 93.4611 224.228 94.395C224.736 95.3289 225.561 95.7958 226.705 95.7958C227.394 95.7958 228.011 95.6065 228.556 95.2279C229.1 94.8241 229.526 94.1174 229.835 93.1078C230.162 92.0982 230.325 90.6847 230.325 88.8674C230.325 86.8482 230.035 85.1824 229.454 83.8699C228.873 82.5574 227.884 81.876 226.487 81.8255C226.469 81.8255 226.351 81.8255 226.133 81.8255C225.915 81.8255 225.698 81.8255 225.48 81.8255C225.262 81.8255 225.135 81.8255 225.099 81.8255V80.3111C225.135 80.3111 225.262 80.3111 225.48 80.3111C225.698 80.3111 225.915 80.3111 226.133 80.3111C226.351 80.3111 226.46 80.3111 226.46 80.3111C227.875 80.2606 228.873 79.6927 229.454 78.6074C230.035 77.4968 230.325 75.8057 230.325 73.5341C230.325 71.8178 230.062 70.4044 229.536 69.2938C229.027 68.1833 228.156 67.628 226.922 67.628C225.761 67.628 224.863 68.0318 224.228 68.8395C223.611 69.6472 223.293 71.0606 223.275 73.0798C223.275 73.1303 223.275 73.2313 223.275 73.3827C223.275 73.5089 223.275 73.5972 223.275 73.6477H221.914V73.1555C221.914 71.7169 222.068 70.4675 222.377 69.4074C222.703 68.3221 223.23 67.4892 223.956 66.9086C224.681 66.3029 225.652 66 226.868 66C227.993 66 228.919 66.3407 229.644 67.0222C230.37 67.6785 230.906 68.5745 231.25 69.7103C231.613 70.8461 231.795 72.1333 231.795 73.572C231.795 75.8688 231.486 77.6356 230.869 78.8724C230.252 80.0839 229.499 80.8159 228.61 81.0683C229.082 81.2197 229.563 81.5731 230.053 82.1284C230.543 82.6584 230.951 83.4661 231.278 84.5514C231.622 85.6367 231.795 87.0628 231.795 88.8296C231.795 90.4954 231.622 91.9846 231.278 93.2971C230.951 94.5843 230.416 95.5939 229.672 96.3259C228.928 97.0578 227.939 97.4238 226.705 97.4238Z"
        fill="#C298DC"/>
      <path
        d="M210.236 97.4238C208.802 97.4238 207.686 97.0326 206.888 96.2502C206.108 95.4425 205.563 94.3572 205.255 92.9942C204.946 91.606 204.792 90.0411 204.792 88.2995V74.8971C204.792 73.1555 204.955 71.6159 205.282 70.2782C205.609 68.9405 206.162 67.893 206.942 67.1358C207.741 66.3786 208.839 66 210.236 66C211.633 66 212.722 66.3786 213.502 67.1358C214.301 67.893 214.863 68.9405 215.19 70.2782C215.516 71.6159 215.68 73.1555 215.68 74.8971V88.3374C215.68 90.079 215.526 91.6312 215.217 92.9942C214.909 94.3572 214.355 95.4425 213.557 96.2502C212.776 97.0326 211.669 97.4238 210.236 97.4238ZM210.236 95.7201C211.288 95.7201 212.096 95.4299 212.658 94.8493C213.239 94.2688 213.638 93.4611 213.856 92.4263C214.074 91.3662 214.183 90.1421 214.183 88.7539V74.5942C214.183 73.206 214.074 71.9945 213.856 70.9597C213.638 69.9248 213.248 69.1298 212.686 68.5745C212.123 67.994 211.307 67.7037 210.236 67.7037C209.183 67.7037 208.367 67.994 207.786 68.5745C207.224 69.1298 206.833 69.9248 206.616 70.9597C206.398 71.9945 206.289 73.206 206.289 74.5942V88.7539C206.289 90.1421 206.398 91.3662 206.616 92.4263C206.833 93.4611 207.224 94.2688 207.786 94.8493C208.367 95.4299 209.183 95.7201 210.236 95.7201Z"
        fill="#C298DC"/>
      <path d="M196.295 97.0074V66.3408H203.78V68.0067H197.765V97.0074H196.295Z" fill="#C298DC"/>
      <path
        d="M186.671 97.0074V66.3408H194.292V68.0067H188.168V80.3112H193.204V81.9013H188.168V95.3795H194.374V97.0074H186.671Z"
        fill="#C298DC"/>
      <path
        d="M168.676 97.0074V66.3408H170.146V95.4173H175.535V66.3408H177.032V95.4173H182.204V66.3408H183.701V97.0074H168.676Z"
        fill="#C298DC"/>
      <path
        d="M155.86 97.0074L160.488 66.3408H162.203L166.857 97.0074H165.36L164.217 88.5268H158.501L157.33 97.0074H155.86ZM158.718 87.0124H164.026L161.359 68.8396L158.718 87.0124Z"
        fill="#C298DC"/>
      <path
        d="M144.829 97.0074V66.3408H149.375C150.391 66.3408 151.226 66.5427 151.879 66.9466C152.55 67.3252 153.068 67.8678 153.43 68.5746C153.812 69.2813 154.075 70.1016 154.22 71.0355C154.383 71.9441 154.465 72.9285 154.465 73.9885C154.465 74.7962 154.392 75.667 154.247 76.6009C154.12 77.5095 153.875 78.3172 153.512 79.0239C153.149 79.7306 152.605 80.2102 151.879 80.4626C152.732 80.816 153.376 81.4217 153.812 82.2799C154.265 83.138 154.565 84.0972 154.71 85.1572C154.873 86.1921 154.955 87.1638 154.955 88.0725C154.955 89.2335 154.891 90.3441 154.764 91.4041C154.655 92.4642 154.429 93.4234 154.084 94.2815C153.739 95.1144 153.24 95.7833 152.587 96.2881C151.952 96.7677 151.099 97.0074 150.028 97.0074H144.829ZM146.299 95.3795H149.838C151.289 95.3795 152.26 94.7863 152.75 93.6C153.258 92.4138 153.512 90.5838 153.512 88.1103C153.512 86.8736 153.403 85.7504 153.186 84.7408C152.986 83.7312 152.632 82.9235 152.124 82.3177C151.634 81.712 150.944 81.4091 150.055 81.4091H146.299V95.3795ZM146.299 79.7811H150.028C150.845 79.7811 151.471 79.5161 151.906 78.9861C152.342 78.456 152.641 77.7619 152.804 76.9038C152.986 76.0204 153.077 75.0486 153.077 73.9885C153.077 72.8527 152.977 71.8305 152.777 70.9219C152.578 70.0132 152.187 69.3065 151.607 68.8017C151.044 68.2717 150.182 68.0067 149.021 68.0067H146.299V79.7811Z"
        fill="#C298DC"/>
      <path
        d="M126.08 97.0074L130.462 83.0371C129.355 82.4566 128.557 81.4091 128.067 79.8947C127.577 78.3551 127.332 76.5756 127.332 74.5564C127.332 73.0925 127.495 71.7422 127.822 70.5054C128.167 69.2687 128.748 68.2717 129.564 67.5145C130.381 66.732 131.515 66.3408 132.967 66.3408H137.784V97.0074H136.26V83.6428H132.885C132.722 83.6428 132.531 83.6428 132.313 83.6428C132.114 83.6176 131.941 83.5924 131.796 83.5671L127.74 97.0074H126.08ZM133.076 82.0527H136.26V67.9688H133.021C131.678 67.9688 130.635 68.4736 129.891 69.4832C129.165 70.4928 128.802 72.1839 128.802 74.5564C128.802 77.03 129.129 78.8977 129.782 80.1597C130.435 81.4217 131.533 82.0527 133.076 82.0527Z"
        fill="#C298DC"/>
      <path
        d="M112.072 97.0831V95.4552C112.653 95.4047 113.134 95.3037 113.515 95.1523C113.896 95.0008 114.204 94.6475 114.44 94.0922C114.676 93.5369 114.876 92.6409 115.039 91.4041C115.203 90.1674 115.357 88.4511 115.502 86.2552C115.665 84.0593 115.847 81.2324 116.046 77.7745L116.645 66.3408H124.539V97.0074H123.042V67.9309H118.033L117.489 77.7745C117.289 81.2324 117.108 84.1224 116.945 86.4445C116.781 88.7413 116.591 90.5965 116.373 92.0099C116.173 93.4233 115.901 94.496 115.556 95.228C115.23 95.9347 114.785 96.4143 114.223 96.6667C113.66 96.9191 112.943 97.0579 112.072 97.0831Z"
        fill="#C298DC"/>
      <path
        d="M97.3604 102.081V95.4173H98.1497C98.5126 95.0892 98.8393 94.7611 99.1296 94.433C99.4381 94.0796 99.7103 93.5622 99.9462 92.8807C100.182 92.1992 100.4 91.2401 100.599 90.0033C100.817 88.7413 101.017 87.0502 101.198 84.9301C101.38 82.7847 101.561 80.0714 101.743 76.7902L102.314 66.3408H110.072V95.4173H111.678V102.081H110.48L110.317 97.0074H98.7213L98.558 102.081H97.3604ZM99.8101 95.4173H108.575V67.9309H103.675L103.158 77.1688C102.977 80.45 102.786 83.1507 102.587 85.2708C102.405 87.3657 102.215 89.0316 102.015 90.2684C101.815 91.5051 101.598 92.4516 101.362 93.1079C101.144 93.7389 100.908 94.2184 100.654 94.5465C100.4 94.8494 100.119 95.1397 99.8101 95.4173Z"
        fill="#C298DC"/>
      <path
        d="M78.708 97.0074L79.3613 66.3408H80.8311L85.4857 94.0165L90.2219 66.3408H91.7462L92.3722 97.0074H91.0929L90.5485 71.3005L86.0573 97.0074H84.9685L80.5317 71.3383L79.9873 97.0074H78.708Z"
        fill="#C298DC"/>
      <path
        d="M61.833 97.0074V66.3408H63.3301V79.2889H65.8071C67.168 79.2889 68.284 79.6297 69.1551 80.3112C70.0261 80.9674 70.6703 81.9644 71.0877 83.3021C71.505 84.6398 71.7137 86.3183 71.7137 88.3375C71.7137 89.5995 71.6048 90.7605 71.3871 91.8206C71.1875 92.8807 70.8518 93.802 70.38 94.5844C69.9263 95.3416 69.3275 95.9347 68.5835 96.3638C67.8395 96.7929 66.9321 97.0074 65.8615 97.0074H61.833ZM63.3301 95.3416H65.8887C66.9594 95.3416 67.8032 95.0766 68.4201 94.5465C69.0553 93.9913 69.5089 93.1962 69.7811 92.1613C70.0533 91.1013 70.1894 89.814 70.1894 88.2996C70.1894 86.0028 69.8719 84.2107 69.2367 82.9235C68.6198 81.611 67.5038 80.9548 65.8887 80.9548H63.3301V95.3416ZM74.5445 97.0074V66.3408H76.0144V97.0074H74.5445Z"
        fill="#C298DC"/>
      <path
        d="M48.1689 97.0074V66.3408H49.666V80.1597H57.3964V66.3408H58.8662V97.0074H57.3964V81.7498H49.666V97.0074H48.1689Z"
        fill="#C298DC"/>
      <path
        d="M32.125 102.081V95.4173H32.9144C33.2773 95.0892 33.6039 94.7611 33.8943 94.433C34.2028 94.0796 34.475 93.5622 34.7109 92.8807C34.9468 92.1992 35.1645 91.2401 35.3641 90.0033C35.5819 88.7413 35.7815 87.0502 35.963 84.9301C36.1444 82.7847 36.3259 80.0714 36.5073 76.7902L37.079 66.3408H44.8365V95.4173H46.4425V102.081H45.2448L45.0815 97.0074H33.486L33.3227 102.081H32.125ZM34.5748 95.4173H43.3395V67.9309H38.4399L37.9228 77.1688C37.7413 80.45 37.5508 83.1507 37.3512 85.2708C37.1697 87.3657 36.9792 89.0316 36.7795 90.2684C36.5799 91.5051 36.3622 92.4516 36.1263 93.1079C35.9085 93.7389 35.6726 94.2184 35.4186 94.5465C35.1645 94.8494 34.8833 95.1397 34.5748 95.4173Z"
        fill="#C298DC"/>
      <path
        d="M24.2969 97.0074V66.3408H31.9184V68.0067H25.794V80.3112H30.8296V81.9013H25.794V95.3795H32V97.0074H24.2969Z"
        fill="#C298DC"/>
      <path
        d="M12.2021 97.0074V66.3408H17.3739C18.6986 66.3408 19.7329 66.6942 20.4769 67.4009C21.2209 68.0824 21.7381 69.0163 22.0284 70.2025C22.3369 71.3888 22.4911 72.7392 22.4911 74.2536C22.4911 75.6165 22.3369 76.9164 22.0284 78.1531C21.7381 79.3899 21.2209 80.3995 20.4769 81.1819C19.7329 81.9644 18.7076 82.3556 17.4011 82.3556H13.672V97.0074H12.2021ZM13.672 80.7655H17.0745C17.9636 80.7655 18.6895 80.5509 19.252 80.1219C19.8327 79.6928 20.2591 79.0113 20.5313 78.0774C20.8217 77.1183 20.9668 75.8437 20.9668 74.2536C20.9668 72.613 20.8398 71.3383 20.5858 70.4297C20.3317 69.4958 19.9234 68.8522 19.3609 68.4988C18.7984 68.1202 18.0453 67.9309 17.1017 67.9309H13.672V80.7655Z"
        fill="#C298DC"/>
      <path
        d="M0 97.0074V66.3408H4.54567C5.56186 66.3408 6.3966 66.5427 7.04987 66.9466C7.72128 67.3252 8.23845 67.8678 8.60138 68.5746C8.98246 69.2813 9.24558 70.1016 9.39075 71.0355C9.55407 71.9441 9.63573 72.9285 9.63573 73.9885C9.63573 74.7962 9.56314 75.667 9.41797 76.6009C9.29095 77.5095 9.04597 78.3172 8.68304 79.0239C8.32011 79.7306 7.77572 80.2102 7.04987 80.4626C7.90275 80.816 8.54694 81.4217 8.98246 82.2799C9.43612 83.138 9.73553 84.0972 9.8807 85.1572C10.044 86.1921 10.1257 87.1638 10.1257 88.0725C10.1257 89.2335 10.0622 90.3441 9.93514 91.4041C9.82626 92.4642 9.59943 93.4234 9.25465 94.2815C8.90987 95.1144 8.41085 95.7833 7.75758 96.2881C7.12245 96.7677 6.26957 97.0074 5.19894 97.0074H0ZM1.46986 95.3795H5.0084C6.46011 95.3795 7.43094 94.7863 7.92089 93.6C8.42899 92.4138 8.68304 90.5838 8.68304 88.1103C8.68304 86.8736 8.57416 85.7504 8.35641 84.7408C8.1568 83.7312 7.80294 82.9235 7.29484 82.3177C6.80489 81.712 6.11533 81.4091 5.22616 81.4091H1.46986V95.3795ZM1.46986 79.7811H5.19894C6.01552 79.7811 6.64157 79.5161 7.07709 78.9861C7.5126 78.456 7.81202 77.7619 7.97533 76.9038C8.1568 76.0204 8.24753 75.0486 8.24753 73.9885C8.24753 72.8527 8.14772 71.8305 7.94811 70.9219C7.7485 70.0132 7.35836 69.3065 6.77767 68.8017C6.21513 68.2717 5.35318 68.0067 4.19181 68.0067H1.46986V79.7811Z"
        fill="#C298DC"/>
    </svg>

  </div>
</template>

<style scoped lang="scss">
.self-med {

  max-width: 1440px;
  width: 100%;
  margin: auto auto 30px auto;
  display: flex;
  justify-content: center;

  padding: 0 60px;

  @media screen and (max-width: 1024px) {
    padding: 0 20px;
    margin: 0 0 30px 0;
    max-width: 100%;
    width: 100%;
    display: block;

  }

  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
  }

  .desk {

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .tablet {
    display: none;
    @media screen and (max-width: 1024px) {
      display: block;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .mob {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  svg {
    width: 100%;
    height: auto;
    display: block;
  }
}
</style>